export default {
  namespaced: true,
  // ESTADO
  state: {
    solicitud: {},
    requisitosTramite: {
      cumple: null,
    },
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    SET_SOLICITUD(state, valor) {
      state.solicitud = valor;
    },
    SET_REQUISITOS_TRAMITE(state, valor) {
      state.requisitosTramite = valor;
    },
  },
};
