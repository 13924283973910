<template>
  <div
    class="notifications"
    v-if="isLogged && validRoute"
    v-click-outside="hide"
  >
    <div
      class="notifications__outerIcon"
      :class="showed?'is-active':''"
    >
      <img
        class="notifications__icon"
        :alt="$t('showNots')"
        src="/img/icons/bell_iconfinder.svg"
        @click="toggle"
        @keyup.enter="toggle"
        @keyup.space="toggle"
        tabindex="0"
      >
    </div>
    <span
      class="notifications__summary"
      v-if="summary"
      @click="toggle"
    >
      {{ summary }}
    </span>
    <span
      class="notifications__triangle"
      v-if="showed"
    />
    <ul
      class="notifications__menu"
      v-if="showed"
    >
      <li
        class="notifications__categoryItem"
        v-if="unread.length > 0"
      >
        <span
          class="notifications__markAllAsRead"
          @click="markAllAsRead"
        >
          {{ $t('markAllRead') }}
        </span>
      </li>
      <li
        class="notifications__categoryItem"
        v-else
      >
        <span
          class="notifications__nonots"
        >
          {{ $t('noNots') }}
        </span>
      </li>
      <li
        class="notifications__categoryItem"
        v-for="(item, j) in group"
        :key="j"
      >
        <h3 class="notifications__groupTitle">
          {{ j }} ({{ groupItems(j).length }})
        </h3>
        <ul class="notifications__submenu">
          <li
            v-for="(data, i) in groupItems(j)"
            :key="i"
            class="notifications__menuItem"
          >
            <fwjs-notification-item
              :notification="data"
              :id="cardId(data.codigo_alerta)"
            />
            <span
              class="notifications__markAsRead"
              @click="markAsRead(data.codigo_alerta)"
            >
              {{ $t('markRead') }}
            </span>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { defineComponent } from 'vue';
import groupBy from '../utils/groupBy';
import fwjsNotificationItem from './fwjsNotificationItem.vue';

export default defineComponent({
  name: 'Notifications',
  components: {
    fwjsNotificationItem,
  },
  data() {
    return {
      showed: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
    ...mapState('notifications', ['notifications']),
    summary() {
      return this.notifications.length > 0
        ? this.notifications.length : 0;
    },
    unread() {
      return this.notifications.length > 0
        ? this.notifications : [];
    },
    group() {
      return this.notifications.length > 0
        ? groupBy(this.unread, 'aplicacion') : [];
    },
    validRoute() {
      return this.$route.path === '/';
    },
  },
  methods: {
    toggle() {
      this.showed = !this.showed;
    },
    hide() {
      this.showed = false;
    },
    markAsRead(itemId) {
      this.$store.dispatch('notifications/markNotificationAsRead', itemId);
    },
    markAllAsRead() {
      const nots = this.notifications.map((el) => el.codigo_alerta);
      this.$store.dispatch('notifications/markAllNotificationAsRead', nots);
    },
    groupItems(key) {
      return this.group[key];
    },
    cardId(id) {
      // const card = this.cards.find((el) => el.identifier === `${id}`);
      // return card && card.identifier ? `${card.identifier}` : 'hidden';
      return id ? `${id}` : false;
    },
  },
});
</script>

<i18n>
{
  "en": {
    "markRead": "Mark as Read",
    "markAllRead": "Mark all as Read",
    "showNots": "Show notifications",
    "noNots": "There's no new notifications"
  },
  "es": {
    "markRead": "Marcar como leída",
    "markAllRead": "Marcar todas como leídas",
    "showNots": "Mostrar notificaciones",
    "noNots": "No hay nuevas notificaciones"
  }
}
</i18n>

<style lang="scss" scoped>
.notifications {
  user-select: none;
  position: relative;
  top: 2px;
  &__outerIcon {
    padding: 0.25rem 0;
    background: transparent;
    border-radius: 0.25rem;
    &.is-active {
      background: rgba(255,255,255,0.25);
    }
  }
  &__icon {
    cursor: pointer;
    margin: 0 0.5rem;
    width: var(--icon-size);
    &:hover {
      filter: drop-shadow(0 0 0.5rem rgba(0,0,0,0.35));
    }
  }
  &__summary {
    background-color: var(--title-color);
    border-radius: 2px;
    bottom: 0;
    color: var(--title-inverse-color);
    cursor: pointer;
    display: block;
    font-size: 0.8rem;
    line-height: 1;
    min-width: 16px;
    padding: 2px 0;
    position: absolute;
    right: 0;
    text-align: center;
  }
  &__triangle {
    content: "";
    display: block;
    position: absolute;
    top: 34px;
    background-color: var(--main-bg-color);
    height: 16px;
    width: 16px;
    right: 11px;
    transform: rotate(45deg);
  }
  &__menu {
    background-color: var(--main-bg-color);
    box-shadow: 0px 8px 8px 0px rgba(0,0,0,0.15);
    color: var(--main-text-color);
    line-height: 1;
    list-style-type: none;
    margin: 0 0 0 0;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    padding: 0 0 0 0;
    position: absolute;
    right: -48px;
    text-align: left;
    top: 40px;
    width: var(--menu-size);
    @media (max-width: $screen-sm-min) {
      border: 1px solid rgba(0,0,0,0.25);
      box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.25);
      top: 29px;
      border-radius: 2px;
      max-height: calc(100vh - 63px);
      overflow: auto;
      right: 0;
    }
  }
  &__submenu {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 1rem 0;
  }
  &__menuItem {
    font-size: 0.875rem;
    padding: 0.875rem 0.875rem 1.5rem 0.875rem;
    border-bottom: 1px solid var(--border-color);
    position: relative;
  }
  &__groupTitle {
    border-bottom: 1px solid var(--border-color);
    font-size: 1rem;
    margin: 0 0 0 0;
    padding: 0.75rem 1rem 0.75rem 1rem;
  }
  &__markAsRead {
    color: var(--link-color);
    cursor: pointer;
    display: block;
    padding: 0.5rem 0;
    text-decoration: underline;
    position: absolute;
    text-align: right;
    bottom: 0.05rem;
    right: 1rem;
    &:hover {
      text-decoration: none;
    }
  }
  &__markAllAsRead {
    border-bottom: 1px solid var(--border-color);
    color: var(--link-color);
    cursor: pointer;
    display: block;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
    text-decoration: underline;
  }
  &__nonots {
    display: block;
    font-size: 0.9rem;
    padding: 1.5rem 1rem;
  }
}
</style>
