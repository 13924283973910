import PoseRoutes from './campus-virtual/portal-servicios/module.routes';
import PciRoutes from './pci/portal-servicios/module.routes';
import CargaveRoutes from './pci/cargave/module.routes';
import AuthRoutes from './auth/module.routes';
import UserRoutes from './user/module.routes';
import SharedRoutes from './shared/module.routes';
import MisCertificadosRoutes from './campus-virtual/mis-certificados/module.routes';
import HistoricoGuiasRoutes from './campus-virtual/historico-guias/module.routes';
import SumaRoutes from './campus-virtual/suma/module.routes';
import ConsultaExpedientesRoutes from './acade/consulta-expedientes/module.routes';
import ResguardoMatriculaRoutes from './acade/resguardo-matricula/module.routes';
import EvaluacionDocenteRoutes from './curie/evaluacion-docente/module.routes';
import DevolucionGenericaIngresos from './gestecon/devolucion-generica-ingresos/module.routes';
import ConvocatoriaIncidenciasRoutes from './acade/convocatoria-incidencias/module.routes';
import BajaMatriculaRoutes from './acade/baja-matricula/module.routes';
import MtoPreciosPublicosRoutes from './acade/mantenimiento-precios-publicos/module.routes';
import ReconocimientoCreditosRoutes from './acade/reconocimiento-creditos/module.routes';
import TrasladoPauRoutes from './acade/traslado-pau/module.routes';
import TrasladoExpedienteEntradaRoutes from './acade/traslado-expediente-entrada/module.routes';
import CompensacionRoutes from './acade/compensacion/module.routes';
import MisCrauRoutes from './acade/mis-crau/module.routes';
import MisTramitesRoutes from './eadmin/mis-tramites/module.routes';
import MisRecibosRoutes from './gestecon/mis-recibos/module.routes';
import KronRoutes from './gente/kron/module.routes';
import MiVehiculoRoutes from './gente/mi-vehiculo/module.routes';
import MovilidadRoutes from './curie/movilidad-servicios/module.routes';
import Deportes from './pci/deportes/module.routes';
import CambioGrupoMatricula from './acade/cambio-grupo/module.routes';
import MiExpedienteRoutes from './acade/mi-expediente/module.routes';
import MiMatriculaRoutes from './acade/mi-matricula/module.routes';
import AccionesFormativasRoutes from './curie/acciones-formativas/module.routes';
import MiTF from './acade/mi-tf/module.routes';
import TransporteRoutes from './pci/transporte/module.routes';
import MisImpresionesRoutes from './pci/sistema-impresion/module.routes';
import CitasReservasRoutes from './curie/citas-reservas/module.routes';
import MatriculaParcialRoutes from './acade/matricula-parcial/module.routes';
import NominaRoutes from './gente/nominas/module.routes';
import IntercambioVacaciones from './campus-virtual/intercambio-vacaciones/module.routes';
import BajaParcialMatriculaRoutes from './acade/baja-parcial-matricula/module.routes';

const NotFound = () => import(/* webpackChunkName: "404" */'@/app/shared/views/NotFound.vue');

export default [
  ...AuthRoutes,
  ...UserRoutes,
  ...SharedRoutes,
  ...PoseRoutes,
  ...PciRoutes,
  ...CargaveRoutes,
  ...MisCertificadosRoutes,
  ...HistoricoGuiasRoutes,
  ...SumaRoutes,
  ...ConsultaExpedientesRoutes,
  ...ResguardoMatriculaRoutes,
  ...EvaluacionDocenteRoutes,
  ...DevolucionGenericaIngresos,
  ...ConvocatoriaIncidenciasRoutes,
  ...MtoPreciosPublicosRoutes,
  ...ReconocimientoCreditosRoutes,
  ...TrasladoPauRoutes,
  ...TrasladoExpedienteEntradaRoutes,
  ...CompensacionRoutes,
  ...MisCrauRoutes,
  ...MisTramitesRoutes,
  ...BajaMatriculaRoutes,
  ...MisRecibosRoutes,
  ...KronRoutes,
  ...MiVehiculoRoutes,
  ...MovilidadRoutes,
  ...CambioGrupoMatricula,
  ...MiExpedienteRoutes,
  ...MiMatriculaRoutes,
  ...AccionesFormativasRoutes,
  ...MiTF,
  ...TransporteRoutes,
  ...MisImpresionesRoutes,
  ...Deportes,
  ...CitasReservasRoutes,
  ...MatriculaParcialRoutes,
  ...BajaParcialMatriculaRoutes,
  ...IntercambioVacaciones,
  ...NominaRoutes,
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
];
