export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "totalElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
        "dayEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["events"])},
        "todayEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Today events"])},
        "noEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no events for that day"])},
        "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])}
      },
      "es": {
        "totalElements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
        "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página"])},
        "dayEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["eventos"])},
        "todayEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eventos de hoy"])},
        "noEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay eventos para este día"])},
        "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])}
      }
    }
  })
}
