import store from '@/store';
import storage from '@/storage';
import {
  environmentURL,
} from '@/api';

export const LOGGER_TRACE_LEVEL = 1000;
export const LOGGER_DEBUG_LEVEL = 2000;
export const LOGGER_INFO_LEVEL = 3000;
export const LOGGER_WARN_LEVEL = 4000;
export const LOGGER_ERROR_LEVEL = 5000;
export const LOGGER_FATAL_LEVEL = 6000;

const LIBRARY_URL = '/js/JSNLog.2-30-0.min.js';

export default class Logger {
  constructor(batchTimeOutTemp = 5000, logLevel = LOGGER_DEBUG_LEVEL) {
    const batchTimeOutTempInt = parseInt(batchTimeOutTemp, 10) || 30000;
    this.namespace = process.env.VUE_APP_PACKAGE_NAME || '';
    this.packageVersion = process.env.VUE_APP_PACKAGE_VERSION || '0';
    this.computedNamespaced = `${this.namespace}/v${this.packageVersion}`;
    this.apiLoggerUrl = `${environmentURL}/aulavirtual/portal-api/public/frontLogger`;
    this.active = process.env.NODE_ENV === 'production' && this.apiLoggerUrl !== '';

    if (!this.active) {
      return;
    }

    if (
      typeof window !== 'undefined'
      && 'onunhandledrejection' in window
    ) {
      // eslint-disable-next-line no-unused-vars
      window.onunhandledrejection = (event) => false;
    }

    // eslint-disable-next-line func-names
    const beforeSendAddHeaders = function (xhr) {
      const logGroup = storage.getItem('POSE_LOG_GROUP');
      const logApp = storage.getItem('POSE_LOG_APPLICATION');
      xhr.setRequestHeader('UMU-User-UUID', store.state.user.user ? btoa(store.state.user.user.uuid) : 'anonymous');
      xhr.setRequestHeader('UMU-Client-APP', process.env.VUE_APP_UMU_CLIENT_APP);
      xhr.setRequestHeader('UMU-Logger-Group', logGroup);
      xhr.setRequestHeader('UMU-Logger-App', logApp);
    };

    const imported = document.createElement('script');
    imported.src = LIBRARY_URL;
    imported.type = 'text/javascript';
    imported.crossorigin = 'anonymous';
    imported.onload = () => {
      const ajaxAppender = window.JL.createAjaxAppender('ajaxAppender').setOptions({
        name: this.computedNamespaced,
        storeInBufferLevel: logLevel,
        level: logLevel,
        sendWithBufferLevel: logLevel,
        defaultAjaxUrl: this.apiLoggerUrl,
        beforeSend: beforeSendAddHeaders,
      });

      window.JL.setOptions({
        appenders: [ajaxAppender],
        batchSize: 1,
        batchTimeout: batchTimeOutTempInt,
        defaultAjaxUrl: this.apiLoggerUrl,
        level: logLevel,
      });

      window.JL().setOptions({
        appenders: [ajaxAppender],
        batchTimeout: batchTimeOutTempInt,
        defaultAjaxUrl: this.apiLoggerUrl,
        level: logLevel,
      });
    };
    document.body.appendChild(imported);
  }

  static get traceLevel() {
    return LOGGER_TRACE_LEVEL;
  }

  static get debugLevel() {
    return LOGGER_DEBUG_LEVEL;
  }

  static get infoLevel() {
    return LOGGER_INFO_LEVEL;
  }

  static get warnLevel() {
    return LOGGER_WARN_LEVEL;
  }

  static get errorLevel() {
    return LOGGER_ERROR_LEVEL;
  }

  static get fatalLevel() {
    return LOGGER_FATAL_LEVEL;
  }

  trace(logObject) {
    if (this.active) {
      window.JL(this.computedNamespaced, process.env.VUE_APP_UMU_CLIENT_APP).trace(logObject);
    }
  }

  debug(logObject) {
    if (this.active) {
      window.JL(this.computedNamespaced, process.env.VUE_APP_UMU_CLIENT_APP).debug(logObject);
    }
  }

  info(logObject) {
    if (this.active) {
      window.JL(this.computedNamespaced, process.env.VUE_APP_UMU_CLIENT_APP).info(logObject);
    }
  }

  warn(logObject) {
    if (this.active) {
      window.JL(this.computedNamespaced, process.env.VUE_APP_UMU_CLIENT_APP).warn(logObject);
    }
  }

  error(logObject) {
    if (this.active) {
      window.JL(this.computedNamespaced, process.env.VUE_APP_UMU_CLIENT_APP).error(logObject);
    }
  }

  fatal(logObject) {
    if (this.active) {
      window.JL(this.computedNamespaced, process.env.VUE_APP_UMU_CLIENT_APP).fatal(logObject);
    }
  }
}
