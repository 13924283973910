const Protecciondatos = () => import(/* webpackChunkName: "lopd" */'./views/Protecciondatos.vue');
const Navegacion = () => import(/* webpackChunkName: "navegacion" */'./views/Navegacion.vue');
const PoliticaCookies = () => import(/* webpackChunkName: "navegacion" */'./views/PoliticaCookies.vue');

export default [
  {
    path: '/proteccion-datos',
    name: 'RGPD',
    component: Protecciondatos,
    meta: {
      group: 'shared',
      application: 'proteccion-datos',
    },
  },
  {
    path: '/navegacion',
    name: 'navegacion',
    component: Navegacion,
    meta: {
      requiresAuth: true,
      group: 'shared',
      application: 'navegacion',
    },
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: PoliticaCookies,
    meta: {
      requiresAuth: true,
    },
  },
];
