<template>
  <section class="adminLinks">
    <div class="adminLinks__menuitem no-click">
      {{ $t('apiumTitle') }}<br>
      <button
        class="pose__button button__secondary"
        v-if="!isLoadingComplete"
        @click="incremental"
      >
        <i
          v-if="isLoading"
          class="fal fa-spinner fa-pulse"
        />
        {{ $t('incremental') }}
      </button>
      <button
        class="pose__button button__secondary"
        v-if="!isLoadingIncremental"
        @click="total"
      >
        <i
          v-if="isLoading"
          class="fal fa-spinner fa-pulse"
        />
        {{ $t('total') }}
      </button>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import apiumApi from '@/app/campus-virtual/portal-servicios/apium.api';

export default {
  name: 'AdminLinks',
  computed: {
    ...mapGetters('auth', ['isImpersonating']),
  },
  data() {
    return {
      isLoading: false,
      isLoadingComplete: false,
      isLoadingIncremental: false,
    };
  },
  methods: {
    incremental() {
      this.isLoading = true;
      this.isLoadingIncremental = true;
      apiumApi.incrementalapium()
        .then(() => {
          this.isLoading = false;
          this.isLoadingIncremental = false;
        });
    },
    total() {
      this.isLoading = true;
      this.isLoadingIncremental = true;
      apiumApi.totalapium()
        .then(() => {
          this.isLoading = false;
          this.isLoadingIncremental = false;
        });
    },
  },
};
</script>

<i18n>
{
  "en": {
    "apiumTitle": "Data transfer from ApiUM.",
    "incremental": "Incremental transfer",
    "total": "Transfer all data",
    "becomeUser": "Become user",
    "comeBack": "Stop impersonation"
  },
  "es": {
    "apiumTitle": "Carga de datos desde ApiUM.",
    "incremental": "Carga incremental",
    "total": "Carga total",
    "becomeUser": "Suplantar usuarios",
    "comeBack": "Dejar de suplantar"
  }
}
</i18n>

<style lang="scss">
  .adminLinks {

    &__menuitem {
      background: var(--main-bg-color);
      border-bottom: 1px solid #d8dde1;
      color: #555;
      cursor: pointer;
      display: block;
      padding: 1rem;
      text-decoration: none;
      &.no-click {
        cursor: inherit;
      }
    }
    .pose__button {
      margin: 0.75rem 0.5rem 0.25rem 0;
    }
  }
</style>
