import userApi from '@/app/user/user.api';

import {
  apiRequest,
} from '@/api';

const moduleBaseApiURL = '/pci/transporte-api/bono-transporte/';
const apiVersion = 'v1.0';

const endpoints = {
  userPhoto: `${userApi.endpoints().environmentURL}${userApi.endpoints().userPhoto}`,
  empresas: `${moduleBaseApiURL}${apiVersion}/private/empresas`,
  tiposBono: `${moduleBaseApiURL}${apiVersion}/private/tiposBono`,
  lugaresRecogida: `${moduleBaseApiURL}${apiVersion}/private/lugaresRecogida`,
  tiposApoyo: `${moduleBaseApiURL}${apiVersion}/private/tiposApoyo`,
  solicitudBono: `${moduleBaseApiURL}${apiVersion}/private/solicitud`,
};

function trataParam(param) {
  if (!param || param.length === 0) {
    return '';
  }
  return param;
}

const transporteApi = {
  endpoints: () => endpoints,

  consultaEmpresasBonoUniversitario: () => apiRequest({
    url: endpoints.empresas,
  }).then((response) => response.data),

  consultaTiposBonoUniversitario: (codEmpresa) => apiRequest({
    url: `${endpoints.tiposBono}?codEmpresa=${trataParam(codEmpresa)}`,
  }).then((response) => response.data),

  consultaLugaresRecogida: (codEmpresa) => apiRequest({
    url: `${endpoints.lugaresRecogida}?codEmpresa=${trataParam(codEmpresa)}`,
  }).then((response) => response.data),

  consultaApoyoSensorial: () => apiRequest({
    url: endpoints.tiposApoyo,
  }).then((response) => response.data),

  userPhoto: () => {
    const url = userApi.endpoints().userPhoto;
    return apiRequest({ url }).then((respuesta) => respuesta.data);
  },
  solicitarBono: (solicitud) => apiRequest({
    url: endpoints.solicitudBono,
    method: 'POST',
    data: solicitud,
  }),

};

export default transporteApi;
