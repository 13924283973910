import Home from './views/Home.vue';
import Search from './views/Search.vue';

const Aggregated = () => import(/* webpackChunkName: "Aggregated" */ './views/Aggregated.vue');
const Detail = () => import(/* webpackChunkName: "detail" */ './views/Detail.vue');

export default [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      group: 'campus-virtual',
      application: 'portal-servicios',
    },
  },
  {
    path: '/search/:tag',
    props: true,
    name: 'search',
    component: Search,
    meta: {
      requiresAuth: true,
      group: 'campus-virtual',
      application: 'portal-servicios',
    },
  },
  {
    path: '/detail/:identifier',
    props: true,
    name: 'detail',
    component: Detail,
    meta: {
      requiresAuth: true,
      group: 'campus-virtual',
      application: 'portal-servicios',
    },
  },
  {
    path: '/view/:route/:identifier?',
    props: true,
    name: 'aggregated',
    component: Aggregated,
    meta: {
      requiresAuth: true,
      group: 'campus-virtual',
      application: 'portal-servicios',
    },
  },
];
