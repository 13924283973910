/* eslint-disable no-unused-vars */
const pages = {
  namespaced: true,
  state: () => ({
    title: '',
    isPublic: false,
    isStandalone: false,
  }),
  mutations: {
    PUBLIC(state, value) {
      state.isPublic = value;
    },
    SET_TITLE(state, title) {
      const { language } = this.getters['pages/rootState'].language;
      const defaultTitle = language === 'en'
        ? 'University of Murcia Service site'
        : 'Portal de Servicios de la Universidad de Murcia';
      state.title = title;
      document.title = `${title ? `${title} - ` : ''}${defaultTitle}`;
    },
    SET_STANDALONE(state, value) {
      state.isStandalone = value;
    },
  },
  getters: {
    rootState: (state, getters, rootState, rootGetters) => rootState,
  },
};

export default pages;
