const Inicio = () => import(/* webpackChunkName: "trasladopauPortal" */ './views/Inicio.vue');
const TrasladoWizard = () => import(/* webpackChunkName: "trasladopauTrasladoWizard" */ './views/TrasladoWizard.vue');
const EleccionPrueba = () => import(/* webpackChunkName: "trasladopauEleccionPrueba" */ './views/EleccionPrueba.vue');
const FormularioSolicitud = () => import(/* webpackChunkName: "trasladopauFormularioSolicitud" */ './views/FormularioSolicitud.vue');
const Borrador = () => import(/* webpackChunkName: "trasladopauBorrador" */ './views/Borrador.vue');
const DatosPago = () => import(/* webpackChunkName: "trasladopauDatosPago" */ './views/DatosPago.vue');
const RetoMovil = () => import(/* webpackChunkName: "trasladoRetoMovil" */ '@/app/acade/shared/retomovil/views/RetoMovil.vue');
const Resumen = () => import(/* webpackChunkName: "trasladopauResumen" */ './views/Resumen.vue');
const Impresion = () => import(/* webpackChunkName: "trasladopauImpresion" */ './views/Impresion.vue');

const WizardSubsanacion = () => import(/* webpackChunkName: "trasladopauWizardSubsanacion" */ './views/subsanacion/WizardSubsanacion.vue');
const InicioSubsanacion = () => import(/* webpackChunkName: "trasladopauInicioSubsanacion" */ './views/subsanacion/InicioSubsanacion.vue');
const PagoSubsanacion = () => import(/* webpackChunkName: "trasladopauPagoSubsanacion" */ './views/subsanacion/PagoSubsanacion.vue');
const ResumenSubsanacion = () => import(/* webpackChunkName: "trasladopauResumenSubsanacion" */ './views/subsanacion/ResumenSubsanacion.vue');
const FinSubsanacion = () => import(/* webpackChunkName: "trasladopauFinSubsanacion" */ './views/subsanacion/FinSubsanacion.vue');

export default [
  {
    path: '/trasladopau',
    name: 'InicioPAU',
    component: Inicio,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'trasladopau',
      standalone: true,
    },
  },
  {
    path: '/trasladopau/traslado',
    name: 'TramitePAU',
    component: TrasladoWizard,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'trasladopau',
      standalone: true,
    },
    redirect: '/trasladopau/prueba',
    children: [
      { path: '/trasladopau/prueba', component: EleccionPrueba },
      { path: '/trasladopau/solicitud', component: FormularioSolicitud },
      { path: '/trasladopau/borrador', component: Borrador },
      { path: '/trasladopau/pago', component: DatosPago },
      { name: 'retoMovil', path: '/trasladopau/retomovil', component: RetoMovil },
      { path: '/trasladopau/resumen', component: Resumen },
      { path: '/trasladopau/impresion', component: Impresion },
    ],
  },
  {
    path: '/trasladopau/subsanacion',
    name: 'WizardSubsanacionPAU',
    component: WizardSubsanacion,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'trasladopau',
      standalone: true,
    },
    redirect: '/trasladopau/subsanacion/inicio',
    children: [
      { path: '/trasladopau/subsanacion/inicio', component: InicioSubsanacion },
      { path: '/trasladopau/subsanacion/pago', component: PagoSubsanacion },
      { path: '/trasladopau/subsanacion/resumen', component: ResumenSubsanacion },
      { path: '/trasladopau/subsanacion/fin', component: FinSubsanacion },
    ],
  },
];
