import { defineAsyncComponent } from 'vue';

const MiVehiculo = defineAsyncComponent(() => import(/* webpackChunkName: "MIVEHICULO_Matricula" */'./views/MiVehiculoView.vue'));

const rutaProyecto = '/transporte/mi-vehiculo';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'Mi Vehiculo',
    component: MiVehiculo,
    meta: {
      requiresAuth: true,
      group: 'gente',
      application: 'mi-vehiculo',
    },
  },
];
