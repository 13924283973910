/* eslint-disable no-param-reassign */
import Vue from 'vue';
import servicesApi from '../../services.api';
import slidersDefinition from '../../models/sliders';

export default {
  namespaced: true,
  state: {
    indice: 0,
    cards: [],
    loadingSliders: true,
    areas: { ...slidersDefinition },
    searchedServices: [],
    single: null,
    service: {},
    maximizeEvents: false,
  },
  mutations: {
    LOAD_SINGLE(state, service) {
      state.single = service;
    },
    LOAD_DETAIL(state, service) {
      state.service = service;
    },
    SEARCHED_ITEMS(state, serviceList) {
      for (let i = 0; i < serviceList.length; i += 1) {
        const serviceId = serviceList[i].identifier;
        const idSearch = serviceList[i].searchId;
        if (state.searchedServices.filter((el) => el.serviceId === serviceId).length === 1) {
          const foundIndex = state.searchedServices.findIndex((el) => el.serviceId === serviceId);
          state.searchedServices[foundIndex].searchId = idSearch;
        } else {
          state.searchedServices.push({
            serviceId,
            searchId: idSearch,
          });
        }
      }
    },
    SET_SLIDER(state, slider) {
      if (state.areas[slider.sliderKey] === undefined) {
        const newId = Object.keys(state.areas).length;
        const newKey = slider.sliderKey;
        const newArea = {
          order: newId,
          private: true,
          services: [],
          sliderKey: newKey,
          tidy: false,
          viewType: 'carousel',
          scion: false,
          param: '',
          options: null,
        };
        Vue.set(state.areas, newKey, {
          ...newArea,
          ...slider,
          loaded: true,
        });
        // state.areas[newKey] = { ...newArea, ...slider };
      } else {
        Vue.set(state.areas, slider.sliderKey, {
          ...state.areas[slider.sliderKey],
          ...slider,
          loaded: true,
        });
        // state.areas[slider.sliderKey] = { ...state.areas[slider.sliderKey], ...slider };
      }
    },
    SET_SLIDER_VIEWTYPE(state, data) {
      state.areas[data.sliderKey].viewType = data.viewType;
    },
    SET_SLIDER_ORDER(state, data) {
      state.areas[data.sliderKey].order = data.order;
    },
    SET_CARD(state, card) {
      if (state.cards.filter((el) => el.identifier === card.identifier).length === 0) {
        if (card.scion === false) {
          state.cards.push(card);
        }
      }
    },
    SET_CARD_DETAIL(state, card) {
      if (state.cards.filter((el) => el.identifier === card.identifier).length === 0) {
        state.cards.push(card);
      } else {
        const itemIndex = state.cards.findIndex((el) => el.identifier === card.identifier);
        const timeStamp = Date.now();
        state.cards[itemIndex] = { ...card, updated: timeStamp, complete: true };
        // Las dos siguientes líneas son necesarias para que Vuex se entere de que se ha modificado
        // el array y se haga propagación de datos. Se supone que en Vue 3 esto estará mejor.

        state.cards.push({});
        state.cards.splice(-1, 1);
      }
    },
    TOGGLE_STARRED(state, serviceUuid) {
      const currentArea = state.areas.STARRED_SERVICES;
      if (currentArea.services.filter((el) => el.identifier === serviceUuid).length === 0) {
        const newElement = currentArea.services
          .map((InnerService) => ({
            identifier: InnerService.identifier,
            order: InnerService.order + 1,
          }));
        currentArea.services = [...newElement];
        currentArea.services.unshift({
          identifier: serviceUuid,
          order: 0,
        });
      } else {
        const index = currentArea.services.findIndex((el) => el.identifier === serviceUuid);
        currentArea.services = [
          ...currentArea.services.slice(0, index),
          ...currentArea.services.slice(index + 1),
        ];
      }
    },
    TOGGLE_RECOMMEND(state, serviceUuid) {
      const currentArea = state.areas.UMRECOMMENDED;
      if (currentArea.services.filter((el) => el.identifier === serviceUuid).length === 0) {
        const newElement = currentArea.services
          .map((InnerService) => ({
            identifier: InnerService.identifier,
            order: InnerService.order + 1,
          }));
        currentArea.services = [...newElement];
        currentArea.services.unshift({
          identifier: serviceUuid,
          order: 0,
        });
      } else {
        const index = currentArea.services.findIndex((el) => el.identifier === serviceUuid);
        currentArea.services = [
          ...currentArea.services.slice(0, index),
          ...currentArea.services.slice(index + 1),
        ];
      }
    },
    TOGGLE_SLIDER_VISIBILITY(state, sliderKey) {
      if (state.areas[sliderKey] !== undefined) {
        state.areas[sliderKey].visible = !state.areas[sliderKey].visible;
      }
    },
    SHOW_SLIDER_VISIBILITY(state, sliderKey) {
      if (state.areas[sliderKey] !== undefined) {
        state.areas[sliderKey].visible = true;
      }
    },
    LOADING_SLIDERS(state) {
      state.loadingSliders = true;
    },
    END_LOADING_SLIDERS(state) {
      state.loadingSliders = false;
    },
    TOGGLE_EVENTS(state) {
      state.maximizeEvents = !state.maximizeEvents;
    },
  },
  actions: {
    loadDetail({ commit }, uuid) {
      servicesApi.detailCard(uuid)
        .then((r) => r.data)
        .then((service) => {
          commit('pages/SET_TITLE', `${service.name || ''}`, { root: true });
          commit('LOAD_DETAIL', service);
        });
    },
    loadDetailByURL({ commit }, url) {
      servicesApi.detailCardByURL(url)
        .then((r) => r.data)
        .then((service) => {
          commit('pages/SET_TITLE', `${service.name || ''}`, { root: true });
          commit('LOAD_DETAIL', service);
        })
        .catch(() => {
          servicesApi.detailCardByURL(`${url}/`)
            .then((r) => r.data)
            .then((service) => {
              commit('pages/SET_TITLE', `${service.name || ''}`, {
                root: true,
              });
              commit('LOAD_DETAIL', service);
            });
        });
    },
    toStarred({ commit, state }, param) {
      commit('TOGGLE_STARRED', param.serviceId);
      const starred = state.areas.STARRED_SERVICES.services
        .map((el) => ({
          identifier: el.identifier,
          order: `${el.order}`,
        }));
      servicesApi.setStarred(starred)
        .catch(() => {
          commit('TOGGLE_STARRED', param.serviceId);
        });
    },
    toRecommend({ commit, state }, param) {
      commit('TOGGLE_RECOMMEND', param.serviceId);
      const recommended = state.areas.UMRECOMMENDED.services
        .map((el) => ({
          identifier: el.identifier,
          order: `${el.order}`,
        }));
      servicesApi.setRecommend(recommended)
        .catch(() => {
          commit('TOGGLE_RECOMMEND', param.serviceId);
        });
    },
    reorder({ commit, state }, data) {
      const {
        arrayServices,
        sliderKey,
      } = data;
      const slider = state.areas[sliderKey];
      const timeStamp = Date.now();
      commit('SET_SLIDER', {
        sliderKey: slider.sliderKey,
        services: arrayServices,
        updated: timeStamp,
        viewType: slider.viewType,
      });
      if (sliderKey === 'STARRED_SERVICES') {
        servicesApi.setStarred(arrayServices.map((el) => ({
          identifier: el.identifier,
          order: `${el.order}`,
        })));
      } else if (sliderKey === 'UMRECOMMENDED') {
        servicesApi.setRecommend(arrayServices.map((el) => ({
          identifier: el.identifier,
          order: `${el.order}`,
        })));
      }
    },
    setCardList({ commit, state, getters }, service) {
      const {
        viewType, howMany, tag,
      } = state.areas[service.type];
      const isLogged = getters.moduleIsLogged;
      // POSE-859 - Desactivamos los carriles por rol hasta que se definan.
      const isForMe = true;
      if (isLogged && !isForMe) {
        const timeStamp = new Date().getTime();
        commit('SET_SLIDER', {
          sliderKey: service.type,
          services: [],
          updated: timeStamp,
          viewType,
        });
      } else {
        servicesApi.lazySlider({
          service, isLogged, howMany, tag,
        })
          .then((r) => r.data)
          .then((cards) => {
            const cardsVisibles = cards.filter((el) => el.scion === false);
            const timeStamp = Date.now();
            commit('SET_SLIDER', {
              sliderKey: service.type,
              services: cardsVisibles,
              updated: timeStamp,
              viewType,
            });
            for (let i = 0; i < cardsVisibles.length; i += 1) {
              const card = cardsVisibles[i];
              if (['STARRED_SERVICES', 'UMRECOMMENDED'].includes(service.type)) {
                card.order = i;
              }
              commit('SET_CARD', { ...card, updated: timeStamp });
            }
          });
      }
    },
    loadCard({ commit }, uuid) {
      servicesApi.detailCard(uuid)
        .then((r) => r.data)
        .then((card) => {
          commit('SET_CARD_DETAIL', card);
        });
    },

    setIsCarousel({ commit, state }, sliderData) {
      const isItLogged = sliderData.logged;
      commit('SET_SLIDER_VIEWTYPE', {
        sliderKey: sliderData.sliderKey,
        viewType: sliderData.viewType,
      });
      if (isItLogged) {
        const sendSlider = Object.values(state.areas)
          .map((el) => ({
            order: el.order,
            sliderKey: el.sliderKey,
            viewType: el.viewType,
            visible: ((el.visible) ? '1' : '0'),
          }));
        servicesApi.setSliders(sendSlider);
      }
    },

    reorderSliders({ commit, state }, arraySliders) {
      const timeStamp = Date.now();
      for (let i = 0; i < arraySliders.length; i += 1) {
        commit('SET_SLIDER_ORDER', {
          sliderKey: arraySliders[i].sliderKey,
          services: arraySliders[i].services,
          updated: timeStamp,
          viewType: arraySliders[i].viewType,
          order: arraySliders[i].order,
        });
      }
      const sendSlider = Object.values(state.areas)
        .map((el) => ({
          order: el.order,
          sliderKey: el.sliderKey,
          viewType: el.viewType,
          visible: ((el.visible) ? '1' : '0'),
        }));
      servicesApi.setSliders(sendSlider);
    },

    toggleVisibility({ commit, state }, key) {
      commit('TOGGLE_SLIDER_VISIBILITY', key);
      const sendSlider = Object.values(state.areas)
        .map((el) => ({
          order: el.order,
          sliderKey: el.sliderKey,
          viewType: el.viewType,
          visible: ((el.visible) ? '1' : '0'),
        }));
      servicesApi.setSliders(sendSlider)
        .catch(() => {
          commit('TOGGLE_SLIDER_VISIBILITY', key);
        });
    },
    showSliders({ commit, state }) {
      Object.keys(state.areas).forEach((key) => {
        commit('SHOW_SLIDER_VISIBILITY', key);
      });
      const sendSlider = Object.values(state.areas)
        .map((el) => ({
          order: el.order,
          sliderKey: el.sliderKey,
          viewType: el.viewType,
          visible: '1',
        }));
      servicesApi.setSliders(sendSlider);
    },
    getSlidersInfo({ commit }) {
      commit('LOADING_SLIDERS');
      servicesApi.getSliders()
        .then((r) => r.data)
        .then((carouselList) => {
          // La estructura viene marcada por el backend
          const sliderList = carouselList.map((slider) => ({
            sliderKey: slider.sliderId.key,
            viewType: slider.type,
            order: slider.order,
            visible: slider.visible === '1',
          }));
          const sliderKeys = Object.values(slidersDefinition).map((el) => el.sliderKey);
          Object.values(sliderList).forEach((slider) => {
            if ((sliderKeys).includes(slider.sliderKey)) {
              commit('SET_SLIDER', slider);
            }
          });
          commit('END_LOADING_SLIDERS');
        });
    },
    loadSingle({ commit }, uuid) {
      servicesApi.detailCard(uuid)
        .then((r) => r.data)
        .then((service) => {
          commit('LOAD_SINGLE', service);
        })
        .catch((error) => {
          commit('API_DATA_FAILURE', error);
        });
    },
  },
  getters: {
    moduleIsLogged: (state, getters, rootState, rootGetters) => rootGetters.isLogged,
  },
};
