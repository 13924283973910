export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "tidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrange"])},
        "tidy_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrange"])},
        "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all elements"])},
        "showmore_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all elements"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
        "next_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next page"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prev"])},
        "prev_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous page"])}
      },
      "es": {
        "tidy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar"])},
        "tidy_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar"])},
        "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos"])},
        "showmore_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver todos los elementos"])},
        "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siguiente"])},
        "next_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página siguiente"])},
        "prev": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anterior"])},
        "prev_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Página anterior"])}
      }
    }
  })
}
