<template>
  <aside
    class="tramitesSugeridos"
    v-if="showing"
  >
    <span class="tramitesSugeridos__dismiss" @click="dismiss">
      <i class="fa-duotone fa-xmark"></i>
    </span>
    <h1 class="tramitesSugeridos__title">
      {{ $t('titlegeneral') }}
    </h1>
    <div class="tramitesSugeridos__container">
      <InlineMessage severity="info" class="tramitesSugeridos__info">
        <p> {{ $t('tramites_sugeridos') }}</p>
      </InlineMessage>

      <div class="grid grid-nogutter" v-for="(tramite, index) of tramites"
           :key="tramite.codigo">
        <Fieldset :legend="tramite.descripcion" :toggleable="true"
                  class="tramitesSugeridos__fieldset" :collapsed="index > 0">
          <div class="col-12 grid grid-nogutter tramitesSugeridos__section">
            <div class="col-12 informacion">
              <a :href="tramite.urlCatalogo" target="_blank"
                class="enlace-primario">
                {{ $t('info_tramite_sugerido') }}
              </a>
            </div>
          </div>
          <div class="col-12 grid grid-nogutter tramitesSugeridos__section">
            <div class="col-12">
              <span class="font-semibold">{{$t('tramite_acceso')}}</span>
            </div>
            <ul>
              <li v-for="formulario of tramite.formulariosInicio"
                  :key="formulario.titulo">
                <a class="enlace-primario-destacado" :href="formulario.url"
                   target="_blank">
                    {{ formulario.titulo }}
                </a>
              </li>
            </ul>
          </div>
          <div class="col-12 grid grid-nogutter tramitesSugeridos__section"
               v-if="tramite.modosAcceso">
            <div class="col-12">
              <span class="font-semibold">{{$t('tramite_modos_acceso')}}</span>
            </div>
            <ul>
              <template v-for="modoAcceso of tramite.modosAcceso"
                  :key="modoAcceso">
                <li v-if="getLabelModoAcceso(modoAcceso)">
                  <span>{{ $t(getLabelModoAcceso(modoAcceso)) }}</span>
                </li>
              </template>
            </ul>
          </div>
        </Fieldset>
      </div>
    </div>
    <button class="pose__button" @click="verMasTramites()" v-if="showMore">
        {{ $t('mostrar_mas_tramites') }}
    </button>
  </aside>
</template>

<script>
import { defineComponent } from 'vue';
import Fieldset from 'primevue/fieldset';
import InlineMessage from 'primevue/inlinemessage';
import clickOutside from '@/app/shared/directives/clickOutside';
import tramitesApi from '../mis-tramites.api';
import modalsMixin from '../../../shared/mixins/modalsMixin';

const compatMode3 = { MODE: 3 };
Fieldset.compatConfig = compatMode3;
InlineMessage.compatConfig = compatMode3;

const viewPercentage = 50;
const hoursOff = 7 * 24; // 7 days, 24 hours

export default defineComponent({
  name: 'TramitesSugeridos',
  mixins: [modalsMixin],
  data() {
    return {
      showable: false,
      tramites: null,
      showMore: false,
      showMax: 3,
      modal: {
        name: 'tramites-sugeridos',
        viewPercentage,
        hoursOff,
      },
    };
  },
  components: {
    Fieldset,
    InlineMessage,
  },
  computed: {
    shouldShow() {
      return this.showable;
    },
  },
  directives: {
    clickOutside,
  },
  methods: {
    getLabelModoAcceso(idModo) {
      switch (idModo) {
        case 'CERTIFICADO_ELECTRONICO':
          return 'acceso_certificado_electronico';
        case 'CLAVE':
          return 'acceso_clave';
        case 'CUENTA_UM':
          return 'acceso_cuenta_um';
        case 'USUARIO_CONTRASENA':
          return 'acceso_usuario_contrasena';
        default:
          return null;
      }
    },
    dismiss() {
      this.showable = false;
      this.closeModal();
    },
    verMasTramites() {
      this.dismiss();
      this.$router.push('/mis-tramites/nuevo-tramite/');
    },
    initTramitesSugeridos() {
      tramitesApi.consultaTramitesSugeridos()
        .then((response) => {
          if (response.data._embedded && response.data._embedded.tipos) {
            this.tramites = response.data._embedded.tipos.slice(0, this.showMax);
            this.showable = this.tramites.length > 0;
            this.showMore = response.data._embedded.tipos.length > this.showMax;

            // Se intenta mostrar el modal
            this.tryShowModal(this.shouldShow);
          }
        });
    },
  },
  mounted() {
    // Si no se está mostrando ningún otro modal
    if (!this.isShowingAnotherModal()) {
      this.initTramitesSugeridos();
    }
  },
});
</script>

<i18n src="../locales/localesTramitesSugeridos.json"></i18n>

<style lang="scss" scoped>
.tramitesSugeridos {
  background: var(--card-bg-color);
  border-radius: var(--card-border-radius);
  padding: 0.25rem 1rem;
  z-index: 10;
  bottom: 1.5rem;
  box-shadow: var(--shadowLevel-3);
  margin: 0;
  position: fixed;
  right: 1.5rem;
  max-height: max(85vh, calc(98vh - var(--header-size) - 3rem));
  overflow: auto;
  @media (min-width: $screen-sm-min) {
    width: 25rem;
  }
  @media (max-width: $screen-sm-min) {
    left: 1.5rem
  }

  &__dismiss {
    cursor: pointer;
    float: right;
    font-size: 1.25rem;
    margin: 0.15rem -0.5rem 0 0;
    text-align: center;
    width: 1.5rem;
    position: sticky;
    top: calc(var(--header-size) - 3rem);
  }

  &__title {
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1.5rem;
    margin: 1rem 0 0.75rem 0;
    padding: 0 0 0.5rem 0;
  }

  &__container {
    font-size: 0.925em;
  }

  &__section {
    font-size: 0.9em;
  }

  &__section ul {
    margin-top: 5px;
    margin-bottom: 0;
  }

  &__fieldset {
    background: inherit;
    padding: 0;
    width: 100%;
    margin-bottom: 25px;
  }

  &__info {
    margin-bottom: 15px;
  }
}
:global(.tramitesSugeridos__container .tramitesSugeridos__fieldset .p-fieldset-content) {
  padding: 0 0 0 0.8rem;
}
:global(.tramitesSugeridos__fieldset.p-fieldset.p-fieldset-toggleable .p-fieldset-legend) {
  margin-bottom: 0.5rem;
}
:global(.tramitesSugeridos__fieldset.p-fieldset.p-fieldset-toggleable .p-fieldset-legend a) {
  padding: unset;
}
:global(.tramitesSugeridos__fieldset.p-fieldset .p-fieldset-legend .p-fieldset-legend-text) {
  font-size: 0.95em;
}
</style>
