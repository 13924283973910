<template>
  <div
    class="openedCarousel"
    :class="getClass"
    v-if="serviceList"
  >
    <side-menu
      v-if="showButton"
      class="openedCarousel__sideMenu"
      :show-less="true"
      :options="options"
      :can-carousel="canCarousel"
      :small-cards="smallCards || null"
      @carouselActivate="carouselView"
      @carouselFilter="carouselFilter"
    />
    <nav
      v-if="pagination"
      class="openedCarousel__pagination"
    >
      {{ $t('pages', [page, pages]) }}
      <ol class="openedCarousel__paginationList">
        <li
          v-for="i in pages"
          :key="i"
          class="openedCarousel__paginationItem"
          :class="{isActive: page === i}"
          @click="setPage(i, false)"
        >
          {{ i }}
        </li>
      </ol>
    </nav>
    <div
      class="openedCarousel__items"
      v-if="!isTidy"
    >
      <lazy-card
        class="openedCarousel__item"
        v-for="item in serviceListCurated"
        :key="item.identifier"
        :service="item"
        :small="smallCards"
        :show-favourite="showFavourite"
        :should-load="shouldLoad"
      />
    </div>
    <div
      class="openedCarousel__items"
      v-else
    >
      <draggable
        v-if="!isMobile()"
        v-model="draggableServiceList"
        item-key="identifier"
        class="openedCarousel__draggable"
        tag="transition-group"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
      >
        <template #item="{element}">
          <lazy-card
            class="openedCarousel__item"
            :key="element.identifier"
            :service="element"
            :small="smallCards"
            :show-favourite="showFavourite"
            :should-load="shouldLoad"
          />
        </template>
      </draggable>
      <div v-else>
        <lazy-card
          class="openedCarousel__item"
          v-for="item in serviceListCurated"
          :key="item.identifier"
          :service="item"
          :small="smallCards"
          :show-favourite="showFavourite"
          :should-load="shouldLoad"
        />
      </div>
      <nav
        v-if="pagination"
        class="openedCarousel__pagination"
      >
        {{ $t('pages', [page, pages]) }}
        <ol class="openedCarousel__paginationList">
          <li
            v-for="i in pages"
            :key="i"
            class="openedCarousel__paginationItem"
            :class="{isActive: page === i}"
            @click="setPage(i)"
          >
            {{ i }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import draggable from 'vuedraggable';

import mobileMixin from '@/app/shared/mixins/mobileMixin';

import LazyCard from './LazyCard.vue';
import SideMenu from './SideMenu.vue';

export default defineComponent({
  name: 'CardGroupOpened',
  emits: [
    'carouselView',
    'carouselFilter',
    'pageActiveChange',
  ],
  components: {
    draggable,
    LazyCard,
    SideMenu,
  },
  props: {
    serviceList: { type: Array, required: true },
    pagination: { type: Boolean, default: false },
    transformable: { type: Boolean, default: true },
    tidy: { type: Boolean, default: false },
    showDescription: { type: Boolean, default: true },
    showButton: { type: Boolean, default: true },
    smallCards: { type: Boolean, default: false },
    options: { type: Object, required: false, default: null },
    showFavourite: { type: Boolean, default: true },
    shouldLoad: { type: Boolean, default: true },
  },
  data() {
    return {
      canCarousel: this.serviceList.length > 4 && this.transformable && !this.tidy,
      maxItems: 20,
      page: 1,
    };
  },
  mixins: [mobileMixin],
  computed: {
    ...mapGetters('auth', ['isLogged']),
    ...mapGetters('user', ['isAdmin']),
    isTidy() {
      return (this.options && this.options.sliderKey === 'UMRECOMMENDED' && !this.isAdmin)
        ? false : this.tidy;
    },
    serviceListCurated() {
      return this.pagination
        ? this.serviceList.slice(
          (this.page - 1) * this.maxItems,
          this.page * this.maxItems,
        )
        : this.serviceList;
    },
    draggableServiceList: {
      get() {
        return this.serviceListCurated;
      },
      set(value) {
        const newValueOrder = [];
        for (let i = 0; i < value.length; i += 1) {
          newValueOrder.push({
            ...value[i],
            order: i,
          });
        }
        this.$store.dispatch('services/reorder', {
          arrayServices: newValueOrder,
          sliderKey: this.options.sliderKey,
        });
      },
    },
    pages() {
      const pagesCount = Math.floor(this.serviceList.length / this.maxItems);
      return this.serviceList.length % this.maxItems === 0
        ? pagesCount
        : pagesCount + 1;
    },
    getClass() {
      return this.smallCards ? 'is-small' : '';
    },
  },
  methods: {
    carouselView() {
      this.$emit('carouselView', 'carousel');
    },
    carouselFilter(evt) {
      this.$emit('carouselFilter', evt);
    },
    setPage(page, scrollable = true) {
      if (scrollable) {
        const el = document.getElementsByClassName(
          'openedCarousel__pagination',
        )[0];
        const distanceToTop = Math.floor(el.getBoundingClientRect().top);
        window.scrollTo({ top: distanceToTop, left: 0, behavior: 'smooth' });
      }
      this.page = page;
    },
  },
  mounted() {
    this.$emit('pageActiveChange', this.serviceList.length);
  },
});
</script>

<i18n>
{
  "en": {
    "showless": "Show less elements",
    "showless_acc": "Show less elements",
    "elements": "Showing {0} out of {1} elements",
    "pages": "Page {0} out of {1}"
  },
  "es": {
    "showless": "Ver menos",
    "showless_acc": "Ver menos elementos",
    "elements": "Mostrando {0} de {1} elementos",
    "pages": "Página {0} de {1}"
  }
}
</i18n>

<style lang="scss">

.openedCarousel {
  position: relative;
  .cardGroupList {
    &:not(.starred-services) & {
        min-height: var(--carousel-height);
    }
  }

  &__pagination {
    text-align: center;
  }

  &__draggable {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .card {
      cursor: move;
    }
  }

  &__paginationList {
    padding: 1rem 0 0 0;
    margin: 0 0 0 0;
    list-style: none;
  }

  &__paginationItem {
    background-color: var(--main-text-color);
    display: inline-block;
    margin: 0 0.5rem;
    padding: 0.5rem 0.8rem;
    color: var(--main-bg-color);
    cursor: pointer;
    user-select: none;
    @media (max-width: $screen-sm-min) {
      margin: 0 0.25rem;
      padding: 0.25rem 0.4rem;
    }
    &.isActive {
      background-color: var(--main-color);
      color: var(--main-bg-color);
    }
  }

  &__length {
    color: var(--title-color);
    display: block;
    font-size: 0.75rem;
    margin: -1rem 0 0 0;
    padding: 0 0 0 6px;
  }

  &__nav {
    position: absolute;
    top: -1rem;
    right: 6px;
    user-select: none;
    @media (max-width: $screen-phone) {
      display: block;
      margin: 1rem 0;
      position: initial;
      top: 0;
    }
  }

  &__items {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    justify-content: flex-start;
    margin: 1rem 0 2rem 0;
    flex-wrap: wrap;
    &:empty {
      min-height: var(--carousel-height);
    }
    > div {
      display: flex;
      @media (max-width: $screen-sm-min) {
        flex-direction: column;
      }
    }
  }

  &__item.card {
    max-width: var(--carousel-item-width);
    width: var(--carousel-item-width);
  }

  &__sideMenu {
    float: right;
    margin-top: -2.9rem;
    transition: linear 0.25s opacity;
    @media (max-width: $screen-sm-min) {
      margin-top: -3.45rem;
    }
  }
}
.openedCarousel__item.card.ghost {
  border: 2px dashed var(--border-color);
  background-color: transparent;
  > * {
    display: none;
  }
}

</style>
