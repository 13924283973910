const mobileMixin = {
  methods: {
    isMobile() {
      return !(window && window.matchMedia('(hover: hover)').matches);
    },
    isTouchable() {
      return (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) ? 'off' : 'on';
    },
  },
};

export { mobileMixin as default };
