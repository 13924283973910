export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to My campus UMU"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What should you know about My Campus?"])},
        "welcomeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information, services and procedures of the University of Murcia online."])}
      },
      "es": {
        "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te damos la bienvenida a Mi campus UMU"])},
        "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué debes saber de Mi campus?"])},
        "welcomeDesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información, servicios y trámites en línea de la Universidad de Murcia."])}
      }
    }
  })
}
