<template>
  <div />
</template>

<script>
export default {
  name: 'Login',
  async created() {
    const { code } = window.location.search
      .slice(1)
      .split('&')
      .map((p) => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {});
    await this.$store.dispatch('auth/accessToken', code);
  },
};
</script>
