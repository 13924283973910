import {
  apiRequest,
} from '@/api';

import CodigosTramite from '@/app/acade/shared/enums/EnumCodigosTramite';

const moduleBaseApiURLTrasladoExpEntrante = '/acade/trasladoexpent-api/v1.0/private';
const moduleBaseApiURLAcadeApiTramite = '/acade/acade-tramites-api/v1.0/private';

const endpoints = {

  /** Servicios Rest Solicitud */
  obtenerDatosPersonalesEstudiante: `${moduleBaseApiURLTrasladoExpEntrante}/estudiantes`,
  guardarSolicitud: `${moduleBaseApiURLTrasladoExpEntrante}/solicitudes`,
  obtenerSolicitud: `${moduleBaseApiURLTrasladoExpEntrante}/solicitudes`,
  obtenerEstudios: `${moduleBaseApiURLTrasladoExpEntrante}/destinos/estudios`,
  obtenerAsignaturasEstudio: `${moduleBaseApiURLTrasladoExpEntrante}/estudios/asignaturas`,
  subirArchivo: `${moduleBaseApiURLAcadeApiTramite}/solicitudes/archivos`,
  obtenerDocumento: `${moduleBaseApiURLAcadeApiTramite}/solicitudes/archivos`,
  borrarDocumento: `${moduleBaseApiURLAcadeApiTramite}/solicitudes/archivos`,
  obtenerCursoAcademico: `${moduleBaseApiURLTrasladoExpEntrante}/cursoacademicos`,
  guardarTitulacionNueva: `${moduleBaseApiURLTrasladoExpEntrante}/estudios`,
  guardarUniversidadNueva: `${moduleBaseApiURLTrasladoExpEntrante}/universidades`,
  obtenerUltimaTitulacionNueva: `${moduleBaseApiURLTrasladoExpEntrante}/estudios`,
  guardarAsignaturasNuevas: `${moduleBaseApiURLTrasladoExpEntrante}/asignaturas`,
  obtenerUltimaAsignaturaNueva: `${moduleBaseApiURLTrasladoExpEntrante}/asignaturas`,
  obtenerUltimaUniversidadNueva: `${moduleBaseApiURLTrasladoExpEntrante}/universidades`,
  requisitiosTramite: `${moduleBaseApiURLAcadeApiTramite}/tramites/requisitos`,

  // Origen Universidad Murcia
  obtenerTitulacionesUM: `${moduleBaseApiURLTrasladoExpEntrante}/estudioscursados`,
  obtenerAsignaturasTitulacionUniversidadMurcia: `${moduleBaseApiURLTrasladoExpEntrante}/estudioscursados/asignaturas`,

  // Origen universidades españolas
  obtenerUniversidadesEspañolas: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesespanolas`,
  obtenerTitulacionesUniversidadesEspañolas: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesespanolas/estudios`,
  obtenerAsignaturasUniversidadesEspañolas: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesespanolas/estudios/asignaturas`,
  obtenerTiposAsignaturas: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesespanolas/tiposasignaturas`,

  // Origen Universidades extranjeras
  obtenerPaises: `${moduleBaseApiURLTrasladoExpEntrante}/paises`,
  obtenerUniversidadesExtranjeras: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesextranjeras`,
  obtenerEstudiosUniversidadesExtranjeras: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesextranjeras/estudios`,
  obtenerAsignaturasEstudioExtranjera: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesextranjeras/estudios/asignaturas`,
  obtenerTiposAsignaturasEstudioExtranjera: `${moduleBaseApiURLTrasladoExpEntrante}/universidadesextranjeras/tiposasignaturas`,

  /** Firma */
  iniciaTransaccionFirma: `${moduleBaseApiURLTrasladoExpEntrante}/firma`,
  finTransaccionFirma: `${moduleBaseApiURLTrasladoExpEntrante}/firma`,

  // Subsanación
  obtenerExpedientesPendienteSubsanacion: `${moduleBaseApiURLAcadeApiTramite}/expedientes`,
  obtenerSolicitudPendienteSubsanacion: `${moduleBaseApiURLAcadeApiTramite}/expedientes/solicitudes`,
  obtenerDocumentoExpediente: `${moduleBaseApiURLAcadeApiTramite}/expedientes/documentos`,
  subsanarExpediente: `${moduleBaseApiURLAcadeApiTramite}/expedientes/subsanar`,

  // Sesiones
  guardarSesion: `${moduleBaseApiURLAcadeApiTramite}/sesiones`,
  obtenerSesion: `${moduleBaseApiURLAcadeApiTramite}/sesiones`,

};

const trasladoExpedienteEntranteApi = {
  endpoints: () => endpoints,
  obtenerDatosPersonalesEstudiante: () => apiRequest({
    url: endpoints.obtenerDatosPersonalesEstudiante,
  }),
  requisitiosTramite: (codigoTramite) => apiRequest({
    url: `${endpoints.requisitiosTramite}?codigoTramite=${codigoTramite}`,
  }),
  obtenerSolicitud: () => apiRequest({
    url: `${endpoints.obtenerSolicitud}`,
  }),
  guardarSolicitud: (jsonSolicitud) => apiRequest({
    url: `${endpoints.guardarSolicitud}`,
    data: jsonSolicitud,
    method: 'POST',
  }),
  obtenerEstudios: () => apiRequest({
    url: `${endpoints.obtenerEstudios}`,
  }),
  obtenerAsignaturasEstudio: (codigoTitulacion) => apiRequest({
    url: `${endpoints.obtenerAsignaturasEstudio}?codigoTitulacion=${codigoTitulacion}`,
  }),
  obtenerTitulacionesUM: () => apiRequest({
    url: endpoints.obtenerTitulacionesUM,
  }),
  obtenerAsignaturasTitulacionUniversidadMurcia: (parametros) => apiRequest({
    url: `${endpoints.obtenerAsignaturasTitulacionUniversidadMurcia}?codigoTitulacion=${parametros.codigo}&tipoEstudio=${parametros.tipoEstudio}&codigoPlan=${parametros.codigoPlan}`,
  }),
  obtenerUniversidadesEspañolas: () => apiRequest({
    url: endpoints.obtenerUniversidadesEspañolas,
  }),
  obtenerTitulacionesUniversidadesEspañolas: (params) => apiRequest({
    url: `${endpoints.obtenerTitulacionesUniversidadesEspañolas}?codigoUniversidad=${params.codigoUniversidad}&tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  obtenerAsignaturasUniversidadesEspañolas: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasUniversidadesEspañolas}?secuenciaUniversidad=${params.secuenciaUniversidad}&codigoTitulacion=${params.codigoEstudio}&tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}&codigoPlan=${params.codigoPlan}`,
  }),
  obtenerTiposAsignaturas: () => apiRequest({
    url: endpoints.obtenerTiposAsignaturas,
  }),
  subirArchivo: (file) => apiRequest({
    url: `${endpoints.subirArchivo}?userAlfresco=userAcadeserviciosAlfresco&codigoTramite=${CodigosTramite.TRASLADO_EXPEDIENTE_ENTRANTE.codigoTramite}`,
    method: 'POST',
    data: file,
  }),
  obtenerDocumento: (pid) => apiRequest({
    url: `${endpoints.obtenerDocumento}?pidDocumento=${pid}&userAlfresco=userAcadeserviciosAlfresco`,
  }),
  borrarDocumento: (pidDocumento) => apiRequest({
    url: `${endpoints.borrarDocumento}?userAlfresco=userAcadeserviciosAlfresco`,
    data: pidDocumento,
    method: 'DELETE',
  }),
  obtenerPaises: () => apiRequest({
    url: `${endpoints.obtenerPaises}`,
  }),
  obtenerUniversidadesExtranjeras: (params) => apiRequest({
    url: `${endpoints.obtenerUniversidadesExtranjeras}?tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  obtenerEstudiosUniversidadesExtranjeras: (params) => apiRequest({
    url: `${endpoints.obtenerEstudiosUniversidadesExtranjeras}?tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  obtenerAsignaturasEstudioExtranjera: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasEstudioExtranjera}?codigoUniversidad=${params.codigoUniversidad}&codigoTitulacion=${params.codigoTitulacion}&tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  obtenerTiposAsignaturasEstudioExtranjera: () => apiRequest({
    url: endpoints.obtenerTiposAsignaturasEstudioExtranjera,
  }),
  obtenerCursoAcademico: () => apiRequest({
    url: endpoints.obtenerCursoAcademico,
  }),
  guardarSesion: (sesion) => apiRequest({
    url: `${endpoints.guardarSesion}`,
    method: 'POST',
    data: sesion,
  }),
  obtenerSesion: (idSession) => apiRequest({
    url: `${endpoints.obtenerSesion}?idSession=${idSession}`,
  }),
  iniciaTransaccionFirma: (parametros) => apiRequest({
    url: `${endpoints.iniciaTransaccionFirma}`,
    data: parametros,
    method: 'POST',
  }),
  finTransaccionFirma: (idTransaccion, idSession, modoFinFirma) => apiRequest({
    url: `${endpoints.finTransaccionFirma}?idTransaccion=${idTransaccion}&idSession=${idSession}&modoFinFirma=${modoFinFirma}`,
    method: 'PUT',
  }),
  guardarTitulacionNueva: (params) => apiRequest({
    url: `${endpoints.guardarTitulacionNueva}`,
    data: params,
    method: 'POST',
  }),
  guardarUniversidadNueva: (params) => apiRequest({
    url: `${endpoints.guardarUniversidadNueva}`,
    data: params,
    method: 'POST',
  }),
  obtenerUltimaTitulacionNueva: (params) => apiRequest({
    url: `${endpoints.obtenerUltimaTitulacionNueva}?codigoUniversidad=${params.codUni}&tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  obtenerUltimaUniversidadNueva: (params) => apiRequest({
    url: `${endpoints.obtenerUltimaUniversidadNueva}?tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  guardarAsignaturasNuevas: (params) => apiRequest({
    url: `${endpoints.guardarAsignaturasNuevas}`,
    data: params,
    method: 'POST',
  }),
  obtenerUltimaAsignaturaNueva: (params) => apiRequest({
    url: `${endpoints.obtenerUltimaAsignaturaNueva}?codigoTitulacion=${params.codigoTitulacion}&tipoOrigen=${params.tipoOrigen}&secuenciaSolicitud=${params.secuenciaSolicitud}`,
  }),
  obtenerExpedientesPendienteSubsanacion: (codigoTramite) => apiRequest({
    url: `${endpoints.obtenerExpedientesPendienteSubsanacion}?codTramite=${codigoTramite}`,
  }),
  obtenerSolicitudPendienteSubsanacion: (expeSub, codigoTramite) => apiRequest({
    url: `${endpoints.obtenerSolicitudPendienteSubsanacion}?eniExpediente=${expeSub.expediente.identificador}&usuarioUnidadAdministrativa=${expeSub.usuarioUnidadAdministrativa}&codTramite=${codigoTramite}`,
  }),
  obtenerDocumentoExpediente: (expediente, eniDocumento, usuarioUnidad) => apiRequest({
    url: `${endpoints.obtenerDocumentoExpediente}?eniExpediente=${expediente.identificador}&usuarioUnidadAdministrativa=${usuarioUnidad}&eniDocumento=${eniDocumento}`,
  }),
  subsanarExpediente: (solicitud) => apiRequest({
    url: `${endpoints.subsanarExpediente}`,
    method: 'POST',
    data: solicitud,
  }),

};

export default trasladoExpedienteEntranteApi;
