/* eslint-disable no-param-reassign */
import axios from 'axios';

function setImgSrc(el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const imageUrl = binding.value;
    axios({
      method: 'get',
      url: imageUrl,
      responseType: 'arraybuffer',
    })
      .then((resp) => {
        const mimeType = resp.headers['content-type'].toLowerCase();
        // eslint-disable-next-line no-buffer-constructor
        const imgBase64 = new Buffer(resp.data, 'binary').toString('base64');
        el.src = `data:${mimeType};base64,${imgBase64}`;
      }).catch((() => {
        el.src = imageUrl;
      }));
  }
}

export default {
  beforeMount(el, binding) {
    setImgSrc(el, binding);
  },
  updated(el, binding) {
    setImgSrc(el, binding);
  },
};
