export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "ALL_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All our services"])}
        },
        "STARRED_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favourites"])}
        },
        "RECENT_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last visited"])}
        },
        "MOST_USED_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most visited"])}
        },
        "RECOMMENDED": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You might be interested in"])}
        },
        "OTHERS": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For your role"])}
        },
        "PAS": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are Administration and Services Staff"])}
        },
        "PDI": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services that will help you in your teaching"])}
        },
        "STUDIENT": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For you, who are a student"])}
        },
        "UMRECOMMENDED": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to take a look?"])}
        },
        "TAG_UTILIDADES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag: Utilidades"])}
        },
        "TAG_UTILIDADES_ACTAS": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags: Imprimir e Itinerarios"])}
        },
        "NEW_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New services"])}
        },
        "Sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sliders"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Root"])},
        "becomeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become user"])},
        "getBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get back to"])},
        "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting method"])},
        "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetical"])},
        "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifier"])},
        "most": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most visited"])},
        "ema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exponential Moving Average"])},
        "orderSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting order"])},
        "resultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search results on my campus"])},
        "resultTitleAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Related searches"])},
        "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching..."])},
        "emptyResult1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The search for"])},
        "emptyResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yielded no results"])},
        "foundResult1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The search provided"])},
        "foundResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["matches"])},
        "searchingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["searching for"])},
        "searchingForTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Searching for"])},
        "searchingUM1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also search for"])},
        "searchingUM2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in all University"])},
        "searchingUMLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this link"])},
        "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expand"])},
        "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contract"])},
        "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide this slider"])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order"])},
        "showall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])}
      },
      "es": {
        "ALL_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Todo lo que puedes encontrar"])}
        },
        "STARRED_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis atajos"])}
        },
        "RECENT_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recientemente visitados"])}
        },
        "MOST_USED_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más visitados"])}
        },
        "RECOMMENDED": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te puede interesar..."])}
        },
        "OTHERS": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para tu perfil"])}
        },
        "PAS": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si eres Personal de Administración y Servicios"])}
        },
        "PDI": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicios que te ayudarán en tu docencia"])}
        },
        "STUDENT": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para ti, que eres estudiante"])}
        },
        "UMRECOMMENDED": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La UMU te recomienda"])}
        },
        "TAG_UTILIDADES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiqueta: Utilidades"])}
        },
        "TAG_ITINERARIOS_IMPRIMIR": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etiquetas: Imprimir e Itinerarios"])}
        },
        "NEW_SERVICES": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novedades"])}
        },
        "Sliders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carriles"])},
        "Admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
        "becomeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplantar usuarios"])},
        "getBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volver a"])},
        "method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modo de ordenación"])},
        "alpha": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por orden alfabético"])},
        "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identificador"])},
        "most": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero los más visitados"])},
        "ema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primero los recomendados para mi"])},
        "orderSelect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona orden"])},
        "resultTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resultados en mi campus"])},
        "resultTitleAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Búsquedas relacionadas"])},
        "searching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscando..."])},
        "emptyResult1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La búsqueda de"])},
        "emptyResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no arrojó resultados"])},
        "foundResult1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se encontraron"])},
        "foundResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["coincidencias"])},
        "searchingFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buscando"])},
        "searchingForTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscando"])},
        "searchingUM1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["También puedes buscar"])},
        "searchingUM2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en el resto de la Universidad desde"])},
        "searchingUMLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["este enlace"])},
        "showmore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expandir"])},
        "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraer"])},
        "hide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocultar este carril"])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrar"])},
        "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordenar"])},
        "showall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar todos"])}
      }
    }
  })
}
