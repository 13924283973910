import { defineAsyncComponent } from 'vue';

const ConsultaCitasReservas = defineAsyncComponent(() => import(/* webpackChunkName: "curie-citareservas" */ './views/ConsultaCitasReservas.vue'));

const rutaProyecto = '/citasreservas';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'ConsultaCitasReservas',
    component: ConsultaCitasReservas,
    meta: {
      group: 'curie',
      application: 'citas-reservas',
      hiddenFeature: true,
      requiresAuth: true,
    },
  },
];
