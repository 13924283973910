import { defineAsyncComponent } from 'vue';

const ConsultaDeportes = defineAsyncComponent(() => import(/* webpackChunkName: "pci-ConsultaDeportes" */'./views/ConsultaDeportes.vue'));
const ConsultaCompeticiones = defineAsyncComponent(() => import(/* webpackChunkName: "pci-ConsultaCompeticiones" */'./views/ConsultaCompeticiones.vue'));
const MisReservasInstalaciones = defineAsyncComponent(() => import(/* webpackChunkName: "pci-MisReservas" */'./views/instalaciones/MisReservas.vue'));

export default [
  {
    path: '/consulta-deportes/',
    name: 'ConsultaDeportes',
    component: ConsultaDeportes,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'consulta-deportes',
      hiddenFeature: true,
    },
  },
  {
    path: '/consulta-deportes/competiciones',
    name: 'ConsultaDeportesCompeticiones',
    component: ConsultaCompeticiones,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'consulta-competiciones',
      hiddenFeature: true,
    },
  },
  {
    path: '/deportes/instalaciones/mis-reservas/',
    name: 'DEPORTES-MisReservas',
    component: MisReservasInstalaciones,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'deportes',
      hiddenFeature: true,
    },
  },
];
