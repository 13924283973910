import { defineAsyncComponent } from 'vue';

import transporteApi from './transporte.api';

const SolicitudBonoUniversitario = defineAsyncComponent(() => import(/* webpackChunkName: "pci-BonoUniversitario" */'./views/SolicitudBonoUniversitario.vue'));
const StepDatosPersonales = defineAsyncComponent(() => import(/* webpackChunkName: "pci-StepDatosPersonales" */'./components/bonoUniversitario/StepDatosPersonales.vue'));
const StepSeleccionTipoBono = defineAsyncComponent(() => import(/* webpackChunkName: "pci-StepSeleccionTipoBono" */'./components/bonoUniversitario/StepSeleccionTipoBono.vue'));
const StepCesionDatos = defineAsyncComponent(() => import(/* webpackChunkName: "pci-StepCesionDatos" */'./components/bonoUniversitario/StepCesionDatos.vue'));
const StepResumen = defineAsyncComponent(() => import(/* webpackChunkName: "pci-StepResumen" */'./components/bonoUniversitario/StepResumen.vue'));

const SelectorBono = defineAsyncComponent(() => import(/* webpackChunkName: "pci-SelectorBono" */'./views/SelectorBonoTransporte.vue'));

const rutaProyecto = '/transporte';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'Transporte',
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'transporte',
    },
    redirect: `${rutaProyecto}/bonos/`,
  },
  {
    path: `${rutaProyecto}/bonos/`,
    name: 'Bono de transporte publico',
    component: SelectorBono,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'bonos',
    },
  },
  {
    path: `${rutaProyecto}/bonos/:nombreCorto/solicitud`,
    name: 'SolicitudBonoUniversitario',
    component: SolicitudBonoUniversitario,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'transporte',
      verify: (to) => transporteApi.consultaEmpresasBonoUniversitario()
        .then((resultado) => resultado
          .some((record) => record.nombreCorto === to.params.nombreCorto)),
    },
    children: [
      { path: '/transporte/bonos/:nombreCorto/solicitud', component: StepDatosPersonales },
      { path: '/transporte/bonos/:nombreCorto/solicitud/tipo-bono', component: StepSeleccionTipoBono },
      { path: '/transporte/bonos/:nombreCorto/solicitud/cesion-datos', component: StepCesionDatos },
      { path: '/transporte/bonos/:nombreCorto/solicitud/resumen', component: StepResumen },
    ],
  },
];
