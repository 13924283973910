import { defineAsyncComponent } from 'vue';

const convocatoriaIncidenciasPortal = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasPortal" */ '@/app/acade/convocatoria-incidencias/views/tramite/Inicio.vue'));

// Tramite
const convocatoriaIncidenciasWizard = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasWizard" */ '@/app/acade/convocatoria-incidencias/views/tramite/Wizard.vue'));
const convocatoriaIncidenciasDatosPersonales = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasDatosPersonales" */ '@/app/acade/convocatoria-incidencias/views/tramite/DatosPersonales.vue'));
const convocatoriaIncidenciasAsignaturas = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasAsignaturas" */ '@/app/acade/convocatoria-incidencias/views/tramite/Asignaturas.vue'));
const convocatoriaIncidenciasResumen = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasResumen" */ '@/app/acade/convocatoria-incidencias/views/tramite/Resumen.vue'));
const convocatoriaIncidenciasFin = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasFin" */ '@/app/acade/convocatoria-incidencias/views/tramite/Fin.vue'));

// Subsanacion
const convocatoriaIncidenciasWizardSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasWizardSubsanacion" */ '@/app/acade/convocatoria-incidencias/views/subsanacion/WizardSubsanacion.vue'));
const convocatoriaIncidenciasInicioSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasInicioSubsanacion" */ '@/app/acade/convocatoria-incidencias/views/subsanacion/InicioSubsanacion.vue'));
const convocatoriaIncidenciasResumenSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasResumenSubsanacion" */ '@/app/acade/convocatoria-incidencias/views/subsanacion/ResumenSubsanacion.vue'));
const convocatoriaIncidenciasFinSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "convocatoriaIncidenciasFinSubsanacion" */ '@/app/acade/convocatoria-incidencias/views/subsanacion/FinSubsanacion.vue'));

export default [
  {
    path: '/convocatoriaincidencias/inicio',
    name: 'InicioConv',
    component: convocatoriaIncidenciasPortal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/convocatoriaincidencias/solicitud/',
    name: 'WizardConv',
    component: convocatoriaIncidenciasWizard,
    meta: {
      requiresAuth: true,
    },
    redirect: 'datospersonales',
    children: [
      {
        path: 'datospersonales',
        component: convocatoriaIncidenciasDatosPersonales,
      },
      {
        path: 'asignaturas',
        component: convocatoriaIncidenciasAsignaturas,
      },
      {
        path: 'resumen',
        component: convocatoriaIncidenciasResumen,
      },
      {
        path: 'fin',
        component: convocatoriaIncidenciasFin,
      },
    ],
  },
  {
    path: '/convocatoriaincidencias/subsanacion/',
    name: 'WizardSubsanacionConv',
    component: convocatoriaIncidenciasWizardSubsanacion,
    meta: {
      requiresAuth: true,
    },
    redirect: 'inicio',
    children: [
      {
        path: 'inicio',
        component: convocatoriaIncidenciasInicioSubsanacion,
      },
      {
        path: 'resumen',
        component: convocatoriaIncidenciasResumenSubsanacion,
      },
      {
        path: 'fin',
        component: convocatoriaIncidenciasFinSubsanacion,
      },
    ],
  },
];
