export default {
  namespaced: true,
  // ESTADO
  state: {
    reciboSeleccionado: null,
    reciboDevolver: null,
    idTransaccion: null,
    idRecibo: null,
    idEstadoFirma: null,
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    asignarRecibo(state, valor) {
      state.reciboSeleccionado = valor;
    },
    setReciboDevolver(state, valor) {
      state.reciboDevolver = valor;
    },
    clearReciboDevolver(state) {
      state.reciboDevolver = null;
    },
    setIdTransaccion(state, valor) {
      state.idTransaccion = valor;
    },
    clearIdTransaccion(state) {
      state.idTransaccion = null;
    },
    setIdRecibo(state, valor) {
      state.idRecibo = valor;
    },
    clearIdRecibo(state) {
      state.idRecibo = null;
    },
    setIdEstadoFirma(state, valor) {
      state.idEstadoFirma = valor;
    },
    clearIdEstadoFirma(state) {
      state.idEstadoFirma = null;
    },
    clearAll(state) {
      state.reciboDevolver = null;
      state.idTransaccion = null;
      state.idRecibo = null;
      state.idEstadoFirma = null;
    },
  },
};
