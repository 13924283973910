export default {
  ORIGEN_UMU: 1,
  ORIGEN_UNIVERSIDAD_ESPAÑOLA: 2,
  ORIGEN_UNIVERSIDAD_EXTRANJERA: 3,
  ORIGEN_ESTUDIOS_PROPIOS_UM: 4,
  ORIGEN_ESTUDIOS_PROPIOS_NO_UM: 5,
  ORIGEN_FP: 6,
  ORIGEN_ENSEÑANZAS_SUPERIORES: 7,
  ORIGEN_EXPERIENCIA_LABORAL: 8,
  ORIGEN_TRANSFERENCIA_CREDITOS: 9,
};
