import { defineAsyncComponent } from 'vue';

const cambioGrupo = defineAsyncComponent(() => import(/* webpackChunkName: "cambioGrupo" */ '@/app/acade/cambio-grupo/views/CambioGrupo.vue'));

export default [
  {
    path: '/cambio-grupo',
    name: 'cambioGrupo',
    component: cambioGrupo,
    meta: {
      title: 'cambioGrupo',
      requiresAuth: true,
      group: 'acade',
      application: 'cambio-grupo',
    },
  },
];
