export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "esenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo usamos cookies esenciales"])},
        "textoesenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación web únicamente utiliza cookies técnicas propias con la finalidad de permitir la navegación por el sitio web, de manera que no se recabarán ni cederán datos de carácter personal de los usuarios sin su conocimiento. Para mas información puede ver nuestra"])},
        "linkesenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de cookies"])},
        "esencialesterceros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación usa cookies esenciales y de terceros"])},
        "linkesencialesterceros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de cookies"])},
        "buttonesenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo esenciales"])},
        "buttonaccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De acuerdo"])},
        "textoesencialesterceros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación web utiliza cookies técnicas propias y de terceros con la finalidad de permitir la navegación por el sitio web, de manera que no se recabarán ni cederán datos de carácter personal de los usuarios sin su conocimiento. Para mas información puede ver nuestra"])}
      },
      "es": {
        "esenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo usamos cookies esenciales"])},
        "textoesenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación web únicamente utiliza cookies técnicas propias con la finalidad de permitir la navegación por el sitio web, de manera que no se recabarán ni cederán datos de carácter personal de los usuarios sin su conocimiento. Para mas información puede ver nuestra"])},
        "linkesenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de cookies"])},
        "esencialesterceros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación usa cookies esenciales y de terceros"])},
        "linkesencialesterceros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["política de cookies"])},
        "buttonesenciales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sólo esenciales"])},
        "buttonaccept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De acuerdo"])},
        "textoesencialesterceros": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta aplicación web utiliza cookies técnicas propias y de terceros con la finalidad de permitir la navegación por el sitio web, de manera que no se recabarán ni cederán datos de carácter personal de los usuarios sin su conocimiento. Para mas información puede ver nuestra"])}
      }
    }
  })
}
