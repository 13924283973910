import { defineAsyncComponent } from 'vue';

const ConsultaIntercambio = defineAsyncComponent(() => import(/* webpackChunkName: "campusvirtual-consultaIntercambio */ './views/ConsultaIntercambio.vue'));
const Listados = defineAsyncComponent(() => import(/* webpackChunkName: "campusvirtual-Listados */ './views/Listados.vue'));
const NuevaSolicitud = () => import(/* webpackChunkName: "campusvirtual-nuevaSolicitud */ './views/NuevaSolicitud.vue');
const Baremo = () => import(/* webpackChunkName: "campusvirtual-baremo */ './views/Baremo.vue');
const Normas = () => import(/* webpackChunkName: "campusvirtual-normas */ './views/Normas.vue');

const rutaProyecto = '/intercambiovacaciones';

export default [
  {
    path: `${rutaProyecto}`,
    name: 'ConsultaIntercambio',
    component: ConsultaIntercambio,
    meta: {
      group: 'campusvirtual',
      application: 'intercambio-vacaciones',
      hiddenFeature: true,
      requiresAuth: true,
    },
  },
  {
    path: `${rutaProyecto}/nueva-solicitud`,
    name: 'NuevaSolicitud',
    component: NuevaSolicitud,
    meta: {
      group: 'campusvirtual',
      application: 'intercambio-vacaciones',
      hiddenFeature: true,
      requiresAuth: true,
    },
  },
  {
    path: `${rutaProyecto}/listados`,
    name: 'Listados',
    component: Listados,
    meta: {
      group: 'campusvirtual',
      application: 'intercambio-vacaciones',
      hiddenFeature: true,
      requiresAuth: true,
    },
  },
  {
    path: `${rutaProyecto}/baremo`,
    name: 'Baremo',
    component: Baremo,
    meta: {
      group: 'campusvirtual',
      application: 'intercambio-vacaciones',
      hiddenFeature: true,
    },
  },
  {
    path: `${rutaProyecto}/normas`,
    name: 'Normas',
    component: Normas,
    meta: {
      group: 'campusvirtual',
      application: 'intercambio-vacaciones',
      hiddenFeature: true,
    },
  },
];
