const Suma = () => import(/* webpackChunkName: "old-Suma" */ './views/Suma.vue');

export default [
  {
    path: '/web/suma/:url?/:suburl?',
    props: true,
    name: 'Suma',
    component: Suma,
    meta: {
      requiresAuth: true,
      group: 'campus-virtual',
      application: 'suma',
    },
  },
];
