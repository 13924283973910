const origenes = {
  ORIGEN_UMU: {
    id: 1,
    descripcion: 'Universidad de Murcia',
  },
  ORIGEN_UNIVERSIDAD_ESPAÑOLA: {
    id: 2,
    descripcion: 'Universidad española',
  },
  ORIGEN_UNIVERSIDAD_EXTRANJERA: {
    id: 3,
    descripcion: 'Universidad extranjera',
  },
};

export { origenes as default };
