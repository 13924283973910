const dateMixin = {
  methods: {
    toDateFormat(nonIsoDate) {
      try {
        return nonIsoDate.substring(0, 10).split(' ')[0]
          .split('/')
          .reverse()
          .join('-');
      } catch (err) {
        return '1970-01-01';
      }
    },
    timeFormat(nonIsoDate) {
      try {
        return nonIsoDate.split(' ')[1]
          .slice(0, 5);
      } catch (err) {
        return '00:00';
      }
    },
    fromDateToISO(date) {
      return new Date(date).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse()
        .join('-');
    },
    isToday(someDate) {
      const today = new Date();
      return someDate.getDate() === today.getDate()
        && someDate.getMonth() === today.getMonth()
        && someDate.getFullYear() === today.getFullYear();
    },
    fromDateToEU(value) {
      return new Date(value).toLocaleDateString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
    },
    fromDateToEUMonthDay(value) {
      return new Date(value).toLocaleDateString('es-ES', {
        month: 'short',
        day: '2-digit',
      });
    },
    fromDateToEULongMonthDay(value) {
      return new Date(value).toLocaleDateString('es-ES', {
        month: 'long',
        day: '2-digit',
      });
    },
  },

};

export { dateMixin as default };
