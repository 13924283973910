export default {
  CERTIFICACION_ACADEMICA_OFICIAL: 1,
  PLAN_ESTUDIOS: 2,
  GUIA_DOCENTE: 3,
  TITULO: 5,
  VIDA_LABORAL: 6,
  MEMORIA_EMPRESA: 7,
  DOCUMENTO_OFICIAL_ACREDITACION_UNIVERSIDAD_ORIGEN: 8,
  EQUIVALENCIA_NOTAS: 9,
  DOCUMENTO_TRADUCCION: 10,
  CONTRATO_TRABAJO: 11,
};
