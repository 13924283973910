import { percentageIsGreater } from '@/app/shared/utils/utils';

const defaultModal = {
  name: '',
  dismissed: 0,
  viewPercentage: 100,
  hoursOff: null,
  priority: Number.MAX_SAFE_INTEGER,
};

const canShow = (modal, tSeconds) => {
  if (modal && modal.hoursOff) {
    return tSeconds - modal.dismissed > modal.hoursOff * 3600;
  }
  return !modal || !modal.dismissed;
};

const isProbablyToShow = (modal) => {
  if (modal) {
    return percentageIsGreater(modal.viewPercentage);
  }
  return !!modal;
};

const nowInSeconds = () => Math.round(new Date().getTime() / 1000);

const isShowingNonPriorityModal = (showingModal, modal) => showingModal && modal
 && showingModal.priority < modal.priority;

const existPriorityModal = (modals, modal, time = nowInSeconds()) => {
  const priority = modal.priority ? modal.priority : Number.MAX_SAFE_INTEGER;
  return !!modals.find((m) => m.priority < priority && canShow(m, time));
};

const dismissModal = (modals, modal) => {
  if (modal && modals.find((m) => m.name === modal.name)) {
    const replacedModal = {
      ...modals.find((m) => m.name === modal.name),
      ...{ dismissed: nowInSeconds() },
    };
    return [...modals.filter((m) => m.name !== modal.name), replacedModal];
  }
  return modals;
};

export default {
  namespaced: true,
  state: {
    alreadyShowedModal: null,
    registeredModals: [],
  },
  mutations: {
    REGISTER_MODAL(state, modal) {
      const indexModal = state.registeredModals.findIndex((el) => el.name === modal.name);
      if (indexModal >= 0) {
        const previousModal = state.registeredModals[indexModal];
        state.registeredModals[indexModal] = { ...previousModal, ...modal };
      } else {
        state.registeredModals.push({ ...defaultModal, ...modal });
      }
    },
    SHOW_MODAL(state, modal) {
      // Si puede visualizarse, actualizamos el que se muestra ahora mismo
      if (
        !state.alreadyShowedModal
        || !existPriorityModal(state.registeredModals, modal)
      ) {
        state.alreadyShowedModal = modal;
      }
    },
    CLOSE_MODAL(state, modal) {
      if (
        state.alreadyShowedModal
        && state.alreadyShowedModal.name === modal.name
      ) {
        state.alreadyShowedModal = null;
      }
    },
    DISMISS_MODAL(state, modal) {
      state.registeredModals = dismissModal(state.registeredModals, modal);
    },
    CLOSE_CURRENT_MODAL(state) {
      // Si existe, actualizamos el valor dismissed del elemento
      state.registeredModals = dismissModal(
        state.registeredModals,
        state.alreadyShowedModal,
      );
      state.alreadyShowedModal = null;
    },
  },
  getters: {
    couldShow: (state) => (modal) => {
      const nowTs = nowInSeconds();
      const foundModal = state.registeredModals.find(
        (m) => m.name === modal.name,
      );
      return (
        isProbablyToShow(foundModal)
        && canShow(foundModal, nowTs)
        && !existPriorityModal(state.registeredModals, foundModal, nowTs)
        && (!state.alreadyShowedModal
          || isShowingNonPriorityModal(state.alreadyShowedModal, foundModal))
      );
    },
    isShowing: (state) => (modal) => state.alreadyShowedModal
      && modal
      && state.alreadyShowedModal.name === modal.name,
    isShowingAnother: (state) => (modal) => state.alreadyShowedModal
      && modal
      && state.alreadyShowedModal.name !== modal.name,
  },
};
