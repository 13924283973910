export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become user"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a user ID and click on \"Become a user\" to access as that user"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User email"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become a user"])},
        "forbidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action required"])},
        "forbidden_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["To access this service it is required a 2-step authentication. In order to do it, you are being automatically ", _interpolate(_named("genderText")), " to the identification gateway."])},
        "forbidden_title_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identification gateway."])},
        "female_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirected"])},
        "male_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirected"])},
        "mix_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["redirected"])},
        "error423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al tomar sus datos de la plataforma de autenticación. Pruebe pasado un tiempo e intente borrar la caché de su navegador."])}
      },
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplantar usuario"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Introduzca un ID de usuario y haga clic en \"Convertirse en usuario\" para acceder como ese usuario"])},
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correo electrónico del usuario"])},
        "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Convertirse en usuario"])},
        "forbidden_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acción requerida"])},
        "forbidden_message": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Para acceder a este servicio es necesaria una autenticación en 2 pasos por lo que vas a ser ", _interpolate(_named("genderText")), " de forma automática a la pasarela de identificación"])},
        "forbidden_title_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pasarela de identificación"])},
        "female_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dirigida"])},
        "male_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dirigido"])},
        "mix_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dirigido/a"])},
        "error423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha producido un error al tomar sus datos de la plataforma de autenticación. Pruebe pasado un tiempo e intente borrar la caché de su navegador."])}
      }
    }
  })
}
