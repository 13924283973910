import { defineAsyncComponent } from 'vue';

// Tramite Baja Matricula

const bajaMatriculaPortal = defineAsyncComponent(() => import(/* webpackChunkName: "bajaMatriculaPortal" */ '@/app/acade/baja-matricula/views/Portal.vue'));

const bajaMatriculaInicio = defineAsyncComponent(() => import(/* webpackChunkName: "bajaMatriculaFormularioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/Inicio.vue'));
const bajaMatriculaFormularioSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaMatriculaFormularioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/FormularioDinamico.vue'));
const bajaMatriculaInicioSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaMatriculaInicioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/InicioGenerico.vue'));
const bajaMatriculaResumenSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaMatriculaResumenSolicitud" */ '@/app/acade/shared/formularioDinamico/views/ResumenGenerico.vue'));
const bajaMatriculaFinSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaMatriculaFinSolicitud" */ '@/app/acade/shared/formularioDinamico/views/FinGenerico.vue'));

export default [
  {
    path: '/bajamatricula',
    name: 'bajaMatriculaInicio',
    component: bajaMatriculaInicio,
    meta: {
      title: 'bajamatricula',
      requiresAuth: true,
    },
  },
  {
    path: '/bajamatricula/solicitud/portal',
    name: 'bajaMatriculaPortal',
    component: bajaMatriculaPortal,
  },
  {
    path: '/bajamatricula',
    name: 'bajaMatriculaFormularioSolicitud',
    component: bajaMatriculaFormularioSolicitud,
    meta: {
      title: 'bajamatricula',
      requiresAuth: true,
    },
    redirect: '/bajamatricula/solicitud/inicio',
    children: [
      {
        path: '/bajamatricula/solicitud/inicio',
        component: bajaMatriculaInicioSolicitud,
      },
      {
        path: '/bajamatricula/solicitud/resumen',
        component: bajaMatriculaResumenSolicitud,
      },
      {
        path: '/bajamatricula/solicitud/fin',
        component: bajaMatriculaFinSolicitud,
      },
    ],
  },
];
