<template>
  <div class="completeList searchResults">
    <section class="cardGroupList__item">
      <h1 class="cardGroup__title">
        {{ $t('resultTitle') }}
      </h1>
      <p v-if="searching">
        {{ $t('searching') }}
      </p>
      <p
        v-else
        class="cardGroup__search"
      >
        <HeaderSearch
          :has-bg="false"
          :input-data="searchString"
          class="search__inputSearch"
        />
      </p>
      <fwjs-message
        v-if="searchItems.length === 0 && !searching"
        :category="errorMsg.categoryMsg"
      >
        {{ $t('emptyResult1') }} <strong>{{ fillErrorMsg }}</strong> {{ $t('emptyResult2') }}
      </fwjs-message>
      <div v-else-if="!searching">
        <span class="searchResults__message">
          {{ $t('foundResult1') }} {{ searchItems.length }} {{ $t('foundResult2') }}
        </span>
        {{ $t('searchingFor') }} <strong>{{ searchString }}</strong>
        <CardGroupOpened
          :service-list="searchItems"
          :show-description="false"
          :show-button="false"
        />
      </div>
      <SearchFeedback v-if="!searching" />
      <div v-if="!searching">
        <h1 class="cardGroup__title">
          {{ $t('resultTitleAlt') }}
        </h1>
        <span class="searchResults__message">
          {{ $t('searchingUM1') }}
          <strong>{{ searchString }}</strong>
          {{ $t('searchingUM2') }}
        </span>
        &nbsp;
        <a
          :href="`https://www.um.es/web/universidad/buscador?q=${encodeURI(searchString)}`"
          target="_blank"
          rel="noopener"
        >{{ $t('searchingUMLink') }}</a>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import fwjsMessage from '@/app/shared/components/fwjsMessage.vue';

import servicesApi from '../services.api';
import CardGroupOpened from '../components/CardGroupOpened.vue';
import HeaderSearch from '../components/HeaderSearch.vue';
import SearchFeedback from '../components/SearchFeedback.vue';

export default defineComponent({
  name: 'Search',
  props: {},
  components: {
    CardGroupOpened,
    HeaderSearch,
    fwjsMessage,
    SearchFeedback,
  },
  data() {
    return {
      searchString: '',
      searchItems: [],
      searching: false,
      errorMsg: {
        categoryMsg: 'info',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
    fillErrorMsg() {
      const response = this.searchString;
      return response;
    },
  },
  watch: {
    '$route.params': {
      handler(newValue) {
        this.searching = true;
        this.searchString = (newValue && newValue.tag) ? newValue.tag : null;
        if (this.searchString) {
          this.$store.commit('pages/SET_TITLE', `${this.$t('searchingForTitle')}: ${newValue.tag}`);
          servicesApi.search({
            tag: newValue.tag,
            logged: this.isLogged,
          }).then((r) => r.data)
            .then((searchData) => {
              this.searching = false;
              this.searchItems = searchData;
              const idSearchItems = this.searchItems.map((el) => ({
                identifier: el.identifier,
                searchId: el.searchId,
              }));
              this.$store.commit('services/SEARCHED_ITEMS', idSearchItems);
            }).catch(
              () => {
                this.searching = false;
                this.$store.commit('services/SEARCHED_ITEMS', []);
              },
            );
        }
      },
      immediate: true,
      deep: true,
    },
  },
});
</script>

<style lang="scss" scoped>

  .searchResults {
    min-height: 100vh;

    &__message {
      color: var(--dark-grey-300);
      font-size: 1rem;
    }
  }
  .completeList {
    max-width: var(--grid-width);
    margin: 1rem auto;
    padding: 2rem 1rem 0 1rem;
    &__container {
      display: flex;
      flex-direction: column;
      list-style: none;
    }
    &__sliders {
      list-style: none;
      padding: 0 0 0 0;
      margin: 0 0 0 0;
      display: flex;
      flex-direction: column;
    }
  }
  .search__inputSearch {
    border: 1px solid #D0D0D0 !important;
    border-radius: 3em;
    input {
      width: 100% !important;
      max-width: 100% !important;
    }
  }
  .cardGroup__title {
    border-bottom: 1px solid var(--border-color);
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 1rem;
    margin: 1rem 0 2rem 0;
    padding: 0 0 0.5rem 0;
  }
  .cardGroupList__item {
    min-height: 100vh;
  }
  .openedCarousel {
    margin: 0 0 5rem 0;
  }
  .cardGroupList {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    position: relative;
    &__item {
      max-width: var(--grid-width);
      overflow: hidden;
    }
  }
</style>

<i18n src="../locales/i18nSliders.json"></i18n>
