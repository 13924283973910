const isSafari = (
  navigator.userAgent.indexOf('Safari') !== -1
  && navigator.userAgent.indexOf('Chrome') === -1
);

export default (url) => {
  if (isSafari) {
    setTimeout(() => {
      window.location = url;
    }, 350);
  } else {
    window.location = url;
  }
};
