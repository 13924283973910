import { defineAsyncComponent } from 'vue';

const DatosKron = defineAsyncComponent(() => import(/* webpackChunkName: "KRON_Datos" */'./views/DatosKron.vue'));

const rutaProyecto = '/profile/gestion-horaria';

export default [
  {
    path: `${rutaProyecto}`,
    name: 'KRON Datos',
    component: DatosKron,
    meta: {
      requiresAuth: true,
      group: 'gente',
      application: 'kron',
    },
  },

];
