import {
  apiRequest,
} from '@/api';

const moduleBaseApiURL = '/eadmin/mis-tramites-api/';
const apiVersion = 'v1.0';
const aplicacionCarpeta = 'CARPETA';

const endpoints = {
  consultaTramites: `${moduleBaseApiURL}private/${apiVersion}/tramites`,
  detalleTramite: `${moduleBaseApiURL}private/${apiVersion}/tramites/{idAplicacion}/{idTramite}`,
  detalleNotificacion: `${moduleBaseApiURL}private/${apiVersion}/tramites/{idAplicacion}/{idTramite}/notificaciones/{idNotificacion}`,
  detalleDocumento: `${moduleBaseApiURL}private/${apiVersion}/tramites/{idAplicacion}/{idTramite}/documentos/{idDocumento}`,
  consultaTiposTramite: `${moduleBaseApiURL}private/${apiVersion}/tipos`,
  consultaEstadosTramitacion: `${moduleBaseApiURL}private/${apiVersion}/estados`,
  consultaUrlAccesoHistorico: `${moduleBaseApiURL}private/${apiVersion}/tramites/historico/url`,
  consultaUrlCatalogoRegistro: `${moduleBaseApiURL}private/${apiVersion}/tipos/catalogo/url`,
  consultaTramitesSolicitables: `${moduleBaseApiURL}private/${apiVersion}/tipos-solicitables`,
  consultaTramitesSugeridos: `${moduleBaseApiURL}private/${apiVersion}/tipos-solicitables/sugerencias`,
  consultaUnidades: `${moduleBaseApiURL}private/${apiVersion}/unidades-organizativas/solicitables`,
};

function trataParam(param) {
  if (!param || param.length === 0) {
    return '';
  }
  return param;
}

function trataParamFecha(fecha, finDia) {
  if (!fecha || fecha.length === 0) {
    return '';
  }
  let f;

  if (finDia) {
    f = new Date(fecha);
    f.setHours(23, 59, 59, 999);
  } else {
    f = new Date(fecha);
  }

  return f.toISOString().substring(0, 19);
}

function getAplicacionBackend(idAplicacion) {
  let aplicacion;

  if (idAplicacion) {
    aplicacion = idAplicacion;
  } else {
    aplicacion = aplicacionCarpeta;
  }

  return aplicacion;
}

const misTramitesApi = {
  endpoints: () => endpoints,
  urlAccesoHistorico: () => {
    const url = `${endpoints.consultaUrlAccesoHistorico}`;
    return apiRequest({ url });
  },
  tiposTramite: () => {
    const url = `${endpoints.consultaTiposTramite}`;
    return apiRequest({ url });
  },
  estadosTramitacion: () => {
    const url = `${endpoints.consultaEstadosTramitacion}`;
    return apiRequest({ url });
  },
  consultaTramites: (filtro, pagina, tamanyo) => {
    const url = `${endpoints.consultaTramites}?titulo=${trataParam(filtro.titulo)}&fechaInicioDesde=${trataParamFecha(filtro.fecIniDesde)}&fechaInicioHasta=${trataParamFecha(filtro.fecIniHasta, true)}&fechaActualizacionDesde=${trataParamFecha(filtro.fecActDesde)}&fechaActualizacionHasta=${trataParamFecha(filtro.fecActHasta, true)}&estadoTramitacion=${trataParam(filtro.estadoTramitacion)}&codigoTramite=${trataParam(filtro.tipoTramite)}&recuperarCerrados=true&pagina=${pagina}&tamanyo=${tamanyo}`;
    return apiRequest({ url });
  },
  consultaBasicaTramites: (filtro, pagina, tamanyo) => {
    const url = `${endpoints.consultaTramites}?titulo=${trataParam(filtro.titulo)}&recuperarCerrados=true&pagina=${pagina}&tamanyo=${tamanyo}`;
    return apiRequest({ url });
  },
  detalleTramite: (idTramite, idAplicacion) => {
    let aplicacion;

    if (idAplicacion) {
      aplicacion = idAplicacion;
    } else {
      aplicacion = aplicacionCarpeta;
    }

    const url = `${endpoints.detalleTramite.replace('{idAplicacion}', aplicacion).replace('{idTramite}', idTramite)}`;
    return apiRequest({ url });
  },
  detalleDocumento: (idTramite, idAplicacion, idDocumento) => {
    const url = `${endpoints.detalleDocumento.replace('{idAplicacion}', getAplicacionBackend(idAplicacion)).replace('{idTramite}', idTramite).replace('{idDocumento}', idDocumento)}`;
    return apiRequest({ url });
  },
  detalleNotificacion: (idTramite, idAplicacion, idNotificacion) => {
    const url = `${endpoints.detalleNotificacion.replace('{idAplicacion}', getAplicacionBackend(idAplicacion)).replace('{idTramite}', idTramite).replace('{idNotificacion}', idNotificacion)}`;
    return apiRequest({ url });
  },
  urlCatalogoRegistro: () => {
    const url = `${endpoints.consultaUrlCatalogoRegistro}`;
    return apiRequest({ url });
  },
  consultaTramitesSolicitables: (filtro, page, size, sort) => {
    const url = `${endpoints.consultaTramitesSolicitables}?descripcion=${trataParam(filtro.nombre)}&unidadResponsable=${trataParam(filtro.udResponsable)}&page=${page}&size=${size}&sort=${sort}`;
    return apiRequest({ url });
  },
  consultaUnidades: () => {
    const url = `${endpoints.consultaUnidades}`;
    return apiRequest({ url });
  },
  consultaTramitesSugeridos: () => {
    const url = `${endpoints.consultaTramitesSugeridos}`;
    return apiRequest({ url });
  },
};

export default misTramitesApi;
