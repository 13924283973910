export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "declaracionResponsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración responsable"])},
        "mensajeDeclaracionResponsable1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Para seguir con el proceso de solicitud, deberá aceptar la declaración responsable:</b><br /><br />"])},
        "mensajeDeclaracionResponsable2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que no tiene expediente académico en otra universidad española por haber obtenido convalidación parcial de estudios extranjeros<br /><br />"])},
        "mensajeDeclaracionResponsable3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["SOLICITA, la admisión en estudios en ", _interpolate(_list(0)), ". <br /><br />DECLARA, que son ciertos los datos consignados en la solicitud, reuniendo las condiciones exigidas para dicho reconocimiento, comprometiéndose a probar los datos que figuran en esta solicitud que le fueran requeridos, asumiendo en caso contrario, las responsabilidades que pudieran derivarse de su inexactitud. Confirmo la declaración responsable"])},
        "mensajeDeclaracionResponsable4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["SOLICITA, el reconocimiento de créditos en los estudios de ", _interpolate(_list(0)), ". <br /><br />DECLARA, que son ciertos los datos consignados en la solicitud, reuniendo las condiciones exigidas para dicho reconocimiento, comprometiéndose a probar los datos que figuran en esta solicitud que le fueran requeridos, asumiendo en caso contrario, las responsabilidades que pudieran derivarse de su inexactitud. Confirmo la declaración responsable"])},
        "requiredDeclaracionResponsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar con el proceso de solicitud, debes aceptar la declaración responsable"])},
        "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
      },
      "en": {
        "declaracionResponsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Declaración responsable"])},
        "mensajeDeclaracionResponsable1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Para seguir con el proceso de solicitud, deberá aceptar la declaración responsable:</b><br /><br />"])},
        "mensajeDeclaracionResponsable2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que no tiene expediente académico en otra universidad española por haber obtenido convalidación parcial de estudios extranjeros<br /><br />"])},
        "mensajeDeclaracionResponsable3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["SOLICITA, la admisión en estudios en ", _interpolate(_list(0)), ". <br /><br />DECLARA, que son ciertos los datos consignados en la solicitud, reuniendo las condiciones exigidas para dicho reconocimiento, comprometiéndose a probar los datos que figuran en esta solicitud que le fueran requeridos, asumiendo en caso contrario, las responsabilidades que pudieran derivarse de su inexactitud. Confirmo la declaración responsable"])},
        "mensajeDeclaracionResponsable4": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["SOLICITA, el reconocimiento de créditos en los estudios de ", _interpolate(_list(0)), ". <br /><br />DECLARA, que son ciertos los datos consignados en la solicitud, reuniendo las condiciones exigidas para dicho reconocimiento, comprometiéndose a probar los datos que figuran en esta solicitud que le fueran requeridos, asumiendo en caso contrario, las responsabilidades que pudieran derivarse de su inexactitud. Confirmo la declaración responsable"])},
        "requiredDeclaracionResponsable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para continuar con el proceso de solicitud, debes aceptar la declaración responsable"])},
        "cancelar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancelar"])},
        "aceptar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceptar"])}
      }
    }
  })
}
