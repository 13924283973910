const Pci = () => import(/* webpackChunkName: "pci" */'./views/PciView.vue');

export default [
  {
    path: '/pci/:slug',
    props: true,
    name: 'Pci',
    component: Pci,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'portal-servicios',
    },
  },
];
