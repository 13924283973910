export default {
  namespaced: true,
  state: {
    cursoInicio: null,
    cursoFin: null,
    idCertificado: null,
  },
  mutations: {
    setCursoInicio(state, valor) {
      state.cursoInicio = valor;
    },
    clearCursoInicio(state) {
      state.cursoInicio = null;
    },
    setCursoFin(state, valor) {
      state.cursoFin = valor;
    },
    clearCursoFin(state) {
      state.cursoFin = null;
    },
    setIdCertificado(state, valor) {
      state.idCertificado = valor;
    },
    clearIdCertificado(state) {
      state.idCertificado = null;
    },
    clearAll(state) {
      state.cursoInicio = null;
      state.cursoFin = null;
      state.idCertificado = null;
    },
  },
};
