export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "Not Found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Not found"])},
        "Unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unauthorized"])}
      },
      "es": {
        "Not Found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruta no encontrada"])},
        "Unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No tiene acceso para solicitar este servicio"])}
      }
    }
  })
}
