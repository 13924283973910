export default {
  namespaced: true,
  state: {
    formData: null,
  },
  mutations: {
    setFormData(state, valor) {
      state.formData = valor;
    },
  },
};
