import { defineAsyncComponent } from 'vue';

const mantenimientoPreciosPublicosPortal = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosPortal" */ '@/app/acade/mantenimiento-precios-publicos/views/Portal.vue'));

// Tramite Mantenimiento Precios Públicos
const mantenimientoPreciosPublicosFormularioSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosFormularioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/FormularioDinamico.vue'));
const mantenimientoPreciosPublicosInicioSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosInicioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/InicioGenerico.vue'));
const mantenimientoPreciosPublicosResumenSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosResumenSolicitud" */ '@/app/acade/shared/formularioDinamico/views/ResumenGenerico.vue'));
const mantenimientoPreciosPublicosFinSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosFinSolicitud" */ '@/app/acade/shared/formularioDinamico/views/FinGenerico.vue'));

// Subsanación Mantenimiento Precios Públicos
const mantenimientoPreciosPublicosWizardSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosWizardSubsanacion" */ '@/app/acade/mantenimiento-precios-publicos/views/subsanacion/Wizard.vue'));
const mantenimientoPreciosPublicosInicioSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosInicioSubsanacion" */ '@/app/acade/mantenimiento-precios-publicos/views/subsanacion/Inicio.vue'));
const mantenimientoPreciosPublicosResumenSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosResumenSubsanacion" */ '@/app/acade/mantenimiento-precios-publicos/views/subsanacion/Resumen.vue'));
const mantenimientoPreciosPublicosFinSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "mantenimientoPreciosPublicosFinSubsanacion" */ '@/app/acade/mantenimiento-precios-publicos/views/subsanacion/Fin.vue'));

export default [
  {
    path: '/mantenimientopreciospublicos',
    name: 'PortalMantenimientoPreciosPublicos',
    component: mantenimientoPreciosPublicosPortal,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/mantenimientopreciospublicos/solicitud/',
    name: 'FormularioSolicitudMantenimientoPreciosPublicos',
    component: mantenimientoPreciosPublicosFormularioSolicitud,
    meta: {
      title: 'mantenimientopreciospublicos',
      requiresAuth: true,
    },
    redirect: '/mantenimientopreciospublicos/solicitud/inicio',
    children: [
      {
        path: '/mantenimientopreciospublicos/solicitud/inicio',
        component: mantenimientoPreciosPublicosInicioSolicitud,
      },
      {
        path: '/mantenimientopreciospublicos/solicitud/resumen',
        component: mantenimientoPreciosPublicosResumenSolicitud,
      },
      {
        path: '/mantenimientopreciospublicos/solicitud/fin',
        component: mantenimientoPreciosPublicosFinSolicitud,
      },
    ],
  },
  {
    path: '/mantenimientopreciospublicos/subsanacion',
    name: 'WizardSubsanacionMantenimentoPreciosPublicos',
    component: mantenimientoPreciosPublicosWizardSubsanacion,
    props: true,
    meta: {
      requiresAuth: true,
    },
    redirect: '/mantenimientopreciospublicos/subsanacion/inicio',
    children: [
      {
        name: 'InicioSubsanacionMantenimientoPreciosPublicos',
        path: '/mantenimientopreciospublicos/subsanacion/inicio',
        component: mantenimientoPreciosPublicosInicioSubsanacion,
        props: true,
      },
      {
        name: 'ResumenSubsanacionMantenimientoPreciosPublicos',
        path: '/mantenimientopreciospublicos/subsanacion/resumen',
        component: mantenimientoPreciosPublicosResumenSubsanacion,
      },
      {
        name: 'FinSubsanacionMantenimientoPreciosPublicos',
        path: '/mantenimientopreciospublicos/subsanacion/fin',
        component: mantenimientoPreciosPublicosFinSubsanacion,
      },
    ],
  },
];
