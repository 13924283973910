export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "appname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My campus"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My campus"])}
      },
      "es": {
        "appname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi campus"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi campus"])}
      }
    }
  })
}
