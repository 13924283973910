<template>
  <transition name="sliderfade">
    <div
      v-show="mustShow"
      class="cardGroupList"
      :class="className"
    >
      <section
        class="cardGroupList__item"
        v-if="!isEmpty() && mustShow"
      >
        <h1
          class="cardGroup__title"
          :title="$t(`${this.pSliderKey}.title`)"
        >
          {{ $t(`${this.pSliderKey}.title`) }}
        </h1>
        <card-group
          :services="pServices"
          :slider-key="pSliderKey"
          :view-type="pViewType"
          :tidy="pTidy"
          :small-cards="pSmallCards || null"
          :options="optionsAdded"
          @pageActiveChange="getPage"
        />
      </section>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import CardGroup from './CardGroup.vue';

export default defineComponent({
  name: 'SliderComponent',
  components: {
    CardGroup,
  },
  props: {
    pOrder: { type: Number, required: true, default: 0 },
    pViewType: { type: String, required: true },
    pVisible: { type: Boolean, required: true, default: true },
    pPrivate: { type: Boolean, required: false, default: true },
    pServices: { type: Array, required: false, default: () => [] },
    pSliderKey: { type: String, required: true },
    pTidy: { type: Boolean, required: false, default: false },
    pParam: { type: String, required: false, default: '' },
    pOptions: { type: Array, required: false, default: () => [] },
    pRoles: { type: Array, required: false, default: () => [] },
    pSmallCards: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      sliderKey: this.pSliderKey,
      sliderAction: 'setCardList',
      sliderParam: this.pParam,
      options: this.pOptions,
      pageText: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
    ...mapGetters('user', ['hasRoles', 'isNewUser']),
    ...mapState('auth', ['token']),
    ...mapState('services', ['areas']),
    mustShow() {
      // POSE-793 - El carril de novedades no sale a los nuevos usuarios
      if (this.isNewUser && this.sliderKey === 'NEW_SERVICES') {
        return false;
      }
      // POSE-859 - Desactivamos los carriles por rol hasta que se definan.
      const isForMe = true;
      return this.isLogged ? this.pVisible && isForMe
        : (!this.pPrivate && this.pVisible);
    },
    className() {
      return this.sliderKey.toLowerCase().replace(/_/ig, '-');
    },
    optionsAdded() {
      return {
        sliderKey: this.sliderKey,
        sliderAction: this.sliderAction,
        sliderParam: this.sliderParam,
        options: [...this.pOptions || []],
      };
    },
  },
  methods: {
    ...mapActions('services', [
      'setCardList',
    ]),
    fullSlider() {
      return this.areas[this.sliderKey];
    },
    isEmpty() {
      const fs = this.fullSlider().services || [];
      return fs.length === 0;
    },
    getPage(value) {
      this.pageText = value;
    },
    reorderSlider() {
      this.setCardList({
        type: this.sliderKey,
        param: this.sliderParam,
      });
    },
    getServiceData() {
      const store = this.$store;
      const { sliderAction, sliderKey, sliderParam } = this;
      if (this.isLogged || !this.pPrivate) {
        window.setTimeout(() => {
          store.dispatch(`services/${sliderAction}`, {
            type: sliderKey,
            param: sliderParam,
          });
        }, 500);
      }
    },
    toggleVisibility(key) {
      this.$store.dispatch('services/toggleVisibility', key);
    },
  },
  mounted() {
    if (this.pServices.length === 0) {
      this.getServiceData();
    }
  },
  watch: {
    token() {
      this.getServiceData();
    },
  },
});
</script>

<style lang="scss">
  .cardGroup__orderselectlabel {
    position: absolute;
    right: 21rem;
    top: 10px;
    font-size: 0.9rem;
    opacity: 0;
    transition: linear 0.25s opacity;
    @media (max-width: $screen-sm-min) {
      position: inherit;
    }
    select {
      padding: 0.2rem;
      margin: 0 0 0 0.25rem;
    }
  }
  .cardGroup__viewIcon {
    display: none;
    font-weight: bolder;
    &__bar{
      margin-left: -1em;
    }
  }
  .cardGroup__title {
    color: var(--title-color);
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1;
    margin: 0 0 0 0;
    padding: 0.3rem 1rem 0.4rem 0.375rem;
    @media (max-width: $screen-sm-min) {
      padding: 0.35rem 1rem 0.45rem 0.375rem;
      font-size: 1.15rem;
      max-width: calc(100vw - 5rem);
      white-space: nowrap;
      overflow:hidden;
      text-overflow: ellipsis;
    }
  }
  .cardGroup__titlePage {
    opacity: 0.85;
    font-size: 1rem;
    font-weight: 400;
  }
  .cardGroup__hideIcon {
    cursor: pointer;
  }
  .cardGroupList {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 1rem 0rem 1rem;
    position: relative;
    .sideMenu {
      opacity: 1;
    }
    .cardCarousel__nav__left,
    .cardCarousel__nav__right {
      display: block;
      @media (max-width: $screen-sm-min) {
        display: none;
      }
    }
    &.starred-services {
      @media (max-width: $screen-sm-min) {
        background-color: var(--card-bg-color);
        margin-bottom: 1rem;
      }
    }
    &.new-services {
      .card__newicon {
        display: none;
      }
    }
    &__item {
      max-width: var(--grid-width);
      margin: 0 auto;
    }
  }
  .cardGroup__viewIcon__eye {
    cursor: pointer;
    font-weight: bolder;
    position: relative;
    z-index: 1;
  }
</style>

<i18n src="../locales/i18nSliders.json"></i18n>
