export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "noitems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No items found"])}
      },
      "es": {
        "noitems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay servicios para estas condiciones"])}
      }
    }
  })
}
