/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable no-console */
import Logger from './log';

export default {
  install(Vue, options) {
    const loggerObject = new Logger();

    Vue.prototype.$logger = loggerObject;

    Vue.config.errorHandler = function (err, vm, info) {
      console.error(err, info);
      if (process.env.NODE_ENV === 'production') {
        vm.$logger.fatal(`Error: ${err.toString()}\nInfo: ${info}`, vm);
      }
    };
    Vue.config.warnHandler = function (msg, vm, trace) {
      console.warn(msg, trace);
      if (process.env.NODE_ENV === 'production') {
        vm.$logger.warn(`Warn: ${msg}\nTrace: ${trace}`, vm);
      }
    };
  },
};
