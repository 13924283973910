export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highlight"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
        "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from shortcuts"])}
      },
      "es": {
        "highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destacar"])},
        "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eliminar"])},
        "favourites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de favoritos"])}
      }
    }
  })
}
