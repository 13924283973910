export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less elements"])},
        "showless_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show less elements"])},
        "elements": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Showing ", _interpolate(_list(0)), " out of ", _interpolate(_list(1)), " elements"])},
        "pages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Page ", _interpolate(_list(0)), " out of ", _interpolate(_list(1))])}
      },
      "es": {
        "showless": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos"])},
        "showless_acc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver menos elementos"])},
        "elements": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Mostrando ", _interpolate(_list(0)), " de ", _interpolate(_list(1)), " elementos"])},
        "pages": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Página ", _interpolate(_list(0)), " de ", _interpolate(_list(1))])}
      }
    }
  })
}
