import { apiRequest } from '@/api';

import CodigosTramite from '@/app/acade/shared/enums/EnumCodigosTramite';

const COD_TRAMITE = CodigosTramite.TRASLADO_PAU.codigoTramite;
const moduleBaseApiURL = '/acade/trasladopau-api/v1.0/private';
const moduleBaseApiURLAcadeApiTramite = '/acade/acade-tramites-api/v1.0/private';

const endpoints = {
  userInfo: `${moduleBaseApiURL}/usuario`,
  construyeTramite: `${moduleBaseApiURL}/construyeTramite`,
  pruebasAcceso: `${moduleBaseApiURL}/pruebasAcceso`,
  universidades: `${moduleBaseApiURL}/universidades`,
  centros: `${moduleBaseApiURL}/centros`,
  estudios: `${moduleBaseApiURL}/estudios`,
  solicitudes: `${moduleBaseApiURL}/solicitudes`,
  solicitudId: `${moduleBaseApiURL}/solicitudes/id`,
  estadoTraslado: `${moduleBaseApiURL}/estadoTraslado`,
  cursosAcademicosDisponibles: `${moduleBaseApiURL}/cursosAcademicosDisponibles`,
  pdfBorrador: `${moduleBaseApiURL}/solicitudes/borrador`,
  iniciaFirmaResguardo: `${moduleBaseApiURL}/iniciaFirmaResguardo`,
  finFirmaResguardo: `${moduleBaseApiURL}/finFirmaResguardo`,

  // API de gestión de sesiones en backend
  session: `${moduleBaseApiURL}/sesiones`,

  // API de subsanación
  solicitudSubsanacionId: `${moduleBaseApiURL}/subsanacion/id`,
  subirArchivoSubsanacion: `${moduleBaseApiURL}/subsanacion/subirAnexo`,
  iniciaFirmaSubsanacion: `${moduleBaseApiURL}/subsanacion/iniciaFirma`,
  finFirmaSubsanacion: `${moduleBaseApiURL}/subsanacion/finFirma`,

  // Subsanación (backend común de acade)
  expedientesPendienteSubsanacion: `${moduleBaseApiURLAcadeApiTramite}/expedientes`,
  obtenerSolicitudPendienteSubsanacion: `${moduleBaseApiURLAcadeApiTramite}/expedientes/solicitudes`,
  obtenerDocumentoExpediente: `${moduleBaseApiURLAcadeApiTramite}/expedientes/documentos`,
  subsanarExpediente: `${moduleBaseApiURLAcadeApiTramite}/expedientes/subsanar`,
};

export default {
  endpoints: () => endpoints,

  async userInfo() {
    const { data: userInfo } = await apiRequest({ url: `${endpoints.userInfo}` });
    return userInfo;
  },

  // construyeTramite: () => apiRequest({ url: endpoints.construyeTramite })
  construyeTramite: async () => {
    const { data: tramite } = await apiRequest({
      url: `${endpoints.construyeTramite}`,
    });
    return tramite;
  },

  async pruebasAcceso() {
    const { data: pruebas } = await apiRequest({
      url: `${endpoints.pruebasAcceso}`,
    });
    return pruebas;
  },

  async universidades() {
    const { data: universidades } = await apiRequest({
      url: `${endpoints.universidades}`,
    });
    return universidades;
  },

  async centros(universidad) {
    const { data: centros } = await apiRequest({
      url: `${endpoints.centros}?universidad=${universidad}`,
    });
    return centros;
  },

  async estudios(centro, universidad) {
    const { data: estudios } = await apiRequest({
      url: `${endpoints.estudios}?centro=${centro}&universidad=${universidad}`,
    });
    return estudios;
  },

  async recuperarSolicitud(codigoTipoAcceso) {
    const { data: solicitud } = await apiRequest({
      url: `${endpoints.solicitudes}?codigoTipoAcceso=${codigoTipoAcceso}`,
    });
    return solicitud;
  },

  async recuperarCodigoSolicitud(codigoTipoAcceso) {
    const { data: solicitudId } = await apiRequest({
      url: `${endpoints.solicitudId}?codigoTipoAcceso=${codigoTipoAcceso}`,
    });
    return solicitudId;
  },

  async recuperarCodigoSolicitudPendienteSubsanacion() {
    const { data: solicitudId } = await apiRequest({
      url: `${endpoints.solicitudSubsanacionId}`,
    });
    return solicitudId;
  },

  async nuevaSolicitud(tipoAcceso) {
    let certificadoTraslado;
    try {
      certificadoTraslado = await this.recuperarEstadoTraslado(tipoAcceso.codigoTipoAcceso);
    } catch (error) {
      certificadoTraslado = null;
    }

    /// Si existe certificadoTraslado => tipoTraslado deberá ser 'Modificacion' o 'Anulacion'
    const solicitud = {
      identificador: null, /// TODO Pendiente identificador (recuperar solicitud del backend?)
      tipoAcceso,
      cursoAcademicoActual: null,
      cursoAcademicoTraslado: null,
      // destino: null,
      certificadoTraslado,
      nuevosDocumentos: [],
      tipoTraslado: 'Nuevo', // valores: 'Nuevo', 'Modificacion', 'Anulacion'
    };
    return solicitud;
  },

  async recuperarEstadoTraslado(codigoTipoAcceso) {
    const { data: certificadoTraslado } = await apiRequest({
      url: `${endpoints.estadoTraslado}?codigoTipoAcceso=${codigoTipoAcceso}`,
    });
    return certificadoTraslado === '' ? null : certificadoTraslado;
  },

  async recuperarCursosAcademicosTrasladoDisponibles(codigoTipoAcceso) {
    const { data: cursos } = await apiRequest({
      url: `${endpoints.cursosAcademicosDisponibles}?codigoTipoAcceso=${codigoTipoAcceso}`,
    });
    return cursos;
  },

  async guardarSolicitud(solicitud) {
    try {
      // capturamos error si no existe
      await this.recuperarCodigoSolicitud(
        solicitud.tipoAcceso.codigoTipoAcceso,
      );
      const { data: solicitudGuardada } = await apiRequest({
        url: `${endpoints.solicitudes}`,
        method: 'PUT',
        data: solicitud,
      });
      return solicitudGuardada;
    } catch (error) {
      const { response: { status } } = error;
      if (status === 404) {
        // Si todavía no existe la solicitud, la creamos
        const { data: solicitudGuardada } = await apiRequest({
          url: `${endpoints.solicitudes}`,
          method: 'POST',
          data: solicitud,
        });
        return solicitudGuardada;
      }
      throw error;
    }
  },

  async generarPDFBorrador(codigoTipoAcceso) {
    // console.time("generación pdf");
    const { data: documentoPdf } = await apiRequest({
      url: `${endpoints.pdfBorrador}?codigoTipoAcceso=${codigoTipoAcceso}`,
    });
    // console.timeEnd("generación pdf");
    return documentoPdf;
  },

  async iniciarFirma(solicitud, origin) {
    const { data: firmaIniciada } = await apiRequest({
      url: `${endpoints.iniciaFirmaResguardo}?backRedirect=${origin}`,
      method: 'POST',
      data: solicitud,
    });
    return firmaIniciada;
  },

  async finalizarFirma(idTransaccion, codigoTipoAcceso) {
    /// TODO Posible mejora pendiente: validar que el idTransaccion procede
    /// de la aplicación (guardado previamente en store)
    /// y que no se ha utilizado todavía (tras firmar eliminar del store)
    const url = `${endpoints.finFirmaResguardo}/${idTransaccion}?codigoTipoAcceso=${codigoTipoAcceso}`;
    const { data: resguardoResponse } = await apiRequest({
      url,
      method: 'POST',
    });
    return resguardoResponse;
  },

  // API de gestión de sesiones en backend

  async storeSession(session) {
    const { data: sessionId } = await apiRequest({
      url: endpoints.session,
      method: 'POST',
      data: session,
    });
    return sessionId;
  },

  async restoreSession(sessionId) {
    const { data: session } = await apiRequest({
      url: `${endpoints.session}?idSession=${sessionId}`,
    });
    return session;
  },

  // API de subsanación

  subirArchivoSubsanacion(file) {
    return apiRequest({
      url: `${endpoints.subirArchivoSubsanacion}`,
      method: 'POST',
      data: file,
    });
  },

  iniciaFirmaSubsanacion(parametrosPost) {
    return apiRequest({
      url: `${endpoints.iniciaFirmaSubsanacion}`,
      method: 'POST',
      data: parametrosPost,
    });
  },

  finFirmaSubsanacion(idTransaccion, request) {
    return apiRequest({
      url: `${endpoints.finFirmaSubsanacion}/${idTransaccion}`,
      method: 'POST',
      data: request,
    });
  },

  expedientesPendienteSubsanacion: () => apiRequest({
    url: `${endpoints.expedientesPendienteSubsanacion}?codTramite=${COD_TRAMITE}`,
  }),

  async obtenerSolicitudPendienteSubsanacion(expediente, usuarioUnidadAdministrativa) {
    const { data: solicitud } = await apiRequest({
      url: `${endpoints.obtenerSolicitudPendienteSubsanacion}?eniExpediente=${expediente.identificador}&usuarioUnidadAdministrativa=${usuarioUnidadAdministrativa}&codTramite=${COD_TRAMITE}`,
    });
    return solicitud;
  },

  async obtenerDocumentoExpediente(expediente, eniDocumento, usuarioUnidad) {
    const { data: respuesta } = await apiRequest({
      url: `${endpoints.obtenerDocumentoExpediente}?eniExpediente=${expediente.identificador}&usuarioUnidadAdministrativa=${usuarioUnidad}&eniDocumento=${eniDocumento}`,
    });
    return respuesta;
  },

  subsanarExpediente: (solicitud) => apiRequest({
    url: `${endpoints.subsanarExpediente}`,
    method: 'POST',
    data: solicitud,
  }),
};
