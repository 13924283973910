import Vue from 'vue';

const clickOutside = {
  // eslint-disable-next-line no-unused-vars
  beforeMount(domNode, binding, vnode) {
    const el = domNode;
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};

if (Vue && Vue.directive) {
  Vue.directive('click-outside', clickOutside);
}
export default clickOutside;
