export default {
  namespaced: true,
  // ESTADO
  state: {
    idTransacion: null,
    idSession: null,
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    setIdTransaccion(state, valor) {
      state.idTransacion = valor;
    },
    setIdSession(state, valor) {
      state.idSession = valor;
    },
    setLimpiarDatosAcade(state) {
      state.idTransacion = null;
      state.idSession = null;
    },
  },
};
