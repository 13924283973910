<template>
  <Dialog
      :header="$t('declaracionResponsable')"
      :modal="true"
      v-model:visible='declaracionResponsableLocal'
      :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }"
    >
      <div class="flex flex-wrap gap-3">
        <div class="flex">
          <label for="checkDeclaracionResponsable" class="ml-3">
            <span v-html="$t('mensajeDeclaracionResponsable1')"></span>
            <span v-if="tipoOrigen === this.Origenes
              .ORIGEN_UNIVERSIDAD_EXTRANJERA.id"
              v-html="$t('mensajeDeclaracionResponsable2')"></span>
            <span v-if="codigoTramite === EnumCodigosTramite
              .TRASLADO_EXPEDIENTE_ENTRANTE.codigoTramite"
              v-html="$t('mensajeDeclaracionResponsable3',[descripcionTitulacion])"></span>
            <span v-if="codigoTramite === EnumCodigosTramite
              .RECONOCIMIENTO_CREDITOS.codigoTramite"
              v-html="$t('mensajeDeclaracionResponsable4',[descripcionTitulacion])"></span>
            <Checkbox v-model="checkDeclaracionResponsable" name="check"
            value="checkDeclaracionResponsable" class="ml-3"/>
            <small role="alert" v-if="erroresValidacion.id === 'checkDeclaracionResponsable'"
              class="p-invalid block mt-4">{{ $t('requiredDeclaracionResponsable') }}</small>
          </label>
        </div>
      </div>
      <template #footer>
        <Button :label="$t('cancelar')" @click="ventanaModalDeclaracionResponsable()"
          class="p-button-secondary" />
        <Button :label="$t('aceptar')" @click="nextPage()" />
      </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { defineComponent } from 'vue';
import Origenes from '@/app/acade/traslado-expediente-entrada/enum/EnumOrigenes';
import EnumCodigosTramite from '@/app/acade/shared/enums/EnumCodigosTramite';

const compatMode3 = { MODE: 3 };
Dialog.compatConfig = compatMode3;
Button.compatConfig = compatMode3;
Checkbox.compatConfig = compatMode3;

export default defineComponent({
  name: 'Declaracion Responsable',
  emits: ['ventanaModalDeclaracionResponsable', 'nextPage'],
  components: {
    Dialog,
    Button,
    Checkbox,
  },
  props: {
    declaracionResponsable: {
      type: Boolean,
      required: true,
    },
    descripcionTitulacion: {
      type: String,
      required: true,
    },
    tipoOrigen: {
      type: String,
      required: true,
    },
    codigoTramite: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkDeclaracionResponsable: false,
      erroresValidacion: {},
      declaracionResponsableLocal: false,
      Origenes,
      EnumCodigosTramite,
    };
  },
  mounted() {
    this.declaracionResponsableLocal = this.declaracionResponsable;
  },
  methods: {
    modificarErrorValidacion(state) {
      this.erroresValidacion = {
        ...this.erroresValidacion,
        id: state,
      };
    },
    ventanaModalDeclaracionResponsable() {
      this.$emit('ventanaModalDeclaracionResponsable');
    },
    nextPage() {
      // Comprobamos si se han checkeado la declaración responsable
      if (this.checkDeclaracionResponsable === false) {
        this.modificarErrorValidacion('checkDeclaracionResponsable', true);
        return;
      }
      this.declaracionResponsableLocal = !this.declaracionResponsableLocal;
      this.$emit('nextPage');
    },
  },
});
</script>

<i18n src='../locales/i18declaracionResponsable.json'></i18n>
