<template>
  <section id="myevents" class="myEventsMaximized">
    <h1 class="cardGroup__title">
      {{ $t('calendar') }}
    </h1>
    <div class="myEventsMaximized__data">
      <div class="myEventsMaximized__calendarContainer">
        <vue-cal
          class="vuecal--um-theme"
          small
          hide-view-selector
          click-to-navigate
          :time="false"
          active-view="month"
          :locale="locale"
          :disable-views="['week', 'day']"
          :todayButton="true"
          :events="events"
          @cell-click="onDayClick($event)"
          @view-change="updateData($event)"
        >
          <template v-slot:today-button>
            <span
              class="today pose__button button__secondary"
              @click="toToday()"
            >
              {{ $t('today') }}
            </span>
          </template>
        </vue-cal>
      </div>
      <div class="myEventsMaximized__eventListContainer">
        <span
          :title="$t('showless')"
          class="myEventsMaximized__minimize"
          @click="this.$emit('minimize')"
        >
          <i class="fa-solid fa-down-left-and-up-right-to-center" />
          <span class="sr-only">{{ $t('showless') }}</span>
        </span>
        <aside class="myEventsMaximized__eventDetail">
          <my-events-list
            :is-today-day="today"
            :selected-day="selectedDay"
            :events="selectedEvents"
          />
        </aside>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import 'vue-cal/dist/i18n/es';
import dateMixin from '@/app/shared/mixins/dateMixin';
import { eventsApi, eventApiDTO2eventDTO } from '../events.api';
import MyEventsList from './MyEventsList.vue';

export default defineComponent({
  name: 'MyEvents',
  mixins: [dateMixin],
  emits: ['minimize'],
  components: {
    MyEventsList,
    VueCal,
  },
  data() {
    return {
      today: true,
      events: [],
      selectedDay: new Date().toISOString().slice(0, 10),
      attrs: [
        {
          key: 'today',
          highlight: true,
          color: 'red',
          dates: new Date(),
        },
      ],
      activeMonth: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      },
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
    dates() {
      const evt = new Set(this.events.map(
        (el) => ({ start: el.start, end: el.end }),
      ));
      return Array.from(evt);
    },
    calendarEvents() {
      const dateAttrs = this.dates.map((date) => ({
        highlight: true,
        dates: date,
      }));
      return [...dateAttrs, ...this.attrs];
    },
    selectedEvents() {
      return (!this.events.filter)
        ? []
        : this.events.filter((el) => el.start <= this.selectedDay
          && el.end >= this.selectedDay);
    },
  },
  // Si el usuario pulsa en el día 'x' que se quede en localStorage.
  methods: {
    toToday() {
      this.today = true;
      this.selectedDay = this.fromDateToISO(new Date());
    },
    onDayClick(value) {
      this.today = this.isToday(value);
      this.selectedDay = this.fromDateToISO(value);
    },
    updateData(value) {
      this.activeMonth = {
        month: value.startDate.getMonth() + 1,
        year: value.startDate.getFullYear(),
      };
      this.getEvents();
    },
    getEvents() {
      let previousMonth = this.activeMonth.month - 1;
      let previousYear = this.activeMonth.year;
      if (previousMonth <= 0) {
        previousMonth = 12;
        previousYear -= 1;
      }
      let nextMonth = this.activeMonth.month + 1;
      let nextYear = this.activeMonth.year;
      if (nextMonth > 12) {
        nextMonth = 1;
        nextYear += 1;
      }
      const nextMonthDays = new Date(nextYear, nextMonth, 0).getDate();
      const sinceMonth = String(previousMonth).padStart(2, '0');
      const toMonth = String(nextMonth).padStart(2, '0');
      const from = `01/${sinceMonth}/${previousYear}%2000:00`;
      const to = `${nextMonthDays}/${toMonth}/${nextYear}%2023:59`;
      eventsApi.events(from, to)
        .then((response) => response.data)
        .then((eventsFromApi) => {
          this.events = eventApiDTO2eventDTO(eventsFromApi.datos, this.events);
        });
    },
  },
  mounted() {
    this.getEvents();
  },
  watch: {
    token() {
      this.getEvents();
    },
  },
});
</script>

<i18n>
{
  "en": {
    "calendar": "My calendar",
    "from": "From",
    "to": "to",
    "at": "at",
    "prevMonth": "Navigate to previous month",
    "nextMonth": "Navigate to next month",
    "today": "Today",
    "showless": "Show less"
  },
  "es": {
    "calendar": "Mi agenda",
    "from": "De",
    "to": "a",
    "at": "a las",
    "prevMonth": "Ir al mes anterior",
    "nextMonth": "Ir al mes siguiente",
    "today": "Hoy",
    "showless": "Mostrar menos"
  }
}
</i18n>

<style lang="scss">

@import "@/app/shared/assets/_vuecal.scss";

.myEventsMaximized {
  padding: 0;
  max-width: var(--grid-width);
  margin: 0 auto 2rem auto;
  @media (max-width: $screen-sm-min) {
    padding: 0 1rem;
  }

  &__data {
    align-items: stretch;
    border-radius: var(--card-border-radius);
    box-shadow: var(--shadowLevel-2);
    display: flex;
    flex-direction: row;
    margin: 1rem 0.5rem 0 0.5rem;
    position: relative;
    @media (max-width: $screen-sm-min) {
      flex-direction: column;
    }
  }

  &__calendarContainer {
    flex-shrink: 0;
    padding: 1rem;
    align-self: stretch;
    @media (max-width: $screen-xs-min) {
      height: 26rem;
    }
    @media (min-width: $screen-xs-min) {
      align-self: flex-start;
      width: 20rem;
      min-height: 20rem;
    }
  }

  &__minimize {
    position: absolute;
    top: -2.55rem;
    right: 0.75rem;
    cursor: pointer;
  }

  &__eventListContainer {
    background-color: var(--privatebgcolor);
    flex-grow: 1;
  }

  &__eventCalendar {
    @media (max-width: $screen-sm-min) {
      margin: 0 auto 1rem auto;
    }
  }

  &__toToday {
    &.pose__button {
      display: block;
      margin: 0.5rem auto 0 auto;
    }
  }

  &__eventDetail {
    padding: 0 0 0 1rem;
    flex-grow: 2;
    position: relative;
    @media (max-width: $screen-sm-min) {
      padding: 0 0 0.25rem 1rem;
    }
  }

  &__eventList {
    padding: 0 0;
    list-style: none;
    margin: 0 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }

  .vc-container {
    --red-600: var(--category1-color);
    --grey-600: #424242;

    font-family: var(--font-family);
    border-color: var(--border-color);
  }
}
</style>
