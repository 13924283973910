import ServicioFN from '@/app/acade/shared/familiaNumerosa/services/familiaNumerosa.api';

export default {
  namespaced: true,
  state: {
    poseeFamiliaNumerosa: false,
    familiaNumerosaUniversidadMurcia: null,
    familiaNumerosaComunidadAutonoma: null,
    familiaNumerosaComunidadAutonomaError: null,
  },

  getters: {
    poseeFamiliaNumerosa(state) {
      return state.poseeFamiliaNumerosa;
    },
    familiaNumerosaUniversidadMurcia(state) {
      return state.familiaNumerosaUniversidadMurcia;
    },
    familiaNumerosaComunidadAutonoma(state) {
      return state.familiaNumerosaComunidadAutonoma;
    },
    familiaNumerosaComunidadAutonomaError(state) {
      return state.familiaNumerosaComunidadAutonomaError;
    },
  },

  actions: {
    obtenerFamiliaNumerosaUniversidadMurcia({ commit }) {
      return ServicioFN.obtenerFamiliaNumerosaUniversidadMurcia().then(({ data }) => {
        commit('SET_FAIMILIA_NUMEROSA_UNIVERSIDAD_MURCIA', data);
      })
        .catch((error) => {
          throw new Error(error);
        });
    },
    obtenerFamiliaNumerosaComunidadAutonoma({ commit }, params) {
      return ServicioFN.obtenerFamiliaNumerosaComunidadAutonoma(
        params.codigoComunidadAutonoma, params.codigoTramiteInventario,
      ).then(({ data }) => {
        if (data && data.estado && data.estado.codigoEstado !== '0') {
          commit('SET_FAMILIA_NUMEROSA_COMUNIDAD_AUTONOMA_ERROR', data.estado.literalError);
        } else {
          commit('SET_FAMILIA_NUMEROSA_COMUNIDAD_AUTONOMA', data);
        }
      })
        .catch((error) => {
          throw new Error(error);
        });
    },
    agregarNuevaFamiliaNumerosa(params) {
      // devuelve información del documento FN
      return ServicioFN.agregarNuevaFamiliaNumerosa(params.fn);
    },
  },

  mutations: {
    SET_FAIMILIA_NUMEROSA_UNIVERSIDAD_MURCIA(state, data) {
      state.familiaNumerosaUniversidadMurcia = data;
    },
    SET_FAMILIA_NUMEROSA_COMUNIDAD_AUTONOMA(state, data) {
      state.familiaNumerosaComunidadAutonoma = data;
    },
    SET_FAMILIA_NUMEROSA_COMUNIDAD_AUTONOMA_ERROR(state, data) {
      state.familiaNumerosaComunidadAutonomaError = data;
    },
  },
};
