export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "safariTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te damos la bienvenida a Mi campus UMU"])},
        "safariText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información, servicios y trámites en línea de la Universidad de Murcia."])},
        "safariLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué debes saber de Mi campus?"])},
        "safariButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticarse en entrada.um.es"])},
        "safariCau1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Problemas con la plataforma? Contacta con el CAU de Atica"])},
        "safariCau2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o escribe a"])}
      },
      "es": {
        "safariTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te damos la bienvenida a Mi campus UMU"])},
        "safariText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información, servicios y trámites en línea de la Universidad de Murcia."])},
        "safariLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué debes saber de Mi campus?"])},
        "safariButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autenticarse en entrada.um.es"])},
        "safariCau1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Problemas con la plataforma? Contacta con el CAU de Atica"])},
        "safariCau2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o escribe a"])}
      }
    }
  })
}
