import {
  apiRequest,
} from '@/api';

const moduleBaseApiURL = '/acade/acade-tramites-api/v1.0/private';

const endpoints = {

  /** Servicios Rest */
  obtenerFamiliaNumerosaComunidadAutonoma: `${moduleBaseApiURL}/estudiantes/familiasnumerosas/comunidadesautonomas`,
  descargarFamiliaNumerosaComunidadAutonoma: `${moduleBaseApiURL}/estudiantes/familiasnumerosas/comunidadesautonomas/peticiones`,
  agregarNuevaFamiliaNumerosa: `${moduleBaseApiURL}/estudiantes/familiasnumerosas`,
  obtenerFamiliaNumerosaUniversidadMurcia: `${moduleBaseApiURL}/estudiantes/familiasnumerosas`,

};

const familiaNumerosaApi = {
  endpoints: () => endpoints,
  agregarNuevaFamiliaNumerosa: (parametros) => apiRequest({
    url: `${endpoints.agregarNuevaFamiliaNumerosa}`,
    data: parametros,
    method: 'POST',
  }),
  obtenerFamiliaNumerosaComunidadAutonoma:
    (codigoComunidadAutonoma, codigoTramiteInventario) => apiRequest({
      url: `${endpoints.obtenerFamiliaNumerosaComunidadAutonoma}?codigoComunidadAutonoma=${codigoComunidadAutonoma}&codigoTramiteInventario=${codigoTramiteInventario}`,
    }),
  descargarFamiliaNumerosaComunidadAutonoma: (idPeticion) => apiRequest({
    url: `${endpoints.descargarFamiliaNumerosaComunidadAutonoma}?idPeticion=${idPeticion}`,
  }),
  obtenerFamiliaNumerosaUniversidadMurcia: () => apiRequest({
    url: `${endpoints.obtenerFamiliaNumerosaUniversidadMurcia}`,
  }),
};

export default familiaNumerosaApi;
