<template>
  <div>
    <my-event-modal-detail
      v-if="selectedEvent"
      :detailEvent="selectedEvent"
      @closeModal="resetSelectedEvent"
    />
    <h2
      v-if="isTodayDay"
      class="myeventslist__subtitle"
    >
      {{ $t('todayEvents') }}
    </h2>
    <h2
      v-else
      class="myeventslist__subtitle"
    >
      {{ readableDate }}
      <span
        v-if="events.length > 4"
        class="myeventslist__eventsLength"
      >
        {{ events.length }}
        {{ $t('dayEvents') }}
      </span>
    </h2>
    <p class="myeventslist__nodata" v-if="events.length === 0">
      <span class="myeventslist__nodataIcon">
        <i class="fad fa-calendar-alt"></i>
      </span>
      <span class="myeventslist__nodataText">
        {{ $t('noEvents') }}
      </span>
    </p>
    <div v-else>
      <div
        class="myeventslist__eventList"
      >
        <span
          class="myeventslist__hourDivider"
           v-if="isTodayDay && events.length > 1"
          :style="styleNow"
        >
          <span>
            {{ now }}
          </span>
          <div />
        </span>
        <my-events-detail
          :event="event"
          v-for="(event, index) in sortedEvents"
          :key="index"
          @showEventDetail="showModalEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import dateMixin from '@/app/shared/mixins/dateMixin';

import MyEventsDetail from './MyEventsDetail.vue';
import MyEventModalDetail from './MyEventModalDetail.vue';

export default defineComponent({
  name: 'MyEventsList',
  components: {
    MyEventsDetail,
    MyEventModalDetail,
  },
  mixins: [dateMixin],
  props: {
    isTodayDay: { type: Boolean, default: true },
    selectedDay: { type: String, required: true },
    events: { type: Array, required: true },
  },
  data() {
    return {
      collapsed: true,
      now: '',
      countdown: null,
      selectedEvent: null,
    };
  },
  computed: {
    styleNow() {
      return `order: ${this.now.replace(':', '')};`;
    },
    readableDate() {
      const formattedDate = new Date(this.selectedDay).toLocaleDateString(
        undefined,
        {
          weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
        },
      );
      return formattedDate.charAt(0).toLocaleUpperCase() + formattedDate.slice(1);
    },
    sortedEvents() {
      return [...this.events].sort(
        (a, b) => parseInt(a.starthour.replace(':', '') || 0, 10) - parseInt(b.starthour.replace(':', '') || 0, 10),
      );
    },
  },
  mounted() {
    this.getNow();
    this.countdown = setInterval(() => {
      this.getNow();
    }, 60000);
  },
  unmounted() {
    clearInterval(this.countdown);
  },
  methods: {
    getNow() {
      this.now = new Date().toLocaleTimeString(
        undefined,
        { hour: '2-digit', minute: '2-digit' },
      );
    },
    showModalEvent(evt) {
      this.selectedEvent = evt;
    },
    resetSelectedEvent() {
      this.selectedEvent = null;
    },
  },
  watch: {
    events: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        this.currentPage = 0;
        this.currentMin = 0;
        this.currentMax = this.eventsPerPage;
      },
      deep: true,
    },
    eventsPerPage(newValue) {
      this.currentPage = 0;
      this.currentMin = 0;
      this.currentMax = newValue;
    },
  },
});
</script>

<i18n>
{
  "en": {
    "totalElements": "Total",
    "showing": "Page",
    "dayEvents": "events",
    "todayEvents": "Today events",
    "noEvents": "There are no events for that day",
    "previous": "Previous",
    "next": "Next"
},
  "es": {
    "totalElements": "Total",
    "showing": "Página",
    "dayEvents": "eventos",
    "todayEvents": "Eventos de hoy",
    "noEvents": "No hay eventos para este día",
    "previous": "Anterior",
    "next": "Siguiente"
  }
}
</i18n>

<style lang="scss">
.myeventslist {
  &__subtitle {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0;
    padding: 0.875rem 0 0.75rem 0;
  }
  &__eventsLength {
    font-weight: 400;
  }
  &__button {
    padding: 1rem;
  }
  &__navigator {
    margin: 0 0 1rem 0;
    font-size: 0.75rem;
    font-weight: 500;
  }
  &__eventList {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    @media (min-width: $screen-xs-min) {
      overflow-y: auto;
      max-height: 19rem;
    }
  }
  &__hourDivider {
    align-items: center;
    color: var(--main-color);
    display: flex;
    flex-direction: row;
    font-size: 0.75rem;
    margin: 0 1rem 0 0;
    > span {
      padding: 0 1rem 0 0;
    }
    > div {
      background: var(--main-color);
      height: 1px;
      width: 100%;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: -0.25rem;
        background-color: var(--main-color);
        border-radius: 100%;
        display: block;
        height: 0.5rem;
        width: 0.5rem;
      }
    }
  }
  &__buttonContainer {
    // position: absolute;
    bottom: 0;
    left: 1rem;
  }
  &__nodata {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 14rem;
    @media (max-width: $screen-sm-min) {
      margin: -2.5rem 0 0 0;
    }
  }
  &__nodataIcon {
    display: block;
    font-size: 5rem;
    opacity: 0.4;
  }
  &__nodataText {
    font-weight: 700;
  }
}
</style>
