export default {
  BAJA_MATRICULA: {
    codigoTramite: '001',
    codigoTramiteInventario: 'PR-000192',
  },
  TRASLADO_PAU: {
    codigoTramite: '002',
    codigoTramiteInventario: 'PR-000029',
  },
  RECONOCIMIENTO_CREDITOS: {
    codigoTramite: '011',
    codigoTramiteInventario: 'PR-000173',
  },
  TRASLADO_EXPEDIENTE_ENTRANTE: {
    codigoTramite: '012',
    codigoTramiteInventario: 'PR-000298',
  },
  CONVOCATORIA_INCIDENCIAS: {
    codigoTramite: '010',
    codigoTramiteInventario: 'PR-000170',
  },
  COMPENSACION_ASIGNATURAS: {
    codigoTramite: '003',
    codigoTramiteInventario: 'PR-000136',
  },
  MANTENIMIENTO_PRECIOS_PUBLICOS: {
    codigoTramite: '005',
    codigoTramiteInventario: 'PR-000198',
  },
  BAJA_PARCIAL_MATRICULA: {
    codigoTramite: '013',
    codigoTramiteInventario: 'PR-000194',
  },
  MATRICULA_PARCIAL: {
    codigoTramite: '014',
    codigoTramiteInventario: 'PR-000293',
  },
};
