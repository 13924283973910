export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading data..."])},
        "completeListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services full list"])},
        "noVisibleSliderMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibility of all sliders has been disabled "])},
        "noVisibleSliderMsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or there is no service as a favorite. To modify this home screen"])},
        "noVisibleSliderMsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" it to your liking, you can"])},
        "noVisibleSliderBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["access the settings of your preferences"])}
      },
      "es": {
        "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando datos..."])},
        "completeListTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista completa de servicios"])},
        "noVisibleSliderMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se ha deshabilitado la visibilidad de todos los carriles"])},
        "noVisibleSliderMsg2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["o no hay ningún servicio como favorito. Para modificar "])},
        "noVisibleSliderMsg3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esta pantalla de inicio y ponerla a tu gusto, puedes acceder"])},
        "noVisibleSliderBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a la configuración de tus preferencias"])}
      }
    }
  })
}
