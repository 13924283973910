export default {
  namespaced: true,
  // ESTADO
  state: {
    tramite: null,
    docResguardo: null,
    docResolucion: null,
    pidResguardo: '',
    urlApi: null,
    formData: {},
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    SET_FORM_DATA(state, valor) {
      state.formData = valor;
    },
    SET_TRAMITE(state, valor) {
      state.tramite = valor;
    },
    SET_DOC_RESGUARDO(state, valor) {
      state.docResguardo = valor;
    },
    SET_DOC_RESOLUCION(state, valor) {
      state.docResolucion = valor;
    },
    SET_PID_RESGUARDO(state, valor) {
      state.pidResguardo = valor;
    },
    SET_URL_API(state, valor) {
      state.urlApi = valor;
    },
  },
};
