import { defineAsyncComponent } from 'vue';

const MisTramites = defineAsyncComponent(() => import(/* webpackChunkName: "eadmin-MisTramitesView" */'./views/MisTramitesView.vue'));
const DetalleTramite = defineAsyncComponent(() => import(/* webpackChunkName: "eadmin-DetalleTramiteView" */'./views/DetalleTramiteView.vue'));
const NuevoTramite = defineAsyncComponent(() => import(/* webpackChunkName: "eadmin-NuevoTramite" */'./views/NuevoTramite.vue'));

export default [
  {
    path: '/mis-tramites/',
    name: 'ConsultaMisTramites',
    component: MisTramites,
    meta: {
      requiresAuth: true,
      group: 'eadmin',
      application: 'mis-tramites',
    },
  },
  {
    path: '/mis-tramites/:idAplicacion/:idTramite/',
    name: 'DetalleTramite',
    component: DetalleTramite,
    meta: {
      requiresAuth: true,
      group: 'eadmin',
      application: 'mis-tramites',
    },
  },
  {
    path: '/mis-tramites/nuevo-tramite/',
    name: 'NuevoTramite',
    component: NuevoTramite,
    meta: {
      requiresAuth: true,
      group: 'eadmin',
      application: 'mis-tramites',
    },
  },
];
