// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import storage from '@/storage';
import authApi from '../../auth.api';
import authCrypto from '../../utils/crypto';
import navigateTo from '../../utils/navigateTo';

function isLoaMedium(tokenData) {
  return (tokenData.authnContextClass?.startsWith('mfa-')
    || (tokenData.clientName && (tokenData.clientName === 'Cl@ve' || tokenData.clientName === 'Cl@veD'))) || false;
}

const auth = {
  namespaced: true,
  state: () => ({
    admin: {
      token: '',
      user: null,
    },
    isAdmin: false,
    token: null,
    tokenData: null,
    sessionID: null,
    expires: null,
    loginError: null,
    needs2FA: false,
    valid2FA: false,
    cas: authApi.endpoints().cas,
  }),
  mutations: {
    /*
    BECOME_USER(state, token) {
      state.admin.user = state.user;
      state.admin.token = state.token;
      state.user = null;
      state.token = token;
    },
    */
    USER_RESTORE(state) {
      state.user = state.admin.user;
      state.token = state.admin.token;
      state.admin.user = null;
      state.admin.token = '';
    },
    SET_TOKEN(state, ticket) {
      state.token = ticket;
      const tokenData = jwt_decode(ticket);
      state.tokenData = tokenData;
      state.expires = state.tokenData.exp * 1000;
      state.valid2FA = isLoaMedium(tokenData);
      if (!state.sessionID) {
        state.sessionID = authCrypto.generateRandomString();
      }
      // Guardo si está logueado o no para pasarlo al resto
      // de módulos de la store y no realizar importaciones cíclicas
    },
    SET_ADMIN(state, admin) {
      state.isAdmin = admin;
    },
    REVOKE_TOKEN(state) {
      state.token = null;
      state.tokenData = null;
      state.admin.token = null;
      state.expires = null;
      state.valid2FA = false;
      state.needs2FA = false;
      storage.removeItem('accesstoken');
      // Guardo si está logueado o no para pasarlo al resto
      // de módulos de la store y no realizar importaciones cíclicas
    },
    SET_ERROR(state, error) {
      state.loginError = error;
    },
    SET_2FA_NEEDED(state, needs2FA) {
      state.needs2FA = needs2FA;
    },
  },
  actions: {
    async login() {
      storage.setItem('accesstokenlogin', 'token');
      await authApi.login();
    },
    async loginLoaMedium() {
      await authApi.login(true);
    },
    accessToken({ commit }, code) {
      const loaLoginRoutePath = storage.getItem('POSE_FORBIDDEN2FA_ROUTE_KEY') || '';
      if (loaLoginRoutePath) {
        storage.setItem('POSE_LOGIN_ROUTE_KEY', loaLoginRoutePath);
        storage.removeItem('POSE_FORBIDDEN2FA_ROUTE_KEY');
      }
      // TO-DO: umusessionid con crypto
      authApi.getToken(code, loaLoginRoutePath || false)
        .then((r) => r.data)
        .then((data) => {
          storage.setItem('accesstoken', data.access_token);
          storage.removeItem('pkce_state');
          storage.removeItem('pkce_code_verifier');
          storage.removeItem('accesstokenlogin');
          commit('SET_TOKEN', data.access_token);
          commit('SET_2FA_NEEDED', false);
          let lastRoutePath = storage.getItem('POSE_LOGIN_ROUTE_KEY') || '';
          if (!lastRoutePath.includes('login')) {
            if (lastRoutePath.charAt(0) === '/') {
              lastRoutePath = lastRoutePath.slice(1);
            }
            storage.removeItem('POSE_LOGIN_ROUTE_KEY');
            const url = `${
              authApi.endpoints().originalPath
            }/#/${lastRoutePath}`;
            navigateTo(url);
          } else {
            navigateTo(authApi.endpoints().originalPath);
          }
        }).catch((error) => {
          if (error.response && error.response.status > 404) {
            // Ñapa error 423 CAS con Safari
            storage.setItem('accesstoken-fail', `CAS-${error.response.status}`);
            storage.removeItem('pkce_state');
            storage.removeItem('accesstokenlogin');
            storage.removeItem('pkce_code_verifier');
            storage.removeItem('vuex');
            storage.removeItem('p15s-useruuid');
            commit('REVOKE_TOKEN');
            commit('SET_ERROR', {
              code: error.response.status,
              info: error.response?.data || '',
            });
            setTimeout(() => {
              window.location.reload(true);
            }, 2500);
          } else {
            navigateTo(authApi.endpoints().originalPath);
          }
        });
    },
    revokeToken({
      commit,
    }) {
      commit('REVOKE_TOKEN');
      window.location.href = `${authApi.endpoints().logout}`;
    },
  },
  getters: {
    isLogged: (state) => state.token !== null && state.token !== '',
    token: (state) => state.token,
    isImpersonating: (state) => state.admin.user !== null,
    urlLogin: () => authApi.endpoints.login,
    valid2FA: (state) => state.valid2FA,
  },
};

export default auth;
