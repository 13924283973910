export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "apiumTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data transfer from ApiUM."])},
        "incremental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incremental transfer"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfer all data"])},
        "becomeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Become user"])},
        "comeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop impersonation"])}
      },
      "es": {
        "apiumTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga de datos desde ApiUM."])},
        "incremental": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga incremental"])},
        "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carga total"])},
        "becomeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suplantar usuarios"])},
        "comeBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dejar de suplantar"])}
      }
    }
  })
}
