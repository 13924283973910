const icons = [
  'fab fa-accusoft',
  'fas fa-address-book',
  'fas fa-archway',
  'fas fa-balance-scale',
  'fas fa-beer',
  'fas fa-bicycle',
  'fab fa-algolia',
  'fas fa-bus-alt',
  'fas fa-car-side',
  'fas fa-baby-carriage',
  'fas fa-baby',
  'fas fa-birthday-cake',
  'fas fa-blind',
  'fas fa-cat',
  'fas fa-fighter-jet',
  'fas fa-address-card',
  'fas fa-envelope-open',
  'fas fa-lock',
  'fas fa-hat-cowboy-side',
];

export default icons;
