export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reload"])},
        "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, your session will expire in"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, Your session has expired"])},
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutes and "])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seconds"])}
      },
      "es": {
        "reload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recargar"])},
        "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, su sesión ha caducado"])},
        "warn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atención, su sesión caducará en"])},
        "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minutos y "])},
        "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["segundos"])}
      }
    }
  })
}
