export default {
  namespaced: true,
  state: {
    jsonSubsanacion: {},
  },
  mutations: {
    guardarSolicitudSubsanacion(state, valor) {
      state.jsonSubsanacion = valor;
    },
    limpiarDatos(state) {
      state.jsonSubsanacion = null;
    },
  },
};
