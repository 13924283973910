/* Needed to disable for Vuex compatibility */
/* eslint-disable no-param-reassign */
import userApi from '../../user.api';

export default {
  namespaced: true,
  state: {
    user: null,
  },
  mutations: {
    SET_USER(state, user) {
      state.user = { ...user, uuid: btoa(user.email) };
      sessionStorage.setItem('p15s-roles', JSON.stringify(user.roleName));
      localStorage.setItem('p15s-useruuid', btoa(user.email));
    },
  },
  actions: {
    setUser({ commit }) {
      userApi.whoAmI()
        .then((response) => response.data)
        .then((user) => {
          commit('SET_USER', user);
          commit('auth/SET_ADMIN', user.isAdmin && user.isAdmin === true, {
            root: true,
          });
          if (user.profile.inLanguage) {
            commit('language/SET_LANGUAGE', user.profile.inLanguage, { root: true });
          }
        });
    },
  },
  getters: {
    hasRoles: (state) => (state.user !== null ? state.user.roleName : []),
    isAdmin: (state) => (state.admin.user !== null || (state.user && state.user.isAdmin === true)),
    isNewUser: (state) => (state.user && state.user.newUser && state.user.newUser === '1'),
    userIsLoaded: (state) => state.user !== null,
  },
};
