<template>
  <div class="myEventsContainer" :class="className" v-if="isLogged">
    <my-events-maximized @minimize="toggleMaximize()" v-if="maximizeEvents" />
    <my-events-minimized @maximize="toggleMaximize()" v-else />
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';
import MyEventsMaximized from './MyEventsMaximized.vue';
import MyEventsMinimized from './MyEventsMinimized.vue';

export default defineComponent({
  name: 'MyEventsContainer',
  components: {
    MyEventsMaximized,
    MyEventsMinimized,
  },
  data() {
    return {
      showMaximizedComponent: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
    ...mapState('services', ['maximizeEvents']),
    className() {
      let css = ' ';
      css += (this.showMaximizedComponent ? 'is-maximized' : 'is-minimized');
      if (this.loading) {
        css += ' is-loading';
      }
      return css;
    },
  },
  beforeMount() {
    this.showMaximizedComponent = this.maximizeEvents || false;
  },
  methods: {
    toggleMaximize() {
      this.$store.commit('services/TOGGLE_EVENTS');
    },
  },
});
</script>

<style lang="scss">
.myEventsContainer {
  padding: 0 0 2rem 0;
  transition: all 0.25s ease-in-out;
}
</style>
