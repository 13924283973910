export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "markRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark as Read"])},
        "markAllRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mark all as Read"])},
        "showNots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show notifications"])},
        "noNots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There's no new notifications"])}
      },
      "es": {
        "markRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar como leída"])},
        "markAllRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marcar todas como leídas"])},
        "showNots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostrar notificaciones"])},
        "noNots": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hay nuevas notificaciones"])}
      }
    }
  })
}
