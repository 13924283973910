import {
  defineAsyncComponent,
} from 'vue';

// Portal
const bajaParcialMatriculaPortal = defineAsyncComponent(() => import(/* webpackChunkName: "bajaParcialMatriculaPortal" */ '@/app/acade/baja-parcial-matricula/views/Portal.vue'));

// Solicitud
const bajaParcialMatriculaWizard = defineAsyncComponent(() => import(/* webpackChunkName: "bajaParcialMatriculaWizard" */ '@/app/acade/baja-parcial-matricula/views/solicitud/Wizard.vue'));
const bajaParcialMatriculaInicioSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaParcialMatriculaInicioSolicitud" */ '@/app/acade/baja-parcial-matricula/views/solicitud/Inicio.vue'));
const bajaParcialMatriculaResumenSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaParcialMatriculaResumenSolicitud" */ '@/app/acade/baja-parcial-matricula/views/solicitud/Resumen.vue'));
const bajaParcialMatriculaFinSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "bajaParcialMatriculaFinSolicitud" */ '@/app/acade/baja-parcial-matricula/views/solicitud/Fin.vue'));

export default [
  {
    path: '/bajaparcialmatricula/',
    name: 'PortalBajaParcialMatricula',
    component: bajaParcialMatriculaPortal,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'bajaparcialmatricula',
    },
  },
  {
    path: '/bajaparcialmatricula/solicitud',
    name: 'WizardBajaParcialMatricula',
    component: bajaParcialMatriculaWizard,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'bajaparcialmatricula',
    },
    redirect: '/bajaparcialmatricula/solicitud/inicio',
    children: [
      {
        path: '/bajaparcialmatricula/solicitud/inicio',
        component: bajaParcialMatriculaInicioSolicitud,
      },
      {
        path: '/bajaparcialmatricula/solicitud/resumen',
        component: bajaParcialMatriculaResumenSolicitud,
      },
      {
        path: '/bajaparcialmatricula/solicitud/fin',
        component: bajaParcialMatriculaFinSolicitud,
      },
    ],
  },
];
