import { defineAsyncComponent } from 'vue';

const AplicrauPasos = defineAsyncComponent(() => import(/* webpackChunkName: "ACADE_APLICRAU_Pasos" */'./views/Pasos.vue'));
const AplicrauPasoTitu = defineAsyncComponent(() => import(/* webpackChunkName: "ACADE_APLICRAU_PasoTitu" */'./views/PasoSeleccion.vue'));
const AplicrauPasoFirma = defineAsyncComponent(() => import(/* webpackChunkName: "ACADE_APLICRAU_PasoFirma" */'./views/PasoFirma.vue'));
const AplicrauPasoFin = defineAsyncComponent(() => import(/* webpackChunkName: "ACADE_APLICRAU_PasoFin" */'./views/PasoFin.vue'));
const ResumenInicial = defineAsyncComponent(() => import(/* webpackChunkName: "ACADE_APLICRAU_PasoFin" */'./views/ResumenInicial.vue'));

const rutaProyecto = '/mis-crau';

export default [
  {
    path: `${rutaProyecto}/inicio`,
    name: 'ACADE APLICRAU Resumen inicial',
    component: ResumenInicial,
    meta: {
      requiresAuth: true,
      application: 'mis-crau',
      group: 'acade',
    },
  },
  {
    path: `${rutaProyecto}/`,
    name: 'ACADE APLICRAU Pasos',
    component: AplicrauPasos,
    meta: {
      requiresAuth: true,
      application: 'mis-crau',
      group: 'acade',
    },
    redirect: `${rutaProyecto}/inicio`,
    children: [
      {
        path: `${rutaProyecto}/seleccion/:codTitu?`,
        name: 'ACADE APLICRAU Paso 1',
        component: AplicrauPasoTitu,
      },
      {
        path: `${rutaProyecto}/confirma`,
        name: 'ACADE APLICRAU Paso 2',
        component: AplicrauPasoFirma,
      },
      {
        path: `${rutaProyecto}/fin`,
        name: 'ACADE APLICRAU Paso 3',
        component: AplicrauPasoFin,
      },
    ],
  },
];
