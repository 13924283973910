import ServicioTrasladoExpedienteEntrada from '@/app/acade/traslado-expediente-entrada/traslado-expediente-entrada.api';
import Documentos from '@/app/acade/traslado-expediente-entrada/enum/EnumDocumentos';

export default {
  namespaced: true,
  // ESTADO
  state: {
    requisitosTramite: null,
    solicitud: {},
    estudios: {},
    universidades: null,
    titulaciones: null,
    Documentos,
    documentos: [],
    paises: null,
    // TiposEstudios,
    // Variables para universidades/titulaciones/asignaturas que no existan en la base de datos
    codigoEstudioNuevo: null,
    codigoAsignaturaNuevo: null,
    codigoUniversidadNuevo: null,

  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    SETLISTADOCUMENTOS(state, valor) {
      state.solicitud.documentos = [...valor];
    },
    GUARDARLISTADOCUMENTOSNUEVOS(state) { // valor es el origen creado
      state.documentos = [];
      if (state.solicitud.bloques) {
        state.solicitud.bloques.forEach((bloque) => {
          bloque.origenes.forEach((origen) => {
            if (origen.documentos) {
              origen.documentos.forEach((documento) => {
                // Sino existe el documento, lo creo
                let auxDocumento = null;
                if (state.documentos) {
                  auxDocumento = state.documentos
                    .find((d) => d.documento.identificador === documento.identificador);
                }
                let documentoNuevo = {};
                if (!auxDocumento) {
                  documentoNuevo.documento = documento;
                  documentoNuevo.documentosAdjuntos = []; // Arrays de descripciones
                } else { // Si el documento esta creado
                  documentoNuevo = { ...auxDocumento };
                }
                // Obtenemos la posición del objeto a guardar para comprobar si se
                // adjuntado un fichero y ponerlo.
                let indiceDocumento = null;
                if (state.solicitud.documentos) {
                  if (documento.identificador !== Documentos.DNI_NIF_PASAPORTE.identificador
                  || documento.identificador !== Documentos.JUSTIFICANTE_ADMISION.identificador) {
                    indiceDocumento = state.solicitud.documentos
                      .map((blo) => blo.documento.identificador)
                      .indexOf(documentoNuevo.documento.identificador);
                  } else {
                    indiceDocumento = state.solicitud.documentos
                      .map((blo) => blo.documento.identificador)
                      .indexOf(documento.identificador);
                  }
                }
                // Comprobamos si el codigo existe (para no añadirlo dos veces)
                let datosDocumento = {};
                if (documento.identificador
                  === Documentos.GUIA_DOCENTE.identificador) { // Si el documento es guia docente
                  origen.asignaturasOrigen.forEach((asignatura) => {
                    datosDocumento = {};
                    if (!documentoNuevo.documentosAdjuntos
                      .find((d) => d.codigoAsignatura === asignatura.asignatura.codigo
                      && origen.tipoOrigen.id === d.tipoOrigen.id
                      && d.codigoTitulacion === origen.estudio.codigo)) {
                      // Comprobamos si tiene anexo adjuntado
                      if (typeof (indiceDocumento) !== 'undefined' && indiceDocumento !== -1 && indiceDocumento !== null) {
                        const documentoAdjunto = state.solicitud.documentos[indiceDocumento]
                          .documentosAdjuntos
                          .find((el) => el.codigoAsignatura === asignatura.asignatura.codigo
                          && el.tipoOrigen.id === origen.tipoOrigen.id);
                        if (documentoAdjunto) {
                          datosDocumento.anexo = documentoAdjunto.anexo;
                          datosDocumento.enlaceGuiaAsignatura = documentoAdjunto
                            .enlaceGuiaAsignatura;
                        }
                      }
                      datosDocumento.tipoOrigen = origen.tipoOrigen;
                      datosDocumento.codigoTitulacion = origen.estudio.codigo;
                      datosDocumento.codigoAsignatura = asignatura.asignatura.codigo;
                      datosDocumento.descripcion = asignatura.asignatura.descripcion;
                      documentoNuevo.documentosAdjuntos.push(datosDocumento);
                    }
                  });
                } else if (!auxDocumento && documento.identificador
                  === Documentos.DNI_NIF_PASAPORTE.identificador) {
                  // Comprobamos si tiene anexo adjuntado
                  if (typeof (indiceDocumento) !== 'undefined' && indiceDocumento !== -1 && indiceDocumento !== null) {
                    const documentoAdjunto = state.solicitud.documentos[indiceDocumento]
                      .documentosAdjuntos
                      .find((el) => el.identificador
                        === Documentos.DNI_NIF_PASAPORTE.identificador);
                    if (documentoAdjunto) {
                      datosDocumento.anexo = documentoAdjunto.anexo;
                    }
                  }
                  datosDocumento.identificador = Documentos.DNI_NIF_PASAPORTE.identificador;
                  datosDocumento.descripcion = `${state.solicitud.datosPersonales.afiliacion.nombre} ${state.solicitud.datosPersonales.afiliacion.apellido1} ${state.solicitud.datosPersonales.afiliacion.apellido2 === null ? '' : state.solicitud.datosPersonales.afiliacion.apellido2}`;
                  documentoNuevo.documentosAdjuntos.push(datosDocumento);
                } else if (!auxDocumento && documento.identificador
                  === Documentos.JUSTIFICANTE_ADMISION.identificador) {
                  // Comprobamos si tiene anexo adjuntado
                  if (typeof (indiceDocumento) !== 'undefined' && indiceDocumento !== -1 && indiceDocumento !== null) {
                    const documentoAdjunto = state.solicitud.documentos[indiceDocumento]
                      .documentosAdjuntos
                      .find((el) => el.identificador
                        === Documentos.JUSTIFICANTE_ADMISION.identificador);
                    if (documentoAdjunto) {
                      datosDocumento.anexo = documentoAdjunto.anexo;
                    }
                  }
                  datosDocumento.identificador = Documentos.JUSTIFICANTE_ADMISION.identificador;
                  datosDocumento.descripcion = Documentos.JUSTIFICANTE_ADMISION.descripcion;
                  documentoNuevo.documentosAdjuntos.push(datosDocumento);
                } else if (!documentoNuevo.documentosAdjuntos
                  .find((d) => d.codigo === origen.estudio.codigo
                  && d.tipoOrigen.id === origen.tipoOrigen.id)
                  && documento.identificador
                  !== Documentos.DNI_NIF_PASAPORTE.identificador
                  && documento.identificador
                  !== Documentos.JUSTIFICANTE_ADMISION.identificador) {
                  // Comprobamos si tiene anexo adjuntado
                  if (typeof (indiceDocumento) !== 'undefined' && indiceDocumento !== -1 && indiceDocumento !== null) {
                    const documentoAdjunto = state.solicitud.documentos[indiceDocumento]
                      .documentosAdjuntos
                      .find((el) => el.codigo === origen.estudio.codigo
                      && el.tipoOrigen.id === origen.tipoOrigen.id);
                    if (documentoAdjunto) {
                      datosDocumento.anexo = documentoAdjunto.anexo;
                    }
                  }
                  datosDocumento.tipoOrigen = origen.tipoOrigen;
                  datosDocumento.codigo = origen.estudio.codigo;
                  datosDocumento.descripcion = origen.estudio.descripcion;
                  documentoNuevo.documentosAdjuntos.push(datosDocumento);
                }
                if (!auxDocumento) {
                  state.documentos.push(documentoNuevo);
                }
              });
            }
          });
        });
      }
      state.solicitud.documentos = [...state.documentos];
    },
    SETESTUDIOS(state, data) {
      state.estudios = data;
    },
    SETREQUISITOSTRAMITE(state, data) {
      state.requisitosTramite = data;
    },
    SETUNIVERSIDADES(state, data) {
      state.universidades = data;
    },
    SETTITULACIONES(state, data) {
      state.titulaciones = data;
    },
    SETSOLICITUD(state, valor) {
      state.solicitud = valor;
    },
    SETPAISES(state, data) {
      state.paises = data;
    },
    SETCODIGOESTUDIONUEVO(state, data) {
      state.codigoEstudioNuevo = data;
    },
    SETASIGNATURANUEVA(state, data) {
      state.codigoAsignaturaNuevo = data;
    },
    SETCODIGOUNIVERSIDADNUEVO(state, data) {
      state.codigoUniversidadNuevo = data;
    },
    AGREGARBLOQUE(state) {
      if (!state.solicitud.bloques) {
        state.solicitud.bloques = [];
      }
      const bloque = {
        codigoBloque: (state.solicitud.bloques.length !== 0)
          ? Math.max(...state.solicitud.bloques.map((b) => b.codigoBloque))
          + 1 : 1,
        origenes: [],
        asignaturasDestino: [],
      };
      state.solicitud.bloques.push(bloque);
      state.solicitud = { ...state.solicitud };
    },
    BORRARBLOQUE(state, codigoBloque) {
      const i = state.solicitud.bloques.map((bloque) => bloque.codigoBloque).indexOf(codigoBloque);
      state.solicitud.bloques.splice(i, 1);
    },
    AGREGARORIGEN(state, params) {
      // Obtenemos el indice
      const i = state.solicitud.bloques.map((blo) => blo.codigoBloque).indexOf(params.codigoBloque);
      const origenNuevo = {
        ...params.origen,
        codigoOrigen: (state.solicitud.bloques[i].origenes.length !== 0)
          ? Math.max(...state.solicitud.bloques[i].origenes.map((o) => o.codigoOrigen)) + 1 : 1,
      };
      state.solicitud.bloques[i].origenes.push(origenNuevo);
    },
    EDITARORIGEN(state, params) {
      // Obtenemos el indice del bloque
      const indiceBloque = state.solicitud.bloques.map((x) => x.codigoBloque)
        .indexOf(params.codigoBloque);
      // Obtenemos el origen
      const origen = state.solicitud.bloques[indiceBloque].origenes.findIndex(
        (el) => el.codigoOrigen === params.origen.codigoOrigen,
      );
      state.solicitud.bloques[indiceBloque].origenes.splice(origen, 1, params.origen);
    },
    BORRARORIGEN(state, params) {
      const indiceBloque = state.solicitud.bloques.map(
        (bloque) => bloque.codigoBloque,
      ).indexOf(params.codigoBloque);
      const removeIndex = state.solicitud.bloques[indiceBloque].origenes.map(
        (item) => item.codigoOrigen,
      ).indexOf(params.codigoOrigen);
      state.solicitud.bloques[indiceBloque].origenes.splice(removeIndex, 1);
    },
    EDITARASIGNATURASDESTINO(state, params) {
      const indiceDestino = state.solicitud.bloques.map((blo) => blo.codigoBloque)
        .indexOf(params.codigoBloque);
      state.solicitud.bloques[indiceDestino].asignaturasDestino = params.asignaturas;
    },
  },
  getters: {
    getSolicitud: (state) => state.solicitud,
    getBloques: (state) => state.solicitud.bloques,
    getEstudioDestino: (state) => state.solicitud.datosAcademicos.estudioDestino,
    getAsignaturasDestino: (state) => (codigoBloque) => {
      const indiceDestino = state.solicitud.bloques.map((bloque) => bloque.codigoBloque)
        .indexOf(codigoBloque);
      return state.solicitud.bloques[indiceDestino].asignaturasDestino;
    },
    getSecuenciaSolicitud: (state) => state.solicitud.secuenciaSolicitud,
  },
  actions: {
    agregarBloque({ commit, state }) {
      commit('AGREGARBLOQUE');
      ServicioTrasladoExpedienteEntrada.guardarSolicitud(state.solicitud).then(({ data }) => {
        commit('SETSOLICITUD', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    borrarBloque({ commit, state }, params) {
      commit('BORRARBLOQUE', params.codigoBloque);
      ServicioTrasladoExpedienteEntrada.guardarSolicitud(state.solicitud).then(({ data }) => {
        commit('SETSOLICITUD', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    obtenerSolicitud({ commit }) {
      return ServicioTrasladoExpedienteEntrada.obtenerSolicitud().then((response) => {
        if (response.data) {
          commit('SETSOLICITUD', response.data);
        }
      }).catch((error) => {
        throw new Error(error);
      });
    },
    obtenerUniversidadesEspañolas({ commit }) {
      return ServicioTrasladoExpedienteEntrada.obtenerUniversidadesEspañolas().then(({ data }) => {
        commit('SETUNIVERSIDADES', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    obtenerTitulacionesUniversidadesEspañolas({ commit }, params) {
      return ServicioTrasladoExpedienteEntrada.obtenerTitulacionesUniversidadesEspañolas(params)
        .then(({ data }) => {
          commit('SETTITULACIONES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    borrarBloques({ state }) {
      state.solicitud.bloques = null;
    },
    obtenerPaises({ commit }) {
      return ServicioTrasladoExpedienteEntrada.obtenerPaises()
        .then(({ data }) => {
          commit('SETPAISES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerEstudiosUniversidadesExtranjeras({ commit }, params) {
      return ServicioTrasladoExpedienteEntrada.obtenerEstudiosUniversidadesExtranjeras(params)
        .then(({ data }) => {
          commit('SETTITULACIONES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUniversidadesExtranjeras({ commit }, params) {
      return ServicioTrasladoExpedienteEntrada.obtenerUniversidadesExtranjeras(params)
        .then(({ data }) => {
          commit('SETUNIVERSIDADES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTiposAsignaturasEstudioExtranjera({ commit }) {
      return ServicioTrasladoExpedienteEntrada.obtenerTiposAsignaturasEstudioExtranjera()
        .then(({ data }) => {
          commit('SETTIPOSASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUltimaTitulacionNueva({ commit }, params) {
      return ServicioTrasladoExpedienteEntrada.obtenerUltimaTitulacionNueva(params)
        .then(({ data }) => {
          commit('SETCODIGOESTUDIONUEVO', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUltimaAsignaturaNueva({ commit }, params) {
      return ServicioTrasladoExpedienteEntrada.obtenerUltimaAsignaturaNueva(params)
        .then(({ data }) => {
          commit('SETASIGNATURANUEVA', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUltimaUniversidadNueva({ commit }, params) {
      return ServicioTrasladoExpedienteEntrada.obtenerUltimaUniversidadNueva(params)
        .then(({ data }) => {
          commit('SETCODIGOUNIVERSIDADNUEVO', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
  },
};
