export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cargando"])},
        "titlegeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te puede interesar ..."])},
        "info_tramite_sugerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información del trámite"])},
        "tramite_acceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso a la tramitación"])},
        "tramite_modos_acceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modos de acceso"])},
        "tramites_sugeridos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Según los últimos trámites que has iniciado, quizás te interese alguno de los siguientes:"])},
        "mostrar_mas_tramites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más trámites"])},
        "acceso_certificado_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado electrónico"])},
        "acceso_clave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cl", "@", "ve"])},
        "acceso_cuenta_um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta UM"])},
        "acceso_usuario_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario y contraseña"])}
      },
      "en": {
        "cargando": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading"])},
        "titlegeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Te puede interesar ..."])},
        "info_tramite_sugerido": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Información"])},
        "tramite_acceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso a la tramitación"])},
        "tramite_modos_acceso": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modos de acceso"])},
        "tramites_sugeridos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Según los últimos trámites que has iniciado, quizás te interese alguno de los siguientes:"])},
        "mostrar_mas_tramites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ver más trámites"])},
        "acceso_certificado_electronico": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificado electrónico"])},
        "acceso_clave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cl", "@", "ve"])},
        "acceso_cuenta_um": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuenta UM"])},
        "acceso_usuario_contrasena": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuario y contraseña"])}
      }
    }
  })
}
