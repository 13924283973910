import { defineAsyncComponent } from 'vue';

const CondicionesView = defineAsyncComponent(() => import(/* webpackChunkName: "CARGAVE_Condiciones" */'./views/CondicionesView.vue'));
const CargadoresView = defineAsyncComponent(() => import(/* webpackChunkName: "CARGAVE_Cargadores" */'./views/CargadoresView.vue'));

const rutaProyecto = '/transporte/carga-vehiculos';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'CARGAVE Inicio',
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'cargave',
    },
    redirect: `${rutaProyecto}/condiciones/`,
  },
  {
    path: `${rutaProyecto}/condiciones/`,
    name: 'CARGAVE Condiciones',
    component: CondicionesView,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'cargave',
    },
  },
  {
    path: `${rutaProyecto}/cargadores/`,
    name: 'CARGAVE Cargadores',
    component: CargadoresView,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'cargave',
    },
  },
];
