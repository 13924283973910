<template>
  <footer class="fwjsfooter">
    <span class="fwjsfooter__title">Universidad de Murcia - ATICA</span>
    <ul class="fwjsfooter__list">
      <li class="fwjsfooter__listitem">
        <a
          href="/accesibilidad.html"
          class="fwjsfooter_listlink"
        >
          {{ $t('accesibility') }}
        </a>
      </li>
      <li class="fwjsfooter__listitem">
        <router-link
          to="/proteccion-datos"
          class="fwjsfooter_listlink"
        >
          {{ $t('lopd') }}
        </router-link>
      </li>
      <li class="fwjsfooter__listitem">
        <router-link
          to="/cookies"
          class="fwjsfooter_listlink"
        >
          {{ $t('cookies') }}
        </router-link>
      </li>
      <li class="fwjsfooter__listitem">
        <router-link
          to="/navegacion"
          class="fwjsfooter_listlink"
        >
          {{ $t('browse') }}
        </router-link>
      </li>
      <li class="fwjsfooter__listitem">
        <a
          href="http://dj.um.es/"
          target="_blank"
          class="fwjsfooter_listlink"
        >{{ $t('incidents') }}</a>
      </li>
    </ul>
  </footer>
</template>

<i18n>
{
  "en": {
    "accesibility": "Accesibility",
    "lopd": "Data protection",
    "browse": "Navigation preferences",
    "cookies": "Cookies policy",
    "incidents": "Incidents"
  },
  "es": {
    "accesibility": "Accesibilidad",
    "lopd": "Política de privacidad",
    "browse": "Recomendaciones de navegación",
    "cookies": "Política de cookies",
    "incidents": "Incidencias"
  }
}
</i18n>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FwjsFooter',
});
</script>

<style lang="scss" scoped>

.fwjsfooter {
  color: var(--main-text-footer);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 0.75rem;
  padding: 1.5rem 1.5rem;
  @media (max-width: $screen-sm-min) {
    border-top: 2px solid #d8dde1;
    flex-direction: column-reverse;
  }
  &__list {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
  }
  &__listitem {
    display: inline-block;
    padding: 0 1rem;
    border-right: 1px solid var(--main-text-footer);
    @media (max-width: $screen-sm-min) {
      border-right: 0px none;
      display: block;
      padding: 0.5rem 0;
    }
    &:last-child {
      padding: 0 0 0 1rem;
      border-right: 0px none;
      @media (max-width: $screen-sm-min) {
        padding: 0.5rem 0;
        margin: 0 0 1rem 0;
      }
    }
  }
  &_listlink {
    color: var(--main-text-footer);
    &:hover {
      color: var(--link-color);
    }
  }
  body.with-menu & {
    @media (min-width: $screen-sm-min) {
      margin-left: var(--private-menu-size);
    }
  }
}
</style>
