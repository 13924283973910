import ServicioReconocimientoCreditos from '@/app/acade/reconocimiento-creditos/reconocimiento-creditos.api';
import Origenes from '@/app/acade/reconocimiento-creditos/enum/EnumOrigenes';
import TiposEstudios from '@/app/acade/reconocimiento-creditos/enum/EnumTipoEstudio';
import viewsMixin from '@/app/acade/reconocimiento-creditos/mixins/viewsMixin';

export default {
  namespaced: true,
  mixins: [viewsMixin],
  // ESTADO
  state: {
    solicitud: {},

    // DlgDestino
    asignaturasMat: null,

    // Variables para los DLG de origen
    titulaciones: null,
    asignaturas: null,
    universidades: null,
    tiposAsignaturas: null,
    paises: null,
    estudiosRecFP: null,
    asignaturasFP: null,

    // Variables para universidades/titulaciones/asignaturas que no existan en la base de datos
    codTituNuevo: null,
    codAsiNuevo: null,
    univCodigoNuevo: null,

    // Variables para la pantalla de documentación
    Origenes,
    TiposEstudios,

  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    SET_JSON_SOLICITUD(state, valor) {
      state.solicitud = valor;
    },
    SET_TITULACION(state, titulacion) {
      state.solicitud = { ...state.solicitud, titulacion };
    },
    AGREGAR_BLOQUE(state) {
      if (!state.solicitud.bloques) {
        state.solicitud.bloques = [];
      }
      const bloque = {
        codBloque: (state.solicitud.bloques.length !== 0)
          ? Math.max(...state.solicitud.bloques.map((b) => b.codBloque))
          + 1 : 1,
        origenes: [],
        asignaturasDestino: [],
      };
      state.solicitud.bloques.push(bloque);
      state.solicitud = { ...state.solicitud };
    },
    BORRAR_BLOQUE(state, codBloque) {
      const i = state.solicitud.bloques.map((bloque) => bloque.codBloque).indexOf(codBloque);
      state.solicitud.bloques.splice(i, 1);
      if (state.solicitud.bloques.length === 0) {
        delete state.solicitud.bloques;
      }
    },
    AGREGAR_ORIGEN(state, params) {
      // Obtenemos el indice
      const i = state.solicitud.bloques.map((blo) => blo.codBloque).indexOf(params.codBloque);
      const origenNuevo = {
        ...params.origen,
        codOrigen: (state.solicitud.bloques[i].origenes.length !== 0)
          ? Math.max(...state.solicitud.bloques[i].origenes.map((o) => o.codOrigen)) + 1 : 1,
      };
      state.solicitud.bloques[i].origenes.push(origenNuevo);
    },
    EDITAR_ORIGEN(state, params) {
      // Obtenemos el indice del bloque
      const iBloque = state.solicitud.bloques.map((x) => x.codBloque).indexOf(params.codBloque);
      // Obtenemos el origen
      const origen = state.solicitud.bloques[iBloque].origenes.findIndex(
        (el) => el.codOrigen === params.origen.codOrigen,
      );
      state.solicitud.bloques[iBloque].origenes.splice(origen, 1, params.origen);
    },
    BORRAR_ORIGEN(state, params) {
      const iBloque = state.solicitud.bloques.map(
        (bloque) => bloque.codBloque,
      ).indexOf(params.codBloque);
      const removeIndex = state.solicitud.bloques[iBloque].origenes.map(
        (item) => item.codOrigen,
      ).indexOf(params.codOrigen);
      state.solicitud.bloques[iBloque].origenes.splice(removeIndex, 1);

      // Si no hay origen UM o ESP, borramos si existe la actividad
      // seleccionada Asignatura a determinar por la comisión responsable
      const existeBloqueUMorESP = this.getters['acadeReconocimientoCreditos/existeBloqueUMorESP'](params.codBloque);
      if (!existeBloqueUMorESP) {
        const posAsiComisionResponsable = state.solicitud.bloques[iBloque].asignaturasDestino
          .findIndex((asi) => asi.codAsi === null);
        if (posAsiComisionResponsable !== -1) {
          state.solicitud.bloques[iBloque].asignaturasDestino.splice(posAsiComisionResponsable, 1);
        }
      }
    },
    BORRAR_DESTINO_FP(state, codBloque) {
      const i = state.solicitud.bloques.map((blo) => blo.codBloque).indexOf(codBloque);
      state.solicitud.bloques[i].asignaturasDestino = [];
    },
    EDITAR_ASIGNATURA_DESTINO(state, params) {
      const i = state.solicitud.bloques.map((blo) => blo.codBloque).indexOf(params.codBloque);
      state.solicitud.bloques[i].asignaturasDestino = params.asignaturas;
    },
    SET_ASIGNATURAS_MAT(state, data) {
      state.asignaturasMat = data;
    },
    /** Variables DLG Origen */
    SET_TITULACIONES(state, data) {
      state.titulaciones = data;
    },
    SET_ASIGNATURAS(state, data) {
      state.asignaturas = data;
    },
    SET_UNIVERSIDADES(state, data) {
      state.universidades = data;
    },
    SET_TIPOS_ASIGNATURAS(state, data) {
      state.tiposAsignaturas = data;
    },
    SET_COD_TITU_NUEVO(state, data) {
      state.codTituNuevo = data;
    },
    SET_ASI_NUEVO(state, data) {
      state.codAsiNuevo = data;
    },
    SET_PAISES(state, data) {
      state.paises = data;
    },
    SET_UNIV_CODIGO_NUEVO(state, data) {
      state.univCodigoNuevo = data;
    },
    SET_ESTUDIOS_RECONOCIMIENTOS_FP(state, data) {
      state.estudiosRecFP = data;
    },
    SET_ASIGNATURAS_FP(state, data) {
      state.asignaturasFP = data;
    },
  },
  getters: {
    getJsonSolicitud: (state) => state.solicitud,
    getBloques: (state) => state.solicitud.bloques,
    getTitulacion: (state) => state.solicitud.titulacion,
    getSeqSolicitud: (state) => state.solicitud.seqSolicitud,
    getTransferenciaCreditos: (state) => state.solicitud.transferenciaCreditos,
    getIncorporacionExpeCreditos: (state) => state.solicitud.incorporacionExpeCreditos,
    getOrigenFP: (state) => (codBloque) => {
      const i = state.solicitud.bloques.map((bloque) => bloque.codBloque).indexOf(codBloque);
      let isFP = false;
      state.solicitud.bloques[i].origenes.forEach((element) => {
        if (element.tipoOrigen === 6) {
          isFP = true;
        } else {
          isFP = false;
        }
      });
      return isFP;
    },
    getOrigenes: (state) => (codBloque) => {
      const i = state.solicitud.bloques.map((bloque) => bloque.codBloque).indexOf(codBloque);
      return state.solicitud.bloques[i].origenes;
    },
    getAsignaturasDestino: (state) => (codBloque) => {
      const i = state.solicitud.bloques.map((bloque) => bloque.codBloque).indexOf(codBloque);
      return state.solicitud.bloques[i].asignaturasDestino;
    },
    getCodTipoTitulacion: (state) => {
      if (state.solicitud.titulacion
        && state.solicitud.titulacion.tipoEstudio === TiposEstudios.GRADO) {
        return 'G';
      }
      if (state.solicitud.titulacion
        && state.solicitud.titulacion.tipoEstudio === TiposEstudios.MASTER) {
        return 'M';
      }
      return '';
    },
    existeEstudioFP: (state) => (estudioFPSeleccionada, codBloque) => {
      let existeEstudioFP = false;
      if (estudioFPSeleccionada) {
        const iBloque = state.solicitud.bloques.map(
          (bloque) => bloque.codBloque,
        ).indexOf(codBloque);
        state.solicitud.bloques[iBloque].origenes.forEach((origen) => {
          if (origen.tipoOrigen === Origenes.ORIGEN_FP
            && origen.codTitu === estudioFPSeleccionada.idEstudio) {
            existeEstudioFP = true;
          }
        });
      }
      return existeEstudioFP;
    },
    existeBloqueUMorESP: (state) => (codBloque) => {
      let existeBloqueUMorESP = false;
      const iBloque = state.solicitud.bloques.map(
        (bloque) => bloque.codBloque,
      ).indexOf(codBloque);
      state.solicitud.bloques[iBloque].origenes.forEach((origen) => {
        if (origen.tipoOrigen === Origenes.ORIGEN_UMU
          || origen.tipoOrigen === Origenes.ORIGEN_UNIVERSIDAD_ESPAÑOLA) {
          existeBloqueUMorESP = true;
        }
      });
      return existeBloqueUMorESP;
    },
  },
  actions: {
    obtenerSolicitud({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerSolicitud(params).then(({ data }) => {
        if (data !== '') {
          commit('SET_JSON_SOLICITUD', data);
        }
      }).catch((error) => {
        throw new Error(error);
      });
    },
    obtenerUltimaTitulacionNueva({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerUltimaTitulacionNueva(params)
        .then(({ data }) => {
          commit('setCodTituNuevo', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUltimaAsignaturaNueva({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerUltimaAsignaturaNueva(params)
        .then(({ data }) => {
          commit('SET_ASI_NUEVO', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUltimaUniversidadNueva({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerUltimaUniversidadNueva(params)
        .then(({ data }) => {
          commit('SET_ASI_NUEVO', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    agregarBloque({ commit, state }) {
      commit('AGREGAR_BLOQUE');
      ServicioReconocimientoCreditos.guardarSolicitud(state.solicitud).then(({ data }) => {
        commit('SET_JSON_SOLICITUD', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    borrarBloque({ commit, state }, params) {
      commit('BORRAR_BLOQUE', params.codBloque);
      ServicioReconocimientoCreditos.guardarSolicitud(state.solicitud).then(({ data }) => {
        commit('SET_JSON_SOLICITUD', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    borrarBloques({ state }) {
      state.solicitud.bloques = null;
      ServicioReconocimientoCreditos.borrarSolicitud(state.solicitud.seqSolicitud)
        .catch((error) => {
          throw new Error(error);
        });
    },
    obtenerAsignaturasMatriculadasEstudiante({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasMatriculadasEstudiante(params)
        .then(({ data }) => {
          commit('SET_ASIGNATURAS_MAT', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTitulacionesUmuCursadas({ commit }) {
      return ServicioReconocimientoCreditos.obtenerTitulacionesUmuCursadas().then(({ data }) => {
        commit('SET_TITULACIONES', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    obtenerAsignaturasTitulacionUmuCursadas({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasTitulacionUmuCursadas(params)
        .then(({ data }) => {
          commit('SET_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerUniversidadesEspañolas({ commit }) {
      return ServicioReconocimientoCreditos.obtenerUniversidadesEspañolas().then(({ data }) => {
        commit('SET_UNIVERSIDADES', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
    obtenerUniversidadesExtranjeras({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerUniversidadesExtranjeras(params)
        .then(({ data }) => {
          commit('SET_UNIVERSIDADES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTitulacionesUniversidadExtranjera({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerTitulacionesUniversidadExtranjera(params)
        .then(({ data }) => {
          commit('SET_TITULACIONES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerAsignaturasTitulacionExtranjera({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasTitulacionExtranjera(params)
        .then(({ data }) => {
          commit('SET_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTiposAsignaturasUniversidadesEspañolas({ commit }) {
      return ServicioReconocimientoCreditos.obtenerTiposAsignaturasUniversidadesEspañolas()
        .then(({ data }) => {
          commit('SET_TIPOS_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTitulacionesUniversidadesEspañolas({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerTitulacionesUniversidadesEspañolas(params)
        .then(({ data }) => {
          commit('SET_TITULACIONES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerAsignaturasUniversidadesEspañolas({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasUniversidadesEspañolas(params)
        .then(({ data }) => {
          commit('SET_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTitulacionesEstudiosPropiosUM({ commit }) {
      return ServicioReconocimientoCreditos.obtenerTitulacionesEstudiosPropiosUM()
        .then(({ data }) => {
          commit('SET_TITULACIONES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerAsignaturasEstudiosPropiosUM({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasEstudiosPropiosUM(params)
        .then(({ data }) => {
          commit('SET_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTitulacionesEstudiosPropiosNoUM({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerTitulacionesEstudiosPropiosNoUM(params)
        .then(({ data }) => {
          commit('SET_TITULACIONES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerAsignaturasEstudiosPropiosNoUM({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasEstudiosPropiosNoUM(params)
        .then(({ data }) => {
          commit('SET_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerPaises({ commit }) {
      return ServicioReconocimientoCreditos.obtenerPaises()
        .then(({ data }) => {
          commit('SET_PAISES', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerTiposAsignaturasUniversidadesExtranjeras({ commit }) {
      return ServicioReconocimientoCreditos.obtenerTiposAsignaturasUniversidadesExtranjeras()
        .then(({ data }) => {
          commit('SET_TIPOS_ASIGNATURAS', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerEstudiosReconocimientoFP({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerEstudiosReconocimientoFP(params)
        .then(({ data }) => {
          commit('SET_ESTUDIOS_RECONOCIMIENTOS_FP', data);
        }).catch((error) => {
          throw new Error(error);
        });
    },
    obtenerAsignaturasEstudioFP({ commit }, params) {
      return ServicioReconocimientoCreditos.obtenerAsignaturasEstudioFP(params).then(({ data }) => {
        commit('SET_ASIGNATURAS_FP', data);
      }).catch((error) => {
        throw new Error(error);
      });
    },
  },
};
