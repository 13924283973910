import { defineAsyncComponent } from 'vue';

// Tramite Compensación

const compensacionPortal = defineAsyncComponent(() => import(/* webpackChunkName: "compensacionPortal" */ '@/app/acade/compensacion/views/Portal.vue'));
const compensacionInicio = defineAsyncComponent(() => import(/* webpackChunkName: "compensacionFormularioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/Inicio.vue'));
const compensacionInicioSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "compensacionInicioSolicitud" */ '@/app/acade/shared/formularioDinamico/views/InicioGenerico.vue'));
const compensacionResumenSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "compensacionResumenSolicitud" */ '@/app/acade/shared/formularioDinamico/views/ResumenGenerico.vue'));
const compensacionFinSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "compensacionFinSolicitud" */ '@/app/acade/shared/formularioDinamico/views/FinGenerico.vue'));

export default [
  {
    path: '/compensacion',
    name: 'compensacionInicio',
    component: compensacionInicio,
    meta: {
      title: 'compensacion',
      requiresAuth: true,
    },
  },
  {
    path: '/compensacion/solicitud/portal',
    name: 'compensacionPortal',
    component: compensacionPortal,
  },
  {
    path: '/compensacion',
    name: 'compensacionFormularioSolicitud',
    component: compensacionInicioSolicitud,
    meta: {
      title: 'compensacion',
      requiresAuth: true,
    },
    redirect: '/compensacion/solicitud/inicio',
    children: [
      {
        path: '/compensacion/solicitud/inicio',
        component: compensacionInicioSolicitud,
      },
      {
        path: '/compensacion/solicitud/resumen',
        component: compensacionResumenSolicitud,
      },
      {
        path: '/compensacion/solicitud/fin',
        component: compensacionFinSolicitud,
      },
    ],
  },
];
