export default {
  namespaced: true,
  // ESTADO
  state: {
    docResguardo: null,
    jsonSolicitud: {},
    jsonSubsanacion: {},
    horaGuardado: null,
    horaGuardadoSubsanacion: null,
    formExpediente: null,
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    setDocResguardo(state, valor) {
      state.docResguardo = valor;
    },
    setJsonSolicitud(state, valor) {
      state.jsonSolicitud = valor;
    },
    setJsonSubsanacion(state, valor) {
      state.jsonSubsanacion = valor;
    },
    setHoraGuardado(state, valor) {
      state.horaGuardado = valor;
    },
    setHoraGuardadoSubsanacion(state, valor) {
      state.horaGuardadoSubsanacion = valor;
    },
    setFormExpediente(state, valor) {
      state.formExpediente = valor;
    },
  },
};
