import { mapGetters } from 'vuex';
import ServicioReconocimientoCreditos from '@/app/acade/reconocimiento-creditos/reconocimiento-creditos.api';
import Origenes from '@/app/acade/reconocimiento-creditos/enum/EnumOrigenes';
import EnumCodigosTramite from '@/app/acade/shared/enums/EnumCodigosTramite';
import DeclaracionResponsable from '@/app/acade/shared/components/DeclaracionResponsable.vue';
import Documentos from '@/app/acade/reconocimiento-creditos/enum/EnumDocumentos';

const viewsMixin = {

  computed: {
    ...mapGetters('acadeReconocimientoCreditos', ['getJsonSolicitud', 'getTitulacion']),
  },

  components: {
    DeclaracionResponsable,
  },

  data() {
    return {
      Origenes,
      checkDeclaracionResponsable: false,
      declaracionResponsable: false,
      EnumCodigosTramite,
      DeclaracionResponsable,
    };
  },
  methods: {
    obtenerDocumentos() {
      const documentos = [];
      if (this.getJsonSolicitud.bloques) {
        this.getJsonSolicitud.bloques.forEach((bloque) => {
          bloque.origenes.forEach((origen) => {
            if (origen.documentos) {
              origen.documentos.forEach((doc) => {
                // Sino existe el documento, lo creo
                let auxDoc = null;
                if (documentos) {
                  auxDoc = documentos.find((d) => d.idDocumento === doc);
                }
                let documentoNuevo = {};
                if (!auxDoc) {
                  documentoNuevo.idDocumento = doc;
                  documentoNuevo.docsAdj = []; // Arrays de descripciones
                } else { // Si el documento esta creado
                  documentoNuevo = { ...auxDoc };
                }
                // Obtenemos la posición del objeto a guardar para comprobar si se
                // adjuntado un fichero y ponerlo.
                let posDocumento = null;
                if (this.getJsonSolicitud.documentos) {
                  posDocumento = this.getJsonSolicitud
                    .documentos.map((blo) => blo.idDocumento)
                    .indexOf(documentoNuevo.idDocumento);
                }
                // Comprobamos si el codigo existe (para no añadirlo dos veces)
                let datosDocumentoNuevo = {};
                // Si el origen es de experiencia profesional
                if (origen.experienciaProfesional) {
                  origen.experienciaProfesional.forEach((expe) => {
                    datosDocumentoNuevo = {};
                    // Si se ha insertado varias veces la experiencia laboral,
                    // solo se pide una vez el informe de vida laboral.
                    if ((documentoNuevo.idDocumento === Documentos.VIDA_LABORAL
                      && documentoNuevo.docsAdj.length === 0)
                      || documentoNuevo.idDocumento === Documentos.MEMORIA_EMPRESA
                      || documentoNuevo.idDocumento === Documentos.CONTRATO_TRABAJO) {
                      if (!documentoNuevo.docsAdj.find((d) => d.orden === expe.orden)) {
                        // Comprobamos si tiene anexo adjuntado
                        if (typeof (posDocumento) !== 'undefined' && posDocumento !== -1 && posDocumento !== null) {
                          const documento = this.getJsonSolicitud.documentos[posDocumento].docsAdj
                            .find((el) => el.codigo === expe.orden
                            && el.descripcion === expe.empresa);
                          if (documento) {
                            datosDocumentoNuevo.anexo = documento.anexo;
                          }
                        }
                        datosDocumentoNuevo.codigo = expe.orden;
                        datosDocumentoNuevo.tipoOrigen = origen.tipoOrigen;
                        datosDocumentoNuevo.descripcion = expe.empresa;
                        documentoNuevo.docsAdj.push(datosDocumentoNuevo);
                      }
                    }
                  });
                } else if (origen.tipoOrigen === Origenes.ORIGEN_ENSEÑANZAS_SUPERIORES) {
                  origen.enseñanzasArtisticas.forEach((enseñanza) => {
                    datosDocumentoNuevo = {};
                    if (!documentoNuevo.docsAdj.find((d) => d.orden === enseñanza.orden)) {
                      // Comprobamos si tiene anexo adjuntado
                      if (typeof (posDocumento) !== 'undefined' && posDocumento !== -1 && posDocumento !== null) {
                        const documento = this.getJsonSolicitud.documentos[posDocumento].docsAdj
                          .find((el) => el.codigo === enseñanza.orden
                          && el.descripcion === enseñanza.titulacion);
                        if (documento) {
                          datosDocumentoNuevo.anexo = documento.anexo;
                        }
                      }
                      datosDocumentoNuevo.codigo = enseñanza.orden;
                      datosDocumentoNuevo.tipoOrigen = origen.tipoOrigen;
                      datosDocumentoNuevo.descripcion = enseñanza.titulacion;
                      documentoNuevo.docsAdj.push(datosDocumentoNuevo);
                    }
                  });
                } else if (doc === Documentos.GUIA_DOCENTE) { // Si el documento es guia docente
                  origen.asignaturasOrigen.forEach((asi) => {
                    datosDocumentoNuevo = {};
                    if (!documentoNuevo.docsAdj.find((d) => d.codigo === asi.asignatura.codAsi
                      && origen.tipoOrigen === d.tipoOrigen && d.codTitu === origen.codTitu)) {
                      // Comprobamos si tiene anexo adjuntado
                      if (typeof (posDocumento) !== 'undefined' && posDocumento !== -1 && posDocumento !== null) {
                        const documento = this.getJsonSolicitud.documentos[posDocumento].docsAdj
                          .find((el) => el.codigo === asi.asignatura.codAsi
                          && el.tipoOrigen === origen.tipoOrigen);
                        if (documento) {
                          datosDocumentoNuevo.anexo = documento.anexo;
                          datosDocumentoNuevo.enlaceGuiaAsignatura = documento.enlaceGuiaAsignatura;
                        }
                      }
                      datosDocumentoNuevo.tipoOrigen = origen.tipoOrigen;
                      datosDocumentoNuevo.codTitu = origen.codTitu;
                      datosDocumentoNuevo.codigo = asi.asignatura.codAsi;
                      datosDocumentoNuevo.descripcion = asi.asignatura.desAsi;
                      documentoNuevo.docsAdj.push(datosDocumentoNuevo);
                    }
                  });
                } else if (!documentoNuevo.docsAdj.find((d) => d.codigo === origen.codTitu
                  && d.tipoOrigen === origen.tipoOrigen)) {
                  // Comprobamos si tiene anexo adjuntado
                  if (typeof (posDocumento) !== 'undefined' && posDocumento !== -1 && posDocumento !== null) {
                    const documento = this.getJsonSolicitud.documentos[posDocumento].docsAdj
                      .find((el) => el.codigo === origen.codTitu
                      && el.tipoOrigen === origen.tipoOrigen);
                    if (documento) {
                      datosDocumentoNuevo.anexo = documento.anexo;
                    }
                  }
                  datosDocumentoNuevo.tipoOrigen = origen.tipoOrigen;
                  datosDocumentoNuevo.codigo = origen.codTitu;
                  datosDocumentoNuevo.descripcion = origen.desTitu;
                  documentoNuevo.docsAdj.push(datosDocumentoNuevo);
                }
                if (!auxDoc) {
                  documentos.push(documentoNuevo);
                }
              });
            }
          });
        });
      }
      if (this.getJsonSolicitud.transferenciaCreditos.asignaturas
        && this.getJsonSolicitud.transferenciaCreditos.asignaturas.length !== 0) {
        let posDocumento = documentos.map((blo) => blo.idDocumento)
          .indexOf(1);
        // Sino existe el documento creado
        if (posDocumento === -1) {
          const documentoNuevo = {};
          documentoNuevo.idDocumento = 1;
          documentoNuevo.docsAdj = []; // Arrays de descripciones
          documentos.push(documentoNuevo);
          posDocumento = 0;
        }
        let codigo = 0;
        this.getJsonSolicitud.transferenciaCreditos.asignaturas.forEach((asignatura) => {
          codigo += 1;
          const datosDocumentoNuevo = {};
          datosDocumentoNuevo.codigo = codigo;
          datosDocumentoNuevo.descripcion = asignatura.asignatura.titulacion;
          documentos[posDocumento].docsAdj.push(datosDocumentoNuevo);
        });
      }
      this.getJsonSolicitud.documentos = [...documentos];
    },
    formateaCampoReconocida(slotProps) {
      if (slotProps) {
        // Para el caso origen ESP
        if (slotProps.asignatura.reconocida === true) {
          return this.$t('SI');
        }
        if (this.origen && this.origen.asignaturasSelRec) {
          // Para el caso origen UMU
          const auxAsi = this.origen.asignaturasSelRec
            .find((asi) => asi.codAsi === slotProps.asignatura.codAsi);
          if (auxAsi) {
            return this.$t('SI');
          }
        }
      }
      return '';
    },
    ventanaModalDeclaracionResponsable() {
      this.declaracionResponsable = !this.declaracionResponsable;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    obtenerTitulacionYSolicitud() {
      if (!this.getJsonSolicitud.seqSolicitud || this.titulaciones.length === 0) {
        this.loading = true;
        ServicioReconocimientoCreditos.obtenerTitulaciones()
          .then((response) => {
            if (response.data && response.data.length > 0) {
              this.titulaciones = response.data;
              if (response.data.length === 1) {
                const [titulacionSeleccionada] = response.data;
                this.$store.commit('acadeReconocimientoCreditos/SET_TITULACION', titulacionSeleccionada);
                this.$store.dispatch('acadeReconocimientoCreditos/obtenerSolicitud', titulacionSeleccionada).then(() => {
                  if (this.getJsonSolicitud.incorporacionExpeCreditos === 'SI') {
                    this.incorporacionExpedienteCreditos = true;
                  }
                  if (!this.getJsonSolicitud.transferenciaCreditos) {
                    this.getJsonSolicitud.transferenciaCreditos = {};
                  }
                });
              } else {
                // Si hay más de 1, miramos si hay solicitud y la cargamos
                response.data.forEach((titulacion) => {
                  this.$store.dispatch('acadeReconocimientoCreditos/obtenerSolicitud', titulacion).then(() => {
                    if (this.getJsonSolicitud.incorporacionExpeCreditos === 'SI') {
                      this.incorporacionExpedienteCreditos = true;
                    }
                    if (!this.getJsonSolicitud.transferenciaCreditos) {
                      this.getJsonSolicitud.transferenciaCreditos = {};
                    }
                  });
                });
              }
              this.loading = false;
            } else {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: this.$t('error'),
                detail: this.$t('noEstudios'),
                life: 5000,
              });
            }
          })
          .catch(() => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: this.$t('error'),
              detail: this.$t('errorObtencionTitulaciones'),
              life: 5000,
            });
          });
      }
    },
  },
};

export { viewsMixin as default };
