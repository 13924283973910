export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "accesibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesibility"])},
        "lopd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data protection"])},
        "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigation preferences"])},
        "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies policy"])},
        "incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidents"])}
      },
      "es": {
        "accesibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accesibilidad"])},
        "lopd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privacidad"])},
        "browse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recomendaciones de navegación"])},
        "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de cookies"])},
        "incidents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incidencias"])}
      }
    }
  })
}
