export default {
  CERTIFICACION_ACADEMICA_OFICIAL: {
    identificador: 1,
    nomenclatura: 'CERT-',
  },
  PLAN_ESTUDIOS: {
    identificador: 2,
    nomenclatura: 'PLAN-',
  },
  GUIA_DOCENTE: {
    identificador: 3,
    nomenclatura: 'GUIA-',
  },
  DOCUMENTO_OFICIAL_ACREDITACION_UNIVERSIDAD_ORIGEN: {
    identificador: 8,
    nomenclatura: 'ESUP-',
  },
  EQUIVALENCIA_NOTAS: {
    identificador: 9,
    nomenclatura: 'EQUI-',
  },
  DOCUMENTO_TRADUCCION: {
    identificador: 10,
    nomenclatura: 'TRAD-',
  },
  DNI_NIF_PASAPORTE: {
    identificador: 11,
    nomenclatura: 'IDEN-',
  },
  JUSTIFICANTE_ADMISION: {
    identificador: 12,
    nomenclatura: 'JUST-',
    descripcion: 'JUSTIFICANTE ADMISION',
  },
};
