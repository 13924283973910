export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your language"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
        "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My preferences"])}
      },
      "es": {
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder"])},
        "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acciones"])},
        "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elige tu idioma"])},
        "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salir"])},
        "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi perfil"])},
        "preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mis preferencias"])}
      }
    }
  })
}
