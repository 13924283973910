<template>
  <div
    class="cardGroup"
    v-if="services"
  >
    <CardGroupCarousel
      v-if="isCarousel"
      @carouselView="carouselView"
      :services="filteredServices()"
      :tidy="tidy"
      :small-cards="smallCards || null"
      :options="options"
      @pageActiveChange="getPage"
      @carouselFilter="filterCategory"
    />
    <CardGroupOpened
      v-else
      @carouselView="carouselView"
      :service-list="filteredServices()"
      :tidy="tidy"
      :small-cards="smallCards || null"
      :options="options"
      @pageActiveChange="getPage"
      @carouselFilter="filterCategory"
    />
    <fwjs-message
      v-if="filteredServices().length === 0"
      :text="errorMsg.textMsg"
      :category="errorMsg.categoryMsg"
      class="cardGroup__message"
    />
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import fwjsMessage from '@/app/shared/components/fwjsMessage.vue';

import CardGroupCarousel from './CardGroupCarousel.vue';
import CardGroupOpened from './CardGroupOpened.vue';

export default defineComponent({
  name: 'CardGroup',
  emits: ['pageActiveChange'],
  components: {
    CardGroupOpened,
    CardGroupCarousel,
    fwjsMessage,
  },
  props: {
    sliderKey: { type: String, required: true },
    services: { type: Array, required: true },
    viewType: { type: String, required: true },
    tidy: { type: Boolean, required: true },
    smallCards: { type: Boolean, required: false, default: false },
    options: { type: Object, required: true },
  },
  data() {
    return {
      filteredCategory: '',
      errorMsg: {
        textMsg: this.$t('noitems'),
        categoryMsg: 'info',
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
    isCarousel() {
      return this.viewType === 'carousel'
        && this.services.length > 4
        && !this.tidy;
    },
  },
  methods: {
    filteredServices() {
      const filterApply = `${this.filteredCategory}`;
      return this.filteredCategory === ''
        ? this.services
        : this.services.filter(
          (e) => ((e.category[0] ? e.category[0].identifier : '') === filterApply),
        );
    },
    carouselView(value) {
      this.$store.dispatch('services/setIsCarousel',
        { sliderKey: this.sliderKey, viewType: value, logged: this.isLogged });
    },
    getPage(value) {
      this.$emit('pageActiveChange', value);
    },
    filterCategory(catId) {
      this.filteredCategory = catId;
      window.sessionStorage.setItem(`ORDER_${this.sliderKey}`, catId);
    },
  },
});
</script>

<i18n>
{
  "en": {
    "noitems": "No items found"
  },
  "es": {
    "noitems": "No hay servicios para estas condiciones"
  }
}
</i18n>

<style lang="scss" scoped>

  .cardGroup {
    max-width: var(--grid-width);
    margin: 0 auto;
    position: relative;

    &__title {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 3rem;
      margin: 0 0 0.7rem 0;
      padding: 0 0 0.5rem 6px;
      border-bottom: 1px solid var(--border-color);
      @media (max-width: $screen-sm-min) {
        font-size: 1.1rem;
      }
    }

    &__message {
      position: absolute;
      top: 30%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -30%);
    }
  }
</style>
