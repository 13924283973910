/* Needed to disable for Vuex compatibility */
/* eslint-disable no-param-reassign */
import sharedApi from '../../shared.api';
import { dateToInt as dti } from '../../utils/utils';

async function getNotificationes(page, notificaciones = []) {
  const resp = await sharedApi.notifications('NL', page, 50);
  const notis = await resp.data;
  notificaciones = notificaciones.concat(notis.datos);
  return notificaciones;
}

export default {
  namespaced: true,
  state: {
    notifications: [],
  },
  mutations: {
    SET_NOTIFICATIONS(state, notis) {
      state.notifications = notis;
    },
    MARK_AS_READ_NOTIFICATION(state, payload) {
      const i = state.notifications.map((item) => item.codigo_alerta).indexOf(payload);
      state.notifications.splice(i, 1);
    },
    MARK_ALL_AS_READ_NOTIFICATION(state) {
      state.notifications = [];
    },
  },
  actions: {
    getNotifications({ commit }) {
      getNotificationes(0)
        .then((not) => {
          commit('SET_NOTIFICATIONS', not.sort((a, b) => dti(b.fecha_envio) - dti(a.fecha_envio)));
        });
    },
    markNotificationAsRead({ commit }, idNotification) {
      sharedApi.markAsReadNotification(idNotification)
        .then(() => {
          commit('MARK_AS_READ_NOTIFICATION', idNotification);
        })
        .catch((error) => {
          commit('API_DATA_FAILURE', error.response.data);
        });
    },
    markAllNotificationAsRead({ commit }, arraynotifications) {
      sharedApi.markAllAsReadNotification(arraynotifications)
        .then(() => {
          commit('MARK_ALL_AS_READ_NOTIFICATION', arraynotifications);
        });
    },
  },
};
