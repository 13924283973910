<template>
  <aside class="fwjsCookies" :class="className" v-if="showing">
    <span>
      <h1 class="fwjsCookies__title">{{ $t(i18nKey('esenciales')) }}</h1>
      <p>
        {{ $t(i18nKey('textoesenciales')) }}
        <router-link to="/cookies">{{ $t(i18nKey('linkesenciales')) }}</router-link>
      </p>
      <p v-if="!onlyEssentials">
        <button class="pose__button button__secondary"
          @click="acceptEssentials"
          @keyup.enter="acceptEssentials"
          @keyup.space="acceptEssentials"
        >
          {{ $t('buttonesenciales') }}
        </button>
        <button class="pose__button"
          @click="acceptAll"
          @keyup.enter="acceptAll"
          @keyup.space="acceptAll"

        >
          {{ $t('buttonaccept') }}
        </button>
      </p>
      <p v-else>
        <button class="pose__button"
          @click="acceptEssentials"
          @keyup.enter="acceptEssentials"
          @keyup.space="acceptEssentials"
        >
          {{ $t('buttonaccept') }}
        </button>
      </p>
    </span>
  </aside>
</template>

<script>
import { defineComponent } from 'vue';
import cookiesFn from '../utils/cookies3rdParty';
import modalsMixin from '../mixins/modalsMixin';

export default defineComponent({
  name: 'fwjsCookies',
  mixins: [modalsMixin],
  props: {
    onlyEssentials: { type: Boolean, default: true },
    growl: { type: Boolean, default: true },
  },
  data() {
    return {
      hasAcceptedCookies: '',
      modal: {
        name: 'cookies',
        priority: 1,
      },
    };
  },
  computed: {
    className() {
      return this.growl ? 'is-growl' : '';
    },
    shouldShow() {
      return this.hasAcceptedCookies === '';
    },
  },
  methods: {
    i18nKey(key) {
      return this.onlyEssentials ? key : `${key}terceros`;
    },
    acceptAll() {
      window.localStorage.setItem('posecookies', 'all');
      this.hasAcceptedCookies = 'all';
      cookiesFn();
      this.closeModal();
    },
    acceptEssentials() {
      window.localStorage.setItem('posecookies', 'onlyessentials');
      this.hasAcceptedCookies = 'onlyessentials';
      this.closeModal();
    },
  },
  mounted() {
    const hasAcceptedCookies = window.localStorage.getItem('posecookies');
    this.hasAcceptedCookies = hasAcceptedCookies || '';
    this.tryShowModal(this.shouldShow, true);
  },
});
</script>

<i18n src="../locales/cookies.json"></i18n>

<style lang="scss">
.fwjsCookies {
  background-color: var(--main-bg-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--shadowLevel-2);
  font-size: 0.875rem;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  left: 1rem;
  z-index: 99;
  padding: 1rem 1rem 0 1rem;

  &__title {
    line-height: 1.25;
    font-weight: 600;
    font-size: 1.25rem;
  }

  &.is-growl {
    @media (min-width: $screen-sm-min) {
      left: 40vw;
    }
  }
}
</style>
