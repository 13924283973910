import {
  apiRequest,
} from '@/api';

const moduleBaseApiURL = '/acade/reconocimientocreditos-api/v1.0/private';
const moduleBaseApiURLAcadeApiTramite = '/acade/acade-tramites-api/v1.0/private';

const endpoints = {

  /** Servicios Rest Solicitud */
  obtenerSolicitud: `${moduleBaseApiURL}/solicitudes`,
  guardarSolicitud: `${moduleBaseApiURL}/solicitudes`,
  guardarTitulacionNueva: `${moduleBaseApiURL}/estudios`,
  guardarUniversidadNueva: `${moduleBaseApiURL}/universidades`,
  guardarAsignaturasNuevas: `${moduleBaseApiURL}/asignaturas`,
  obtenerUltimaTitulacionNueva: `${moduleBaseApiURL}/estudios`,
  obtenerUltimaAsignaturaNueva: `${moduleBaseApiURL}/asignaturas`,
  obtenerUltimaUniversidadNueva: `${moduleBaseApiURL}/universidades`,
  guardarSesion: `${moduleBaseApiURL}/sesiones`,
  obtenerSesion: `${moduleBaseApiURL}/sesiones`,
  borrarSolicitud: `${moduleBaseApiURL}/solicitudes`,
  obtenerAsignaturasMatriculadasEstudiante: `${moduleBaseApiURL}/estudiantes/estudios/asignaturas`,
  obtenerTitulaciones: `${moduleBaseApiURL}/estudiantes/estudios`,
  obtenerCursoAcademico: `${moduleBaseApiURL}/cursosacademicos`,
  subirArchivo: `${moduleBaseApiURL}/solicitudes/documentos`,
  obtenerDocumento: `${moduleBaseApiURL}/solicitudes/documentos`,
  borrarDocumento: `${moduleBaseApiURL}/solicitudes/documentos`,
  requisitiosTramite: `${moduleBaseApiURLAcadeApiTramite}/tramites/requisitos`,

  /** Origen UMU */
  obtenerTitulacionesUmuCursadas: `${moduleBaseApiURL}/estudioscursados/`,
  obtenerAsignaturasTitulacionUmuCursadas: `${moduleBaseApiURL}/estudioscursados/asignaturas`,

  /** Origen Universidades españolas no UMu */
  obtenerUniversidadesEspañolas: `${moduleBaseApiURL}/universidadesespanolas`,
  obtenerTiposAsignaturasUniversidadesEspañolas: `${moduleBaseApiURL}/universidadesespanolas/tiposasignaturas`,
  obtenerTitulacionesUniversidadesEspañolas: `${moduleBaseApiURL}/universidadesespanolas/titulaciones`,
  obtenerAsignaturasUniversidadesEspañolas: `${moduleBaseApiURL}/universidadesespanolas/titulaciones/asignaturas`,

  /** Origen Estudios Propios UMU */
  obtenerTitulacionesEstudiosPropiosUM: `${moduleBaseApiURL}/estudiospropios/titulaciones`,
  obtenerAsignaturasEstudiosPropiosUM: `${moduleBaseApiURL}/estudiospropios/titulaciones/asignaturas`,

  /** Origen Estudios Propios No UM */
  obtenerTitulacionesEstudiosPropiosNoUM: `${moduleBaseApiURL}/estudiospropiosespañolas/universidades/titulaciones`,
  obtenerAsignaturasEstudiosPropiosNoUM: `${moduleBaseApiURL}/estudiospropiosespañolas/universidades/titulaciones/asignaturas`,

  /** Origen Universidades Extranjeras */
  obtenerUniversidadesExtranjeras: `${moduleBaseApiURL}/universidadesextranjeras`,
  obtenerTitulacionesUniversidadExtranjera: `${moduleBaseApiURL}/universidadesextranjeras/titulaciones`,
  obtenerAsignaturasTitulacionExtranjera: `${moduleBaseApiURL}/universidadesextranjeras/titulaciones/asignaturas`,
  obtenerPaises: `${moduleBaseApiURL}/paises`,
  obtenerTiposAsignaturasUniversidadesExtranjeras: `${moduleBaseApiURL}/universidadesextranjeras/tiposasignaturas`,

  /** Origen FP */
  obtenerEstudiosReconocimientoFP: `${moduleBaseApiURL}/estudiosoficialessuperiores/estudios`,
  obtenerAsignaturasEstudioFP: `${moduleBaseApiURL}/estudiosoficialessuperiores/estudios/asignaturas`,

  /** Firma */
  iniciaTransaccionFirma: `${moduleBaseApiURL}/firma`,
  finTransaccionFirma: `${moduleBaseApiURL}/firma`,

  // Subsanación
  obtenerExpedientesPendienteSubsanacion: `${moduleBaseApiURLAcadeApiTramite}/expedientes`,
  obtenerSolicitudPendienteSubsanacion: `${moduleBaseApiURLAcadeApiTramite}/expedientes/solicitudes`,
  obtenerDocumentoExpediente: `${moduleBaseApiURLAcadeApiTramite}/expedientes/documentos`,
  subsanarExpediente: `${moduleBaseApiURLAcadeApiTramite}/expedientes/subsanar`,

};

const reconocimientocreditosApi = {
  endpoints: () => endpoints,
  requisitiosTramite: (codigoTramite) => apiRequest({
    url: `${endpoints.requisitiosTramite}?codigoTramite=${codigoTramite}`,
  }),
  obtenerTitulaciones: () => apiRequest({
    url: endpoints.obtenerTitulaciones,
  }),
  obtenerCursoAcademico: () => apiRequest({
    url: endpoints.obtenerCursoAcademico,
  }),
  obtenerSolicitud: (parametros) => apiRequest({
    url: `${endpoints.obtenerSolicitud}?codTitu=${parametros.codTitu}&codTipoTitu=${parametros.tipoEstudio}`,
  }),
  guardarSolicitud: (jsonSolicitud) => apiRequest({
    url: `${endpoints.guardarSolicitud}`,
    data: jsonSolicitud,
    method: 'POST',
  }),
  borrarSolicitud: (seqSolicitud) => apiRequest({
    url: `${endpoints.borrarSolicitud}?seqSolicitud=${seqSolicitud}`,
    method: 'DELETE',
  }),
  obtenerAsignaturasMatriculadasEstudiante: (parametros) => apiRequest({
    url: `${endpoints.obtenerAsignaturasMatriculadasEstudiante}?codTitu=${parametros.codTitu}&tipoEstudio=${parametros.tipoEstudio}&codPlan=${parametros.codPlan}&cursoAca=${parametros.cursoAca}`,
  }),
  obtenerTitulacionesUmuCursadas: () => apiRequest({
    url: endpoints.obtenerTitulacionesUmuCursadas,
  }),
  obtenerAsignaturasTitulacionUmuCursadas: (parametros) => apiRequest({
    url: `${endpoints.obtenerAsignaturasTitulacionUmuCursadas}?codTitu=${parametros.codTitu}&tipoEstudio=${parametros.tipoEstudio}&codPlan=${parametros.codPlan}`,
  }),
  obtenerUniversidadesEspañolas: () => apiRequest({
    url: endpoints.obtenerUniversidadesEspañolas,
  }),
  obtenerTiposAsignaturasUniversidadesEspañolas: () => apiRequest({
    url: endpoints.obtenerTiposAsignaturasUniversidadesEspañolas,
  }),
  obtenerTitulacionesUniversidadesEspañolas: (params) => apiRequest({
    url: `${endpoints.obtenerTitulacionesUniversidadesEspañolas}?codUniversidad=${params.codUni}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerAsignaturasUniversidadesEspañolas: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasUniversidadesEspañolas}?seqUniversidad=${params.seqUni}&codTitu=${params.codTitu}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}&codigoPlan=${params.codigoPlan}`,
  }),
  obtenerTitulacionesEstudiosPropiosUM: () => apiRequest({
    url: endpoints.obtenerTitulacionesEstudiosPropiosUM,
  }),
  obtenerAsignaturasEstudiosPropiosUM: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasEstudiosPropiosUM}?codEstudio=${params.codEstudio}`,
  }),
  guardarTitulacionNueva: (params) => apiRequest({
    url: `${endpoints.guardarTitulacionNueva}`,
    data: params,
    method: 'POST',
  }),
  guardarUniversidadNueva: (params) => apiRequest({
    url: `${endpoints.guardarUniversidadNueva}`,
    data: params,
    method: 'POST',
  }),
  obtenerUltimaTitulacionNueva: (params) => apiRequest({
    url: `${endpoints.obtenerUltimaTitulacionNueva}?seqUniversidad=${params.codUni}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerUltimaUniversidadNueva: (params) => apiRequest({
    url: `${endpoints.obtenerUltimaUniversidadNueva}?tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  guardarAsignaturasNuevas: (params) => apiRequest({
    url: `${endpoints.guardarAsignaturasNuevas}`,
    data: params,
    method: 'POST',
  }),
  obtenerUltimaAsignaturaNueva: (params) => apiRequest({
    url: `${endpoints.obtenerUltimaAsignaturaNueva}?codTitu=${params.codTitu}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerTitulacionesEstudiosPropiosNoUM: (params) => apiRequest({
    url: `${endpoints.obtenerTitulacionesEstudiosPropiosNoUM}?univCodigo=${params.univCodigo}&seqUniversidad=${params.seqUniversidad}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerAsignaturasEstudiosPropiosNoUM: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasEstudiosPropiosNoUM}?seqUniversidad=${params.seqUniversidad}&codEstudio=${params.codEstudio}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerUniversidadesExtranjeras: (params) => apiRequest({
    url: `${endpoints.obtenerUniversidadesExtranjeras}?tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerTitulacionesUniversidadExtranjera: (params) => apiRequest({
    url: `${endpoints.obtenerTitulacionesUniversidadExtranjera}?univCodigo=${params.univCodigo}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerAsignaturasTitulacionExtranjera: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasTitulacionExtranjera}?univCodigo=${params.univCodigo}&codTitu=${params.codTitu}&tipoOrigen=${params.tipoOrigen}&seqSolicitud=${params.seqSolicitud}`,
  }),
  obtenerPaises: () => apiRequest({
    url: `${endpoints.obtenerPaises}`,
  }),
  obtenerTiposAsignaturasUniversidadesExtranjeras: () => apiRequest({
    url: endpoints.obtenerTiposAsignaturasUniversidadesExtranjeras,
  }),
  obtenerEstudiosReconocimientoFP: (params) => apiRequest({
    url: `${endpoints.obtenerEstudiosReconocimientoFP}?codTitu=${params.titulacion.codTitu}&codPlan=${params.titulacion.codPlan}`,
  }),
  obtenerAsignaturasEstudioFP: (params) => apiRequest({
    url: `${endpoints.obtenerAsignaturasEstudioFP}?codTitu=${params.titulacion.codTitu}&codPlan=${params.titulacion.codPlan}&idEstudio=${params.idEstudio}`,
  }),
  iniciaTransaccionFirma: (parametros) => apiRequest({
    url: `${endpoints.iniciaTransaccionFirma}`,
    data: parametros,
    method: 'POST',
  }),
  finTransaccionFirma: (idTransaccion, idSession, modoFinFirma) => apiRequest({
    url: `${endpoints.finTransaccionFirma}?idTransaccion=${idTransaccion}&idSession=${idSession}&modoFinFirma=${modoFinFirma}`,
    method: 'PUT',
  }),
  guardarSesion: (sesion) => apiRequest({
    url: `${endpoints.guardarSesion}`,
    method: 'POST',
    data: sesion,
  }),
  obtenerSesion: (idSession) => apiRequest({
    url: `${endpoints.obtenerSesion}?idSession=${idSession}`,
  }),
  obtenerExpedientesPendienteSubsanacion: (codTramite) => apiRequest({
    url: `${endpoints.obtenerExpedientesPendienteSubsanacion}?codTramite=${codTramite}`,
  }),
  obtenerSolicitudPendienteSubsanacion: (expeSub, codTramite) => apiRequest({
    url: `${endpoints.obtenerSolicitudPendienteSubsanacion}?eniExpediente=${expeSub.expediente.identificador}&usuarioUnidadAdministrativa=${expeSub.usuarioUnidadAdministrativa}&codTramite=${codTramite}`,
  }),
  obtenerDocumentoExpediente: (expediente, eniDocumento, usuarioUnidad) => apiRequest({
    url: `${endpoints.obtenerDocumentoExpediente}?eniExpediente=${expediente.identificador}&usuarioUnidadAdministrativa=${usuarioUnidad}&eniDocumento=${eniDocumento}`,
  }),
  subsanarExpediente: (solicitud) => apiRequest({
    url: `${endpoints.subsanarExpediente}`,
    method: 'POST',
    data: solicitud,
  }),
  subirArchivo: (file) => apiRequest({
    url: `${endpoints.subirArchivo}`,
    method: 'POST',
    data: file,
  }),
  obtenerDocumento: (pid) => apiRequest({
    url: `${endpoints.obtenerDocumento}?pidDocumento=${pid}`,
  }),
  borrarDocumento: (pidDocumento) => apiRequest({
    url: `${endpoints.borrarDocumento}`,
    data: pidDocumento,
    method: 'DELETE',
  }),
};

export default reconocimientocreditosApi;
