export default {
  namespaced: true,
  // ESTADO
  state: {
    solicitud: {},
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    SET_SOLICITUD(state, valor) {
      state.solicitud = valor;
    },
  },
};
