export default {
  namespaced: true,
  state: {
    filtrosBuscador: null,
  },
  mutations: {
    almacenarFiltrosBuscador(state, filtrosBuscador) {
      state.filtrosBuscador = filtrosBuscador;
    },
  },
};
