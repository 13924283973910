import { defineAsyncComponent } from 'vue';

const ConsultaTrabajos = defineAsyncComponent(() => import(/* webpackChunkName: "SistemaImpresion_ConsultaTrabajos" */'@/app/pci/sistema-impresion/views/ConsultaTrabajos.vue'));
const MovimientosSaldo = defineAsyncComponent(() => import(/* webpackChunkName: "SistemaImpresion_MovimientosSaldo" */'@/app/pci/sistema-impresion/views/MovimientosSaldo.vue'));
const RecargaSaldo = defineAsyncComponent(() => import(/* webpackChunkName: "SistemaImpresion_RecargaSaldo" */'@/app/pci/sistema-impresion/views/RecargaSaldo.vue'));

const rutaProyecto = '/sistema-impresion';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'SistemaImpresion Inicio',
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'sistema-impresion',
      hiddenFeature: true,
    },
    redirect: `${rutaProyecto}/consulta-trabajos/`,
  },
  {
    path: `${rutaProyecto}/consulta-trabajos/`,
    name: 'SistemaImpresion Consulta trabajos',
    component: ConsultaTrabajos,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'sistema-impresion',
      hiddenFeature: true,
    },
  },
  {
    path: `${rutaProyecto}/movimientos-saldo/`,
    name: 'SistemaImpresion Movimientos saldo',
    component: MovimientosSaldo,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'sistema-impresion',
      hiddenFeature: true,
    },
  },
  {
    path: `${rutaProyecto}/recarga-saldo/`,
    name: 'SistemaImpresion Recarga saldo',
    component: RecargaSaldo,
    meta: {
      requiresAuth: true,
      group: 'pci',
      application: 'sistema-impresion',
      hiddenFeature: true,
    },
  },
];
