<script setup>
import { computed, defineProps, defineEmits } from 'vue';

const props = defineProps({
  value: { type: Number, required: true },
  active: { type: Boolean, required: false, default: false },
});
const emit = defineEmits(['change']);

const imgSrc = computed(() => {
  const leadingNumberFormat = new Intl.NumberFormat('es-ES', {
    minimumIntegerDigits: 2,
  });
  return `/img/feedback/${leadingNumberFormat.format(props.value)}.png`;
});

const className = computed(() => (props.active ? 'is-active' : ''));

const clicked = () => {
  emit('change', props.value);
};
</script>

<template>
<span
  class="generalFeedbackSmiley"
  :class="className"
>
  <img
    tabindex="0"
    class="generalFeedbackSmiley__img"
    :src="imgSrc"
    :alt="$t('alt', [props.value])"
    @click="clicked"
  />
</span>
</template>

<i18n>
{
  "en" : {
    "alt" : "Give { 0 } points`"
  },
  "es" : {
    "alt" : "Valorar con { 0 } puntos`"
  }
}
</i18n>

<style lang="scss" scoped>
.generalFeedbackSmiley {
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0);
  border-radius: var(--card-border-radius);
  display: inline-block;
  line-height: 1;
  opacity: 0.6;
  padding: 0.5rem;
  transition: all 0.25s ease-in-out;

  &:hover,
  &.is-active {
    border: 1px solid var(--link-color);
    opacity: 1;
  }
  &__img {
    aspect-ratio: 1 / 1;
    display: inline-block;
    width: 3rem;
    max-width: 100%;
  }
}
</style>
