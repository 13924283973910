<template>
  <div class="home" v-if="isLogged">
    <Hero />
    <Dashboard />
    <my-events-container />
    <div
      v-if="!areasToShow && showMessage"
      class="completeList__info"
    >
      <div class="alert alert__warning">
        {{ $t('noVisibleSliderMsg') }}
        {{ $t('noVisibleSliderMsg2') }}
        {{ $t('noVisibleSliderMsg3') }}
        <router-link to="/preferences">
          {{ $t('noVisibleSliderBtn') }}
        </router-link>
      </div>
    </div>
    <div
      class="completeList__container"
      v-if="!loadingSliders && userIsLoaded"
    >
      <span id="carriles" />
      <draggable
        v-model="draggableSliderList"
        class="mainmenu__draggable completeList__sliders"
        ghost-class="ghost"
        @start="dragging = true"
        @end="dragging = false"
        v-if="isLogged && !isMobileData"
        tag="transition-group"
        :component-data="{name:'fade'}"
        item-key="sliderKey"
      >
        <template #item="{element}">
          <slider-component
            :p-slider-key="element.sliderKey"
            :p-order="element.order"
            :p-visible="element.visible"
            :p-private="element.private"
            :p-tidy="element.tidy"
            :p-services="element.services"
            :p-param="element.param"
            :p-view-type="element.viewType"
            :style="'order:' + element.order + ';'"
            :p-options="element.orderParams"
            :p-small-cards="element.smallCards || null"
            :p-roles="element.roleName"
          />
        </template>
      </draggable>
      <div v-else>
        <slider-component
          v-for="area in orderedSliders"
          :key="area.sliderKey"
          :p-slider-key="area.sliderKey"
          :p-order="area.order"
          :p-visible="area.visible"
          :p-private="area.private"
          :p-tidy="area.tidy"
          :p-services="area.services"
          :p-param="area.param"
          :p-view-type="area.viewType"
          :style="'order:' + area.order + ';'"
          :p-options="area.orderParams"
          :p-small-cards="area.smallCards || null"
          :p-roles="area.roleName"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import { mapState, mapGetters } from 'vuex';
import draggable from 'vuedraggable';

import Dashboard from '@/app/campus-virtual/dashboard/components/Dashboard.vue';
import Hero from '../components/Hero.vue';
import MyEventsContainer from '../components/MyEventsContainer.vue';
import SliderComponent from '../components/SliderComponent.vue';

export default defineComponent({
  name: 'Home',
  components: {
    draggable,
    Hero,
    MyEventsContainer,
    SliderComponent,
    Dashboard,
  },
  data() {
    return {
      content: true,
      showMessage: false,
      isMobileData: true,
      errorMsg: {
        textMsg: this.$t('noVisibleSliderMsg'),
        categoryMsg: 'info',
      },
    };
  },
  computed: {
    ...mapState('services', ['areas', 'loadingSliders']),
    ...mapGetters('auth', ['isLogged']),
    ...mapGetters('user', ['userIsLoaded']),
    areasToShow() {
      const retValue = Object.values(this.areas).filter(
        (el) => (
          el.visible === true
          && el.services.length > 0
          && el.loaded === true
        ),
      ).length;
      return retValue > 0;
    },
    orderedSliders() {
      return Object.values(this.areas).sort((a, b) => a.order - b.order);
    },
    draggableSliderList: {
      get() {
        return this.orderedSliders;
      },
      set(value) {
        const newValueOrder = [];
        for (let i = 0; i < value.length; i += 1) {
          newValueOrder.push({ ...value[i], order: i });
        }
        this.$store.dispatch('services/reorderSliders', newValueOrder);
      },
    },
  },
  methods: {
    computedStyle() {
      return parseInt(window
        .getComputedStyle(document.body)
        .getPropertyValue('--mobile-menu-size')
        .replace('px', ''), 10);
    },
    isTouchable() {
      const isTouchable = window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches;
      return (parseInt(window.outerWidth, 10) < this.computedStyle() || isTouchable);
    },
    changeResizable() {
      this.isMobileData = this.isTouchable();
    },
  },
  mounted() {
    if (this.isLogged) {
      this.$store.dispatch('services/getSlidersInfo');
    }
    this.$store.commit('pages/SET_TITLE', '');
    setTimeout(() => {
      this.showMessage = true;
    }, 1500);
    this.isMobileData = this.isTouchable();
    window.addEventListener('resize', this.changeResizable);
  },
  unmounted() {
    window.removeEventListener('resize', this.changeResizable);
  },
});
</script>

<i18n>
{
  "en": {
    "loading": "Loading data...",
    "completeListTitle": "Services full list",
    "noVisibleSliderMsg": "Visibility of all sliders has been disabled ",
    "noVisibleSliderMsg2": "or there is no service as a favorite. To modify this home screen",
    "noVisibleSliderMsg3": " it to your liking, you can",
    "noVisibleSliderBtn": "access the settings of your preferences"
  },
  "es": {
    "loading": "Cargando datos...",
    "completeListTitle": "Lista completa de servicios",
    "noVisibleSliderMsg": "Se ha deshabilitado la visibilidad de todos los carriles",
    "noVisibleSliderMsg2": "o no hay ningún servicio como favorito. Para modificar ",
    "noVisibleSliderMsg3": "esta pantalla de inicio y ponerla a tu gusto, puedes acceder",
    "noVisibleSliderBtn": "a la configuración de tus preferencias"
  }
}
</i18n>

<style lang="scss" scoped>
.completeList {
  &__container {
    display: flex;
    flex-direction: column;
    list-style: none;
  }
  &__sliders {
    list-style: none;
    padding: 0 0 0 0;
    margin: 2rem 0 0 0;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    > li:first-child {
      padding-top: 1rem;
    }
  }
  &__info {
    max-width: var(--grid-width);
    margin: 1rem auto;
  }
}
.ghost {
  opacity: 0.5;
  border: 2px dashed var(--main-color);
  box-shadow: inset 0 0 2rem 0 rgba(0,0,0,0.25);
}
</style>
