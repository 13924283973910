import { defineAsyncComponent } from 'vue';

const EvaluacionesView = defineAsyncComponent(() => import(/* webpackChunkName: "EvaluacionDocente" */'./views/EvaluacionesView.vue'));

export default [
  {
    path: '/consulta/evaluaciondocente/',
    name: 'EvaluacionDocente',
    component: EvaluacionesView,
    meta: {
      requiresAuth: true,
      group: 'curie',
      application: 'evaluacion-docente',
    },
  },
];
