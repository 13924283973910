import {
  defineAsyncComponent,
} from 'vue';

// Portal
const reconocimientoCreditosPortal = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosPortal" */ '@/app/acade/reconocimiento-creditos/views/tramite/Portal.vue'));

// Tramite
const reconocimientoCreditosWizard = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosWizard" */ '@/app/acade/reconocimiento-creditos/views/tramite/Wizard.vue'));
const reconocimientoCreditosInicio = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosInicio" */ '@/app/acade/reconocimiento-creditos/views/tramite/pasos/Inicio.vue'));
const reconocimientoCreditosDatos = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosDatos" */ '@/app/acade/reconocimiento-creditos/views/tramite/pasos/Datos.vue'));
const reconocimientoCreditosDocumentacion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosDocumentacion" */ '@/app/acade/reconocimiento-creditos/views/tramite/pasos/Documentacion.vue'));
const reconocimientoCreditosPago = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosPago" */ '@/app/acade/reconocimiento-creditos/views/tramite/pasos/Pago.vue'));
const reconocimientoCreditosResumen = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosResumen" */ '@/app/acade/reconocimiento-creditos/views/tramite/pasos/Resumen.vue'));
const reconocimientoCreditosImpresion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosImpresion" */ '@/app/acade/reconocimiento-creditos/views/tramite/pasos/Impresion.vue'));

// Subsanación
const reconocimientoCreditosWizardSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosWizardSubsanacion" */ '@/app/acade/reconocimiento-creditos/views/subsanacion/Wizard.vue'));
const reconocimientoCreditosPagoSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosPagoSubsanacion" */ '@/app/acade/reconocimiento-creditos/views/subsanacion/Pago.vue'));
const reconocimientoCreditosInicioSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosInicioSubsanacion" */ '@/app/acade/reconocimiento-creditos/views/subsanacion/Inicio.vue'));
const reconocimientoCreditosResumenSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosResumenSubsanacion" */ '@/app/acade/reconocimiento-creditos/views/subsanacion/Resumen.vue'));
const reconocimientoCreditosFinSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "reconocimientoCreditosFinSubsanacion" */ '@/app/acade/reconocimiento-creditos/views/subsanacion/Fin.vue'));

export default [
  {
    path: '/reconocimientocreditos/inicio',
    name: 'Portal',
    component: reconocimientoCreditosPortal,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'reconocimiento-creditos',
    },
  },
  {
    path: '/reconocimientocreditos',
    name: 'Inicio',
    component: reconocimientoCreditosInicio,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'reconocimiento-creditos',
    },
  },
  {
    path: '/reconocimientocreditos/solicitud',
    name: 'WizardReconocimientoCreditos',
    component: reconocimientoCreditosWizard,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'reconocimiento-creditos',
    },
    redirect: '/reconocimientocreditos/solicitud/datos',
    children: [{
      path: '/reconocimientocreditos/solicitud/datos',
      component: reconocimientoCreditosDatos,
    },
    {
      path: '/reconocimientocreditos/solicitud/documentacion',
      component: reconocimientoCreditosDocumentacion,
    },
    {
      path: '/reconocimientocreditos/solicitud/resumen',
      component: reconocimientoCreditosResumen,
    },
    {
      path: '/reconocimientocreditos/solicitud/pago',
      component: reconocimientoCreditosPago,
    },
    {
      path: '/reconocimientocreditos/solicitud/impresion',
      component: reconocimientoCreditosImpresion,
    },
    ],
  },
  {
    path: '/reconocimientocreditos/subsanacion/',
    name: 'WizardSubsanacionRecCreditos',
    component: reconocimientoCreditosWizardSubsanacion,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'reconocimiento-creditos',
    },
    redirect: '/reconocimientocreditos/subsanacion/inicio',
    children: [
      {
        path: '/reconocimientocreditos/subsanacion/inicio',
        component: reconocimientoCreditosInicioSubsanacion,
      },
      {
        path: '/reconocimientocreditos/subsanacion/pago',
        component: reconocimientoCreditosPagoSubsanacion,
      },
      {
        path: '/reconocimientocreditos/subsanacion/resumen',
        component: reconocimientoCreditosResumenSubsanacion,
      },
      {
        path: '/reconocimientocreditos/subsanacion/fin',
        component: reconocimientoCreditosFinSubsanacion,
      },
    ],
  },
];
