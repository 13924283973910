import storage from '@/storage';
import trasladoService from '../trasladoPau.api';

// Store para la subsanación
// Lógica antigua importada. Pendiente refactorizar.

export default {
  namespaced: true,
  // ESTADO
  state: {
    expediente: null,
    usuarioUnidadAdministrativa: null,
    documentoFN: null, // { filename, mimetype, pid, eni, hash}
    enProcesoDeFirma: false,
    idTransaccionFirma: '',
    docResguardo: null,
    pidResguardo: '',
    jsonSolicitud: null,
    recibo: null, // { hash, eni, pid, nombre }
    jsonSubsanacion: null,
    horaGuardado: null,
    horaGuardadoSubsanacion: null,
  },
  // MUTACIONES: sólo estas pueden modificar el estado
  mutations: {
    SET_EXPEDIENTE(state, expediente) {
      state.expediente = expediente;
    },
    SET_USUARIO_UNIDAD_ADMINISTRATIVA(state, usuarioUnidadAdministrativa) {
      state.usuarioUnidadAdministrativa = usuarioUnidadAdministrativa;
    },
    SET_DOCUMENTO_FN(state, documentoFN) {
      state.documentoFN = documentoFN;
    },
    SETENPROCESODEFIRMA(state, valor) {
      state.enProcesoDeFirma = valor;
    },
    SETIDTRANSACCIONFIRMA(state, valor) {
      state.idTransaccionFirma = valor;
    },
    SETDOCRESGUARDO(state, valor) {
      state.docResguardo = valor;
    },
    SETPIDRESGUARDO(state, valor) {
      state.pidResguardo = valor;
    },
    SET_RECIBO(state, value) {
      state.recibo = value;
    },
    SETJSONSOLICITUD(state, valor) {
      state.jsonSolicitud = valor;
    },
    SETJSONSUBSANACION(state, valor) {
      state.jsonSubsanacion = valor;
    },
    SET_OBSERVACIONES(state, value) {
      if (state.jsonSubsanacion) {
        state.jsonSubsanacion.observacionesSubsanacion = value;
      } else {
        state.jsonSubsanacion = {
          observacionesSubsanacion: value,
        };
      }
    },
    RESET_ANEXOS_SIN_SUBIR(state) {
      state.jsonSubsanacion.anexosSinSubirSubsanacion = [];
    },
    SETHORAGUARDADO(state, valor) {
      state.horaGuardado = valor;
    },
    SETHORAGUARDADOSUBSANACION(state, valor) {
      state.horaGuardadoSubsanacion = valor;
    },
    SETSESSION(state, valor) {
      const {
        expediente,
        usuarioUnidadAdministrativa,
        enProcesoDeFirma,
        idTransaccionFirma,
        docResguardo,
        pidResguardo,
        jsonSolicitud,
        recibo,
        jsonSubsanacion,
      } = valor;

      state.expediente = expediente;
      state.usuarioUnidadAdministrativa = usuarioUnidadAdministrativa;
      state.enProcesoDeFirma = enProcesoDeFirma;
      state.idTransaccionFirma = idTransaccionFirma;
      state.pidResguardo = pidResguardo;
      state.jsonSolicitud = jsonSolicitud;
      state.docResguardo = docResguardo;
      state.recibo = recibo;
      state.jsonSubsanacion = jsonSubsanacion;
    },
  },
  // ACCIONES: se encargan de la lógica de negocio y llaman a las mutaciones
  actions: {
    initSubsanacion({ commit }, { expediente, usuarioUnidadAdministrativa }) {
      commit('SET_EXPEDIENTE', expediente);
      commit('SET_USUARIO_UNIDAD_ADMINISTRATIVA', usuarioUnidadAdministrativa);
    },
    setEnProcesoDeFirma({ commit }, valor) {
      commit('SETENPROCESODEFIRMA', valor);
    },
    setIdTransaccionFirma({ commit }, valor) {
      commit('SETIDTRANSACCIONFIRMA', valor);
    },
    setDocResguardo({ commit }, valor) {
      commit('SETDOCRESGUARDO', valor);
    },
    setPidResguardo({ commit }, valor) {
      commit('SETPIDRESGUARDO', valor);
    },
    setJsonSolicitud({ commit }, valor) {
      commit('SETJSONSOLICITUD', valor);
      commit('SETHORAGUARDADO', Date.now());
    },
    setJsonSubsanacion({ commit }, valor) {
      commit('SETJSONSUBSANACION', valor);
      commit('SETHORAGUARDADOSUBSANACION', Date.now());
    },
    setHoraGuardado({ commit }, valor) {
      commit('SETHORAGUARDADO', valor);
    },
    setHoraGuardadoSubsanacion({ commit }, valor) {
      commit('SETHORAGUARDADOSUBSANACION', valor);
    },
    setLimpiarDatosSolicitud({ commit }) {
      commit('SET_EXPEDIENTE', null);
      commit('SET_USUARIO_UNIDAD_ADMINISTRATIVA', null);
      commit('SETENPROCESODEFIRMA', false);
      commit('SETIDTRANSACCIONFIRMA', null);
      commit('SETDOCRESGUARDO', null);
      commit('SETPIDRESGUARDO', null);
      commit('SETJSONSOLICITUD', null);
      commit('SETHORAGUARDADO', null);
      commit('SETJSONSUBSANACION', null);
      commit('SETHORAGUARDADOSUBSANACION', null);
    },

    async backupState({ state }) {
      const session = {
        expediente: state.expediente,
        usuarioUnidadAdministrativa: state.usuarioUnidadAdministrativa,
        enProcesoDeFirma: state.enProcesoDeFirma,
        idTransaccionFirma: state.idTransaccionFirma,
        docResguardo: state.docResguardo,
        pidResguardo: state.pidResguardo,
        jsonSolicitud: state.jsonSolicitud,
        recibo: state.recibo,
        jsonSubsanacion: state.jsonSubsanacion,
      };
      const sessionId = await trasladoService.storeSession(session);
      storage.setItem('subsanacionSessionId', sessionId);
    },

    async restoreState() {
      const sessionId = storage.getItem('subsanacionSessionId');
      if (sessionId) {
        const session = await trasladoService.restoreSession(sessionId);
        this.commit('SETSESSION', session);
      }
    },
  },
};
