import icons from '../models/icons';

const serviceMixin = {
  data() {
    return {
      filteredWords: ['el', 'de', 'del', 'la', 'los', 'las', '-', 'a', 'al', 'UMU', 'UM', 'un'],
    };
  },
  computed: {
    usingService() {
      return this.lazyService || this.service;
    },

    roles() {
      return !this.usingService.audience
        ? []
        : this.usingService.audience.map(
          (x) => x.audienceType.charAt(0).toUpperCase() + x.audienceType.slice(1),
        );
    },

    categoryData() {
      return this.usingService.category && this.usingService.category.length > 0
        ? this.usingService.category.map((el) => el.name).join(', ')
        : '';
    },

    areasServed() {
      return !this.usingService.areaServed
        ? []
        : this.usingService.areaServed
          .map((el) => `<span
                itemscope
                itemtype="http://schema.org/AdministrativeArea"
              >
                <span itemprop="name">${el.name}</span>`)
          .join(', ');
    },

    isEmptyCard() {
      return typeof this.usingService.name === 'undefined';
    },

    services() {
      return !this.usingService.audience
        ? []
        : this.usingService.audience.map(
          (x) => x.audienceType.charAt(0).toUpperCase() + x.audienceType.slice(1),
        );
    },

    externalUrl() {
      try {
        const linkPattern = '_link';
        let url = this.usingService[linkPattern].external.href;
        if (url.startsWith('https%3A%2F%2F') || url.startsWith('http%3A%2F%2F')) {
          url = decodeURIComponent(url);
        }
        const protocol = url.startsWith('http') ? '' : '//';
        if (url.indexOf(window._env_.POSE_API_URL) > -1) {
          url = url.replace(window._env_.POSE_API_URL, '');
        }
        return `${protocol}${url}`;
      } catch (e) {
        return '#';
        // const linkPattern = 'link';
        // const url = this.usingService[linkPattern];
        // const protocol = url.startsWith('http') ? '' : '//';
        // return `${protocol}${url}`;
      }
    },

    getClass() {
      const categoryIdentifier = this.usingService.category
        && this.usingService.category.length > 0
        ? this.usingService.category.map((el) => el.identifier).join('')
        : '0';
      const classEmpty = this.isEmptyCard ? 'card__is_empty' : '';
      return `is__catIdentifier__${categoryIdentifier} ${classEmpty}`;
    },

    itemIcon() {
      const idTemp = parseInt(this.usingService.identifier.split('_')[0], 10);
      const charValue = this.usingService.name.charCodeAt(0);
      return icons[(charValue + idTemp) % icons.length];
    },

    itemInitials() {
      const words = this.usingService.name
        ? this.usingService.name.split(' ').filter((el) => !this.filteredWords.includes(el))
        : '';
      if (words.length >= 2) {
        return words[0].substr(0, 1).toUpperCase() + words[1].substr(0, 1).toUpperCase();
      }
      return this.usingService.name ? this.usingService.name.substr(0, 2).toUpperCase() : '';
    },

    orderedChildren() {
      const children = (this.usingService.children)
        ? [...this.usingService.children] : [];
      if (children.length > 0) {
        return children.sort((a, b) => a.name.localeCompare(b.name));
      }
      return [];
    },

    cleanedHTML() {
      const doc = document.createElement('body');
      doc.innerHTML = this.usingService.description || '';
      const tags = doc.getElementsByTagName('*');
      for (let i = tags.length - 1; i >= 0; i -= 1) {
        tags[i].removeAttribute('style');
        if (typeof (tags[i].innerText) === 'string') {
          if (tags[i].innerText.trim() === '') {
            tags[i].remove();
          }
        }
      }
      return doc.innerHTML.replace(/&nbsp;/g, ' ').replace(/\s\s+/g, ' ');
    },

    cleanedPotentialAction() {
      const doc = document.createElement('body');
      doc.innerHTML = this.usingService.potentialAction || '';
      const tags = doc.getElementsByTagName('*');
      for (let i = tags.length - 1; i >= 0; i -= 1) {
        tags[i].removeAttribute('style');
        if (typeof (tags[i].innerText) === 'string') {
          if (tags[i].innerText.trim() === '') {
            tags[i].remove();
          }
        }
      }
      return doc.innerHTML.replace(/&nbsp;/g, ' ').replace(/\s\s+/g, ' ');
    },

    descriptionCutted() {
      const MAX_LENGTH = 50;
      const trimmedString = this.usingService.disambiguatingDescription.substr(0, MAX_LENGTH);
      return (this.usingService.disambiguatingDescription.length < MAX_LENGTH)
        ? this.usingService.disambiguatingDescription
        : `${trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))}&#8230;`;
    },
  },
  methods: {
    normalizeStr(str) {
      return !str ? '' : str.toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .replace(/ /g, '-');
    },
  },
};

export { serviceMixin as default };
