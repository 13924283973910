export default {
  namespaced: true,
  state: {
    formData: null,
    rutaVolver: null,
    nombreBtnRutaVolver: null,
  },
  mutations: {
    setFormData(state, valor) {
      state.formData = valor;
    },
    clearFormData(state) {
      state.formData = null;
    },
    setRutaVolver(state, value) {
      state.rutaVolver = value;
    },
    clearRutaVolver(state) {
      state.rutaVolver = null;
    },
    setNombreBtnRutaVolver(state, value) {
      state.nombreBtnRutaVolver = value;
    },
    clearNombreBtnRutaVolver(state) {
      state.nombreBtnRutaVolver = null;
    },
  },
};
