export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Did you find this search useful?"])},
        "titlegeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What do you thing about My Campus?"])},
        "moreFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to give us more information?"])},
        "feedbackButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send feedback"])},
        "successMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your feedback have been sent correctly."])},
        "successMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you very much."])},
        "errorMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There was a problem sending the feedback."])},
        "errorMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try to send it later."])}
      },
      "es": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Te ha resultado útil esta búsqueda?"])},
        "titlegeneral": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Qué te está pareciendo Mi Campus?"])},
        "moreFeedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Querrías darnos más información?"])},
        "feedbackButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviar comentario"])},
        "successMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se han enviado tus comentarios correctamente."])},
        "successMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Muchas gracias."])},
        "errorMessage1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha habido un problema al enviar el comentario."])},
        "errorMessage2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inténtalo más tarde"])}
      }
    }
  })
}
