import { defineAsyncComponent } from 'vue';

const MiFormacion = defineAsyncComponent(() => import(/* webpackChunkName: "curie-miFormacion" */ './views/MiFormacion.vue'));
const NuevaAccion = defineAsyncComponent(() => import(/* webpackChunkName: "curie-nuevaAccion" */ './views/NuevaAccion.vue'));

const rutaProyecto = '/accionesformativas';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'MiFormacion',
    component: MiFormacion,
    meta: {
      group: 'curie',
      application: 'acciones-formativas',
      hiddenFeature: true,
      requiresAuth: true,
    },
  },
  {
    path: `${rutaProyecto}/nueva-accion/`,
    name: 'NuevaAccion',
    component: NuevaAccion,
    meta: {
      group: 'curie',
      application: 'acciones-formativas',
      hiddenFeature: true,
      requiresAuth: true,
    },
  },
];
