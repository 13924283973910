import storage from '@/storage';
import trasladoService from '../trasladoPau.api';

const nomenclaturaAnexos = {
  cartaAdmision: 'Admisión-matrícula.pdf',
  bajaMatricula: 'Baja-matrícula.pdf',
};

export default {
  namespaced: true,

  state: {
    prueba: null,
    solicitud: null,
    pdfBorrador: null, // PDF del resguardo sin firmar
    pdfResguardo: null, // PDF del resguardo firmado
    compruebaTelefono: false, // almacena confirmación (check) del usuario
    compruebaLOPD: false, // almacena confirmación (check) del usuario
  },

  mutations: {
    SET_PRUEBA(state, prueba) {
      state.prueba = prueba;
    },
    SET_SOLICITUD(state, solicitud) {
      state.solicitud = solicitud;
    },
    CLEAR(state) {
      state.prueba = null;
      state.solicitud = null;
      state.pdfBorrador = null;
      state.pdfResguardo = null;
      state.compruebaTelefono = false;
      state.compruebaLOPD = false;
    },
    CLEAR_SOLICITUD(state) {
      const { solicitud } = state;
      if (!solicitud) return;
      solicitud.destino = null;
      solicitud.resguardo = null;
    },
    SET_BORRADOR(state, pdfBorrador) {
      state.pdfBorrador = pdfBorrador;
    },
    SET_RESGUARDO(state, pdfResguardo) {
      state.pdfResguardo = pdfResguardo;
    },
    SET_COMPRUEBA_TELEFONO(state, compruebaTelefono) {
      state.compruebaTelefono = compruebaTelefono;
    },
    SET_COMPRUEBA_LOPD(state, compruebaLOPD) {
      state.compruebaLOPD = compruebaLOPD;
    },
    SET_TIPO_TRASLADO(state, tipoTraslado) {
      const { solicitud } = state;
      solicitud.tipoTraslado = tipoTraslado;
    },
    SET_CURSO_ACADEMICO_TRASLADO(state, curso) {
      const { solicitud } = state;
      solicitud.cursoAcademicoTraslado = curso;
    },
    SET_DESTINO(state, destino) {
      const { solicitud } = state;
      solicitud.destino = destino;
    },
    // Añade o reemplaza documento en los nuevos documentos de la solicitud
    PUT_ANEXO(state, documento) {
      const { solicitud } = state;
      if (!solicitud) return;
      solicitud.nuevosDocumentos = solicitud.nuevosDocumentos.filter(
        (doc) => doc.tipo !== documento.tipo,
      );
      solicitud.nuevosDocumentos.push(documento);
    },
    // Elimina de los nuevos documentos de la solicitud el anexo del tipo dado
    REMOVE_ANEXO(state, tipo) {
      const { solicitud } = state;
      if (!solicitud) return;
      solicitud.nuevosDocumentos = solicitud.nuevosDocumentos.filter(
        (doc) => doc.tipo !== tipo,
      );
    },
    REMOVE_ANEXOS(state) {
      const { solicitud } = state;
      solicitud.nuevosDocumentos = [];
    },
  },

  actions: {
    selectPrueba({ commit }, prueba) {
      commit('SET_PRUEBA', prueba);
    },
    initTramite({ commit }) {
      commit('CLEAR');
    },
    loadSolicitud({ commit }, { tipoAcceso }) {
      return trasladoService
        .recuperarSolicitud(tipoAcceso.codigoTipoAcceso)
        .then((solicitud) => {
          commit('SET_SOLICITUD', solicitud);
          return solicitud;
        })
        .catch(
          // Si no existe todavía la solicitud, creamos una nueva inicializada
          // con información del posible certificado de traslado existente
          () => trasladoService.nuevaSolicitud(tipoAcceso).then((nueva) => {
            commit('SET_SOLICITUD', nueva);
            return nueva;
          }),
        );
    },
    saveSolicitud({ commit }, { solicitud, translate }) {
      const nuevaSolicitud = solicitud; // { ...state.solicitud, ...solicitud };
      return trasladoService
        .guardarSolicitud(nuevaSolicitud)
        .then((solicitudRecibida) => {
          commit('SET_SOLICITUD', solicitudRecibida);
          return Promise.resolve({
            severity: 'success',
            summary: translate('solicitudGuardada'),
            life: 3000,
          });
        })
        .catch((error) => {
          const { response: { data: { errors } } } = error;
          const detail = errors?.map((e) => e.defaultMessage).join(', ');
          return {
            severity: 'error',
            summary: translate('errorGuardarSolicitud'),
            detail,
          };
        });
    },

    resetSolicitud({ commit }) {
      /// TODO ¿Se deben eliminar PIDs en Archivo?
      commit('REMOVE_ANEXOS');
      commit('SET_BORRADOR', null);
      commit('SET_RESGUARDO', null);
      commit('CLEAR_SOLICITUD');
    },

    storeAnexo({ commit }, {
      tipo, filename, mimetype, base64,
    }) {
      const renamed = nomenclaturaAnexos[tipo] ?? filename;
      const nuevoDocumento = {
        tipo,
        origen: 'CIUDADANO',
        visibleInteresado: true,
        filename: renamed,
        mimetype,
        base64,
        archivado: false,
        pid: null,
        eni: null,
        hash: null,
      };
      commit('PUT_ANEXO', nuevoDocumento);
    },
    storeBorrador({ commit }, {
      tipo, mimetype, content, filename,
    }) {
      const nuevoDocumento = {
        tipo,
        origen: 'CIUDADANO',
        visibleInteresado: true,
        filename,
        mimetype,
        content,
        archivado: false,
        pid: null,
        eni: null,
        hash: null,
      };
      commit('PUT_ANEXO', nuevoDocumento);
    },
    removeAnexo({ commit }, tipo) {
      commit('REMOVE_ANEXO', tipo);
    },
    // Utilizado para anexar un documento archivado externamente (e.g. Docu de familia numerosa)
    storeAnexoArchivado({
      dispatch, commit, state,
    }, {
      tipo, filename, mimetype, pid, eni, hash,
    }) {
      const nuevoDocumento = {
        tipo,
        origen: 'CIUDADANO',
        visibleInteresado: true,
        filename,
        mimetype,
        base64: null,
        archivado: true,
        pid,
        eni,
        hash,
      };
      commit('PUT_ANEXO', nuevoDocumento);
      return dispatch('saveSolicitud', {
        solicitud: state.solicitud,
      });
    },
    generarPDFBorrador({ commit, state }, { translate }) {
      return trasladoService
        .generarPDFBorrador(state.solicitud.tipoAcceso.codigoTipoAcceso)
        .then((doc) => {
          /// Actualizamos el store con dicho pdf. Nótese que la solicitud no se ha modificado.
          commit('SET_BORRADOR', doc);
          return Promise.resolve({
            severity: 'success',
            summary: translate('generadoBorrador'),
            life: 3000,
          });
        })
        .catch(() => Promise.resolve({
          severity: 'error',
          summary: translate('errorGenerarBorrador'),
        }));
    },
    storePagoRealizado({ commit, state }, { importe, codFn, numeroReferenciaRecibo }) {
      const pagoRealizado = { importe, codFn, numeroReferenciaRecibo };
      const solicitud = { ...state.solicitud, pagoRealizado };
      commit('SET_SOLICITUD', solicitud);
    },

    iniciarFirma({ commit, state }, { origin, translate }) {
      return trasladoService
        .iniciarFirma(state.solicitud, origin)
        .then((firmaIniciada) => {
          // actualizamos solicitud (posibles cambios por archivado de anexos)
          commit('SET_SOLICITUD', firmaIniciada.solicitud);
          return firmaIniciada.urlRedireccion;
        })
        .catch(() => Promise.reject(new Error(translate('errorIniciarFirma'))));
    },

    finalizarFirma(
      { commit },
      { idTransaccion, codigoTipoAcceso },
    ) {
      return trasladoService
        .finalizarFirma(idTransaccion, codigoTipoAcceso)
        .then((firmaResponse) => {
          // firmaResponse contiene tanto la solicitud como el pdf del resguardo
          // Actualizamos la solicitud devuelta (firmada y con anexos ya archivados)
          // y PDF del resguardo
          commit('SET_SOLICITUD', firmaResponse.solicitud);
          commit('SET_RESGUARDO', firmaResponse.pdfResguardo);
          return firmaResponse;
        });
    },

    async backupState({ state }) {
      const {
        prueba, solicitud, pdfBorrador, pdfResguardo, compruebaTelefono, compruebaLOPD,
      } = state;
      const sessionId = await trasladoService.storeSession({
        prueba, solicitud, pdfBorrador, pdfResguardo, compruebaTelefono, compruebaLOPD,
      });
      storage.setItem('tramiteSessionId', sessionId);
    },

    async restoreState({ commit }, option) {
      const sessionId = storage.getItem('tramiteSessionId');
      if (sessionId) {
        const session = await trasladoService.restoreSession(sessionId);
        const {
          prueba, solicitud, pdfBorrador, pdfResguardo, compruebaTelefono, compruebaLOPD,
        } = session;
        commit('SET_PRUEBA', prueba);
        commit('SET_SOLICITUD', solicitud);
        commit('SET_BORRADOR', pdfBorrador);
        commit('SET_RESGUARDO', pdfResguardo);
        commit('SET_COMPRUEBA_TELEFONO', compruebaTelefono);
        commit('SET_COMPRUEBA_LOPD', compruebaLOPD);

        if (!option?.keepSessionId) {
          // TODO Se podría eliminar también la sesión del backend (un único uso)
          storage.removeItem('tramiteSessionId');
        }
      }
    },
  },

  getters: {
    existeTraslado: (state) => state.solicitud?.tipoAcceso?.existeTraslado,
    trasladoEnCursoActual: (state) => {
      const cursoAcademicoActual = state.solicitud?.cursoAcademicoActual;
      const cursoAcademicoTraslado = state.solicitud?.certificadoTraslado?.cursoAcademicoTraslado;
      return cursoAcademicoActual === cursoAcademicoTraslado;
    },
    tipoNuevo: (state) => state.solicitud?.tipoTraslado === 'Nuevo',
    tipoModificacion: (state) => state.solicitud?.tipoTraslado === 'Modificacion',
    tipoAnulacion: (state) => state.solicitud?.tipoTraslado === 'Anulacion',
    cartaAdmision: (state) => {
      if (state.solicitud) {
        const doc = state.solicitud.nuevosDocumentos.find(
          (d) => d.tipo === 'cartaAdmision',
        );
        return doc;
      }
      return null;
    },
    cartaArchivada: (state) => {
      if (state.solicitud) {
        const carta = state.solicitud.nuevosDocumentos.find(
          (doc) => doc.tipo === 'cartaAdmision',
        );
        return carta && carta.archivado;
      }
      return false;
    },
    bajaMatricula: (state) => {
      if (state.solicitud) {
        const doc = state.solicitud.nuevosDocumentos.find(
          (d) => d.tipo === 'bajaMatricula',
        );
        return doc;
      }
      return null;
    },
    bajaMatriculaArchivada: (state) => {
      if (state.solicitud) {
        const anexo = state.solicitud.nuevosDocumentos.find(
          (doc) => doc.tipo === 'bajaMatricula',
        );
        return anexo && anexo.archivado;
      }
      return false;
    },
    destinoIsEmpty: (state) => !state.solicitud?.destino?.estudio?.descripcion,
  },
};
