import { defineAsyncComponent } from 'vue';

const miExpediente = defineAsyncComponent(() => import(/* webpackChunkName: "miExpediente" */ '@/app/acade/mi-expediente/views/MiExpediente.vue'));

export default [
  {
    path: '/miexpediente',
    name: 'Mi expediente',
    component: miExpediente,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'mi-expediente',
      hiddenFeature: true,
    },
  },
];
