/* eslint-disable no-unused-vars */
import transporteApi from '../../transporte.api';

async function inicializaSolicitudBono(commit, state, params) {
  let errorMsg;

  // Limpiamos la solicitud de bono que pudiese haber
  commit('CLEAR_SOLICITUD_BONO');

  // Establece la empresa del bono
  let empresas = await transporteApi.consultaEmpresasBonoUniversitario();

  empresas = empresas.filter((element) => element.nombreCorto === params.nombreCorto);
  if (empresas) {
    commit('SET_EMPRESA', empresas[0]);
  }

  // Establecemos los tipos disponibles

  try {
    const tiposBono = await transporteApi.consultaTiposBonoUniversitario(state.empresa.codigo);
    commit('SET_TIPOS_BONO_DISPONIBLES', tiposBono);
  } catch (error) {
    if (error.response.data.message) {
      errorMsg = error.response.data.message;
    }
  }

  return new Promise((resolve, reject) => (errorMsg ? reject(errorMsg) : resolve()));
}

const bonoUniversitario = {
  namespaced: true,
  state: {
    empresa: null,
    solicitante: null,
    foto: null,
    tipoBono: null,
    tiposBonoDisponibles: [],
    lugarRecogida: null,
    apoyosSensoriales: [],
    solicitudPresentada: false,
  },
  mutations: {
    CLEAR_SOLICITUD_BONO(state) {
      state.empresa = null;
      state.tipoBono = null;
      state.tiposBonoDisponibles = [];
      state.lugarRecogida = null;
      state.apoyosSensoriales = [];
      state.solicitudPresentada = false;
    },
    SET_SOLICITANTE(state, user) {
      const solicitante = {
        identificador: `${user.afiliacion.identificador}${user.afiliacion.letra}`,
        nombre: `${user.afiliacion.nombre} ${user.afiliacion.apellido1} ${user.afiliacion.apellido2}`,
        email: user.afiliacion.emailNotificaciones,
        telefono: user.afiliacion.telefonoNotificaciones,
      };
      state.solicitante = solicitante;
    },
    SET_DATOS_SOLICITANTE(state, datosSolicitante) {
      state.solicitante = {
        ...state.solicitante,
        ...datosSolicitante,
      };
    },
    SET_FOTO_SOLICITANTE(state, foto) {
      state.foto = foto;
    },
    SET_EMPRESA(state, empresa) {
      state.empresa = { ...empresa };
    },
    SET_TIPO_BONO(state, tipoBono) {
      state.tipoBono = { ...tipoBono };
    },
    SET_TIPOS_BONO_DISPONIBLES(state, tiposBonoDisponibles) {
      state.tiposBonoDisponibles = [...tiposBonoDisponibles];
    },
    SET_LUGAR_RECOGIDA(state, lugarRecogida) {
      state.lugarRecogida = { ...lugarRecogida };
    },
    SET_APOYO_SENSORIAL(state, apoyosSensoriales) {
      state.apoyosSensoriales = [...apoyosSensoriales];
    },
    SET_SOLICITUD_PRESENTADA(state, solicitudPresentada) {
      state.solicitudPresentada = solicitudPresentada;
    },
  },
  actions: {
    init({ commit, state }, params) {
      return inicializaSolicitudBono(commit, state, params);
    },
    setSolicitante({ commit }, params) {
      commit('SET_SOLICITANTE', params.user);

      // Establecemos la foto
      transporteApi.userPhoto().then(() => {
        commit('SET_FOTO_SOLICITANTE', transporteApi.endpoints().userPhoto);
      });
    },
    updateDatosSolicitante({ commit }, params) {
      commit('SET_DATOS_SOLICITANTE', params.datosSolicitante);
    },
    updateTipoBono({ commit }, params) {
      commit('SET_TIPO_BONO', params.tipoBono);
      commit('SET_LUGAR_RECOGIDA', params.lugarRecogida);
      commit('SET_APOYO_SENSORIAL', params.apoyosSensoriales);
    },
    updatePresentacionSolicitud({ commit }, params) {
      commit('SET_SOLICITUD_PRESENTADA', params.solicitudPresentada);
    },
  },
};

export default bonoUniversitario;
