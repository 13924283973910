export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Give ", _interpolate(_list(0)), " points`"])}
      },
      "es": {
        "alt": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Valorar con ", _interpolate(_list(0)), " puntos`"])}
      }
    }
  })
}
