<template>
  <div class="cardCarousel__container">
    <nav
      class="cardCarousel__nav"
    >
      <SideMenu
        class="cardCarousel__sideMenu"
        :show-more="true"
        :options="options"
        @carouselDeactivate="carouselView()"
        @carouselFilter="carouselFilter"
      />
      <button
        class="cardCarousel__nav__left"
        @click="moveCarousel(direction.prev)"
        :disabled="atHeadOfList"
        :title="$t('prev_acc')"
      />
      <button
        class="cardCarousel__nav__right"
        @click="moveCarousel(direction.next)"
        :disabled="atEndOfList"
        :title="$t('next_acc')"
      />
    </nav>
    <div class="cardCarousel__wrapperContainer">
      <div
        class="cardCarousel__wrapper"
        ref="carousel"
      >
        <div
          v-touch:swipe="touchEvent"
          class="cardCarousel"
        >
          <div class="cardCarousel__overflow-container">
            <div
              v-if="hasServices"
              class="cardCarousel__cards"
              :style="{ transform: 'translateX' + '(' + currentOffset + 'px' + ')'}"
            >
              <lazy-card
                class="cardCarousel__card"
                v-for="item in services"
                :service="item"
                :key="item.identifier"
                :small="smallCards"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { defineComponent } from 'vue';

import LazyCard from './LazyCard.vue';
import SideMenu from './SideMenu.vue';

export default defineComponent({
  name: 'CardGroupCarousel',
  emits: [
    'carouselView',
    'carouselFilter',
    'pageActiveChange',
  ],
  components: {
    LazyCard,
    SideMenu,
  },
  props: {
    services: { type: Array, required: true },
    tidy: { type: Boolean, default: false },
    smallCards: { type: Boolean, default: false },
    options: { type: Object, required: false, default: null },
  },
  data() {
    return {
      currentOffset: 0,
      startingPoint: 1,
      windowSize: 4,
      scrollWidth: 0,
      total: 0,
      direction: {
        prev: -1,
        next: 1,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isLogged']),
    atHeadOfList() {
      return this.currentOffset === 0;
    },
    atEndOfList() {
      return this.startingPoint >= this.total;
    },
    activePage() {
      return `${this.startingPoint} / ${this.total}`;
    },
    filteredServices() {
      this.initWidths();
      return this.services();
    },
    hasServices() {
      return this.services.length > 0;
    },
  },
  methods: {
    paginationFactor() {
      const elementList = this.$refs.carousel;
      const element = elementList.getElementsByClassName('cardCarousel__card')[0] || document.createElement('div');
      const style = element.currentStyle || window.getComputedStyle(element);
      const width = parseInt(style.width.replace('px', ''), 10); // element.offsetWidth; // or use style.width
      const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
      const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);
      // const border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);
      return width + margin - padding; // + border como es content-box no hace falta;
    },
    touchEvent(direction) {
      this.moveCarousel(direction === 'left' ? this.direction.next : this.direction.prev);
    },
    carouselView() {
      this.$emit('carouselView', 'opened');
    },
    carouselFilter(evt) {
      this.$emit('carouselFilter', evt);
    },
    moveCarousel(direction) {
      const paginationFactor = this.paginationFactor();
      const scrollWidth = Math.floor(this.$refs.carousel.clientWidth / paginationFactor);
      this.windowSize = scrollWidth;
      // Find a more elegant way to express the :style.
      // Consider using props to make it truly generic
      if (direction === this.direction.next && !this.atEndOfList) {
        this.currentOffset -= (paginationFactor * scrollWidth);
        this.startingPoint += 1;
      } else if (direction === this.direction.prev && !this.atHeadOfList) {
        this.currentOffset += (paginationFactor * scrollWidth);
        this.startingPoint -= 1;
      }
      this.$emit('pageActiveChange', this.activePage);
    },
    initWidths() {
      if (this.$refs) {
        const itemsPerPage = Math.floor(this.$refs.carousel.clientWidth / this.paginationFactor());
        this.windowSize = itemsPerPage;
        this.total = Math.ceil(this.services.length / itemsPerPage);
      }
    },
    initPages() {
      this.currentOffset = 0;
      this.startingPoint = 1;
    },
  },
  mounted() {
    this.initWidths();
    this.initPages();
    this.$emit('pageActiveChange', this.activePage);
    window.addEventListener('resize', this.initWidths);
  },
  updated() {
    this.initWidths();
    this.$emit('pageActiveChange', this.activePage);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.initWidths);
  },
  watch: {
    services: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldVal) {
        this.initPages();
        this.initWidths();
      },
      deep: true,
    },
  },
});
</script>

<i18n>
{
  "en": {
    "tidy": "Arrange",
    "tidy_acc": "Arrange",
    "showmore": "Show all elements",
    "showmore_acc": "Show all elements",
    "next": "Next",
    "next_acc": "Next page",
    "prev": "Prev",
    "prev_acc": "Previous page"
  },
  "es": {
    "tidy": "Ordenar",
    "tidy_acc": "Ordenar",
    "showmore": "Ver todos",
    "showmore_acc": "Ver todos los elementos",
    "next": "Siguiente",
    "next_acc": "Página siguiente",
    "prev": "Anterior",
    "prev_acc": "Página anterior"
  }
}
</i18n>

<style lang="scss" scoped>

.cardCarousel {
  min-height: 254px;
  @media (max-width: $screen-xs-min) {
    min-height: 200px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 1.5rem 0 0 0;
  }

  &__wrapperContainer {
    width: 100%;
    overflow: hidden;
  }

  &__container {
    position: relative;
    top: -2.5rem;
  }

  &__overflow-container {
    overflow: hidden;
  }

  &__nav {
    user-select: none;
    height: 1.5rem;
    transition: linear 0.25s opacity;
    @media (max-width: $screen-phone) {
      position: initial;
      top: 0;
    }
    &__left,
    &__right {
      display: none;
      appearance: none;
      border-radius: 5rem;
      cursor: pointer;
      height: 3rem;
      width: 3rem;
      border: 0px none;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 9;
      background-color: rgba(255, 255, 255, 0.9);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 24px;
      line-height: 0;
      z-index: 8;
      transition: linear 0.15s all;
      margin-top: 0.8rem;
      &:hover {
        box-shadow: 1px 0 3px 0 rgba(0, 0, 0, 0.15);
      }
      &[disabled] {
        opacity: 0;
        display: none;
        top: -300vh;
      }
    }
    &__left {
      left: 0;
      background-image: url('/img/icons/arrow-left.svg');
    }
    &__right {
      right: 0;
      background-image: url('/img/icons/arrow-right.svg');
    }
  }

  &__cards {
    display: flex;
    transition: transform 750ms ease-in-out;
    transform: translatex(0);
  }

  &__sideMenu {
    float: right;
    position: relative;
    top: 0.3rem;
  }

}
</style>
