import { defineAsyncComponent } from 'vue';

const MisSolicitudes = defineAsyncComponent(() => import(/* webpackChunkName: "curie-MisSolicitudes" */ './views/MisSolicitudesView.vue'));
const NuevaSolicitud = defineAsyncComponent(() => import(/* webpackChunkName: "curie-NuevaSolicitud" */ './views/NuevaSolicitud.vue'));
const SolicitudCertificadoTutor = defineAsyncComponent(() => import(/* webpackChunkName: "mimovilidad-SolicitudCertificadoTutor" */ './views/SolicitudCertificadoTutor.vue'));
const Paso1Step = defineAsyncComponent(() => import(/* webpackChunkName: "mimovilidad-Paso1Step" */ './components/solicitudCertificadoTutor/Paso1Step.vue'));
const Paso2Step = defineAsyncComponent(() => import(/* webpackChunkName: "mimovilidad-Paso2Step" */ './components/solicitudCertificadoTutor/Paso2Step.vue'));
const Paso3Step = defineAsyncComponent(() => import(/* webpackChunkName: "mimovilidad-Paso3Step" */ './components/solicitudCertificadoTutor/Paso3Step.vue'));

const rutaProyecto = '/mi-movilidad';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'Mi Movilidad MisSolicitudes',
    component: MisSolicitudes,
    meta: {
      group: 'curie',
      application: 'mi-movilidad',
      requiresAuth: true,
    },
  },
  {
    path: `${rutaProyecto}/SolicitudCertificadoTutor`,
    name: 'Mi Movilidad SolicitudCertificadoTutor',
    component: SolicitudCertificadoTutor,
    meta: {
      group: 'curie',
      application: 'mi-movilidad',
      requiresAuth: true,
    },
    redirect: `${rutaProyecto}/SolicitudCertificadoTutor/inicio`,
    children: [
      {
        path: `${rutaProyecto}/SolicitudCertificadoTutor/inicio`,
        name: 'Mi Movilidad SolicitudCertificadoTutor Paso 1',
        component: Paso1Step,
      },
      {
        path: `${rutaProyecto}/SolicitudCertificadoTutor/previsualizacion`,
        name: 'Mi Movilidad SolicitudCertificadoTutor Paso 2',
        component: Paso2Step,
      },
      {
        path: `${rutaProyecto}/SolicitudCertificadoTutor/generacion`,
        name: 'Mi Movilidad SolicitudCertificadoTutor Paso 3',
        component: Paso3Step,
      },
    ],
  },
  {
    path: `${rutaProyecto}/nueva-solicitud/`,
    name: 'NuevaSolicitudMovilidad',
    component: NuevaSolicitud,
    meta: {
      group: 'curie',
      application: 'mi-movilidad',
      requiresAuth: true,
    },
  },
];
