<template>
  <div class="forbidden2FA">
    <div class="forbidden2FA__flexItems">
      <h1 class="forbidden2FA__title">{{ $t('forbidden_title') }}</h1>
      <div class="grid">
        <div class="forbidden2FA__message col-12">
            {{ $t('forbidden_message', {genderText: userTextGender}) }}
        </div>
        <div class="col-12 text-center pt-2">
          <fwjsTimer :timeLimit="redirectTimeout"/>
        </div>
    </div>
    </div>
  </div>
</template>

<script>

import fwjsTimer from '@/app/shared/components/fwjsTimer.vue';
import { mapState } from 'vuex';

export default {
  name: 'Forbidden2FA',
  data() {
    return {
      redirectTimeout: 10,
    };
  },
  components: {
    fwjsTimer,
  },
  methods: {
    loginLoaMedium() {
      this.$store.dispatch('auth/loginLoaMedium');
    },
  },
  mounted() {
    this.$store.commit('pages/SET_TITLE', this.$t('forbidden_title_sub'));
    setTimeout(() => {
      this.loginLoaMedium();
    }, this.redirectTimeout * 1000);
  },

  computed: {
    ...mapState('user', ['user']),
    userTextGender() {
      if (this.user && this.user.afiliacion && this.user.afiliacion.sexo) {
        if (this.user.afiliacion.sexo === 'M') { return this.$t('female_text'); }
        if (this.user.afiliacion.sexo === 'V') { return this.$t('male_text'); }
      }
      return this.$t('mix_text');
    },
  },
};
</script>

<i18n src="../locales/i18n.json"></i18n>

<style lang="scss" scoped>
.forbidden2FA {
  width: 100vw;
  min-height: 87vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--privatebgcolor);

  &__flexItems{
    background-color: var(--card-bg-color);
    max-width: var(--detail-grid-width);
    margin: 0 1rem;
    padding: 2rem 1.5rem;
    border-radius: 1rem;
    box-shadow: var(--shadowLevel-2);
  }

  &__title {
    color: var(--main-text-color);
    font-size: 1.875rem;
    padding-top: 0;
    margin-top: 0;

    @media (max-width: $screen-sm-min) {
      font-size: 1.5rem;
    }
  }

  &__message {
    line-height: 1.5;
  }
}
</style>
