/* Needed to disable for Vuex compatibility */
/* eslint-disable no-param-reassign */

import axios from 'axios';
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import modules from './app/app.store.modules';

const store = createStore({
  // strict: process.env.NODE_ENV !== 'production',
  modules,
  plugins: [createPersistedState({
    paths: [
    // 'language.language',
      'acadeGenerico',
      'misCrau.rutaVolver',
      'misCrau.nombreBtnRutaVolver',
      'modals',
      'services.maximizeEvents',
      'gesteconRecibos.idTransaccion',
      'gesteconRecibos.idRecibo',
      'gesteconRecibos.idEstadoFirma',
      'gesteconRecibos.reciboDevolver',

    ],
  })],
});

/* AUTH Auth CUSTOM */
axios.interceptors.request.use((config) => {
  // Do something before request is sent
  const bearer = store.state.auth.token || '';
  // Do not include the bearer token if we are invoking CAS
  const isCasRequest = config.url.includes(store.state.auth.cas);
  if (bearer !== '' && !isCasRequest) {
    config.headers.Authorization = `Bearer ${bearer}`;
  }
  if (config.method === 'post' && !config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json; charset=utf-8';
  }
  const umsessionID = store.state.auth.sessionID || '';
  const needsHeader = config.url.indexOf(window._env_.API_UM_URL) !== -1
    || config.url.indexOf(process.env.VUE_APP_BACKEND) !== -1;
  if (needsHeader && umsessionID !== '') {
    config.headers['UMU-User-SessionID'] = umsessionID;
  }
  return config;
}, (error) => Promise.reject(error));

const errorResponseHandler = (error) => {
  if (!error.code || error.code !== 'ECONNABORTED') {
    // Do something with response error
    const status = error.response ? error.response.status : 401;
    // Check insufficient LoA
    if (status === 401 && error.response
      && error.response.headers['umu-authenticate']
      && error.response.headers['umu-authenticate'] === 'invalid_loa_claim') {
      store.commit('auth/SET_2FA_NEEDED', true);
    } else if (
      (!error.response || [401, 408, 504].includes(status))
    ) {
      store.commit('auth/REVOKE_TOKEN');
      store.dispatch('auth/login');
      // window.location.href = authpaths.login;
    }
  }
  return Promise.reject(error);
};

axios.interceptors.response.use((response) => response, errorResponseHandler);

/* END Auth custom */

export default store;
