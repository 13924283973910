export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
        "tom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
        "weblink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web link"])},
        "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])},
        "ends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ends at"])},
        "allDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All day"])},
        "anchor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["this URL"])}
      },
      "es": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De"])},
        "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
        "tom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["al"])},
        "weblink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enlace web"])},
        "at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a las"])},
        "ends": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["termina a las"])},
        "allDay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["todo el día"])},
        "anchor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esta dirección"])}
      }
    }
  })
}
