import { defineAsyncComponent } from 'vue';

const SolicitudCorrecta = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-solicitudCorrecta" */'./views/SolicitudCorrecta.vue'));
const DevolucionIngreso = defineAsyncComponent(() => import(/* webpackChunkName: "gestecon-devolucionIngreso" */'./views/DevolucionIngreso.vue'));

export default [
  {
    path: '/devolucion-generica-ingresos/',
    name: 'DevolucionGenericaIngresos',
    component: DevolucionIngreso,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'devolucion-generica-ingresos',
    },
  },
  {
    path: '/devolucion-generica-ingresos/SolicitudCorrecta',
    name: 'SolicitudCorrecta',
    component: SolicitudCorrecta,
    meta: {
      requiresAuth: true,
      group: 'gestecon',
      application: 'devolucion-generica-ingresos',
    },
  },
];
