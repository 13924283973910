export default {
  namespaced: true,
  state: {
    selectedTurn: null,
    solicitudParcial: null,
  },
  mutations: {
    SET_SELECTED_TURN(state, selectedTurn) {
      state.selectedTurn = selectedTurn;
    },
    SET_SOLICITUD_PARCIAL(state, info) {
      state.solicitudParcial = JSON.parse(JSON.stringify(info));
    },
  },
};
