const possiblesRoles = [
  'G_OTROS',
  'G_PAS_GENERAL',
  'G_PDI_GENERAL',
  'G_ALUMNOS_OFICIAL',
  'G_ALUMNOS_NO_OFICIAL',
];

const possibleOrders = [
  {
    i18n: 'alpha',
    key: 'alpha',
    value: '/sortByAlphabetic',
    disabled: false,
  },
  {
    i18n: 'most',
    key: 'most',
    value: '/sortByMostVisited',
    disabled: false,
  },
  {
    i18n: 'ema',
    key: 'ema',
    value: '/sortByEma',
    disabled: false,
  },
];

const slidersDefinition = {
  STARRED_SERVICES: {
    order: 0,
    private: true,
    services: [],
    sliderKey: 'STARRED_SERVICES',
    tidy: true,
    viewType: 'opened',
    visible: true,
    param: '',
    howMany: '',
    tag: '',
    orderParams: [...possibleOrders],
    smallCards: true,
    loaded: false,
    roleName: [...possiblesRoles],
  },
  NEW_SERVICES: {
    order: 1,
    private: false,
    services: [],
    sliderKey: 'NEW_SERVICES',
    tidy: false,
    viewType: 'carousel',
    visible: true,
    param: '/sortByEma',
    howMany: '?result=24',
    tag: '',
    orderParams: [...possibleOrders],
    smallCards: false,
    loaded: false,
    roleName: [...possiblesRoles],
  },
  RECOMMENDED: {
    order: 2,
    private: true,
    services: [],
    sliderKey: 'RECOMMENDED',
    tidy: false,
    viewType: 'carousel',
    visible: true,
    param: '/sortByEma',
    howMany: '',
    tag: '',
    orderParams: [...possibleOrders],
    smallCards: false,
    loaded: false,
    roleName: [...possiblesRoles],
  },
  RECENT_SERVICES: {
    order: 3,
    private: true,
    services: [],
    sliderKey: 'RECENT_SERVICES',
    tidy: false,
    viewType: 'carousel',
    visible: false,
    param: '/sortByEma',
    howMany: '?result=8',
    tag: '',
    orderParams: [...possibleOrders],
    smallCards: false,
    loaded: false,
    roleName: [...possiblesRoles],
  },
  MOST_USED_SERVICES: {
    order: 4,
    private: true,
    services: [],
    sliderKey: 'MOST_USED_SERVICES',
    tidy: false,
    viewType: 'carousel',
    visible: false,
    param: '/sortByMostVisited',
    howMany: '?result=8',
    tag: '',
    orderParams: [...possibleOrders],
    smallCards: false,
    loaded: false,
    roleName: [...possiblesRoles],
  },
  ALL_SERVICES: {
    order: 9,
    private: false,
    services: [],
    sliderKey: 'ALL_SERVICES',
    tidy: false,
    viewType: 'carousel',
    visible: true,
    param: '/sortByEma',
    howMany: '',
    tag: '',
    orderParams: [...possibleOrders],
    smallCards: false,
    loaded: false,
    roleName: [...possiblesRoles],
  },
};

export { slidersDefinition as default };
