import { defineAsyncComponent } from 'vue';

const Resguardos = defineAsyncComponent(() => import(/* webpackChunkName: "RMAT_Resguardos" */'./views/Resguardos.vue'));

const rutaProyecto = '/consulta/resguardomatricula';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'RMAT Resguardos',
    component: Resguardos,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'resguardo-matricula',
    },
  },
];
