import { createApp, configureCompat } from 'vue';
import Vue3TouchEvents from 'vue3-touch-events';
import VueSkipTo from '@vue-a11y/skip-to';

import PrimeVue from 'primevue/config';
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';

import './app/shared/assets/estiloUMU/estiloUMU.css';

import log from './lib/log/index';
import cookiesFn from './app/shared/utils/cookies3rdParty';
import vueAuthImage from './app/shared/directives/vueAuthImage';

const hasAcceptedCookies = window.localStorage.getItem('posecookies');
if (hasAcceptedCookies && hasAcceptedCookies === 'all') {
  cookiesFn();
}

/*
Necesario para que la compatibilidad con Vue 3 funcione correctamente.
*/
configureCompat({
  COMPONENT_V_MODEL: false,
  RENDER_FUNCTION: false,
  TRANSITION_GROUP_ROOT: false,
  OPTIONS_BEFORE_DESTROY: true,
  ATTR_FALSE_VALUE: 'suppress-warning',
});

const isSafari = (
  navigator.userAgent.indexOf('Safari') !== -1
  && navigator.userAgent.indexOf('Chrome') === -1
);

window._env_.isSafari = isSafari;
createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(VueSkipTo)
  .use(PrimeVue)
  .use(ConfirmationService)
  .use(ToastService)
  .use(Vue3TouchEvents)
  .use(log)
  .use(ToastService)
  .directive('auth-image', vueAuthImage)
  .mount('#app');
