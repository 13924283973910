import { defineAsyncComponent } from 'vue';

const ExpedientesView = defineAsyncComponent(() => import(/* webpackChunkName: "CEXP_ExpedientesView" */'./views/ExpedientesView.vue'));

const rutaProyecto = '/consulta/expedienteacademico';

export default [
  {
    path: `${rutaProyecto}/`,
    name: 'CEXP Expedientes',
    component: ExpedientesView,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'consulta-expedientes',
    },
  },
];
