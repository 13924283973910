import Languages from '@/i18n';
import sharedApi from '../../shared.api';

export default {
  namespaced: true,
  state: {
    language: '',
  },
  mutations: {
    SET_LANGUAGE(state, lang) {
      // Necesario ya que es la configuración por defecto del plugin
      // de su propia página web.
      // eslint-disable-next-line no-param-reassign
      state.language = lang;
      Languages.locale = lang;
    },
  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    setLanguage({ commit }, langs) {
      // Desactivo los idiomas y fuerzo siempre el castellano
      commit('SET_LANGUAGE', 'es');
    },
    setRemoteLanguage({ commit }, lang) {
      commit('SET_LANGUAGE', lang);
      sharedApi.setLanguage(lang);
    },
  },
};
