import { defineAsyncComponent } from 'vue';

// Tramite
const matriculaParcialPortal = defineAsyncComponent(() => import(/* webpackChunkName: "matriculaParcialPortal" */ '@/app/acade/matricula-parcial/views/Portal.vue'));
const matriculaParcialWizard = defineAsyncComponent(() => import(/* webpackChunkName: "matriculaParcialWizard" */ '@/app/acade/matricula-parcial/views/Wizard.vue'));
const matriculaParcialInicio = defineAsyncComponent(() => import(/* webpackChunkName: "matriculaParcialInicio" */ '@/app/acade/matricula-parcial/views/Inicio.vue'));
const matriculaParcialDocumentacion = defineAsyncComponent(() => import(/* webpackChunkName: "matriculaParcialDocumentacion" */ '@/app/acade/matricula-parcial/views/Documentacion.vue'));
const matriculaParcialResumen = defineAsyncComponent(() => import(/* webpackChunkName: "matriculaParcialResumen" */ '@/app/acade/matricula-parcial/views/Resumen.vue'));
const matriculaParcialFin = defineAsyncComponent(() => import(/* webpackChunkName: "matriculaParcialFin" */ '@/app/acade/matricula-parcial/views/Fin.vue'));

export default [
  {
    path: '/matriculaparcial/',
    name: 'PortalMatriculaParcial',
    component: matriculaParcialPortal,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'matricula-parcial',
    },
  },
  {
    path: '/matriculaparcial/solicitud',
    name: 'WizardMatriculaParcial',
    component: matriculaParcialWizard,
    meta: {
      requiresAuth: true,
      group: 'acade',
      application: 'matricula-parcial',
    },
    redirect: '/matriculaparcial/solicitud/inicio',
    children: [
      {
        path: '/matriculaparcial/solicitud/inicio',
        component: matriculaParcialInicio,
      },
      {
        path: '/matriculaparcial/solicitud/documentacion',
        component: matriculaParcialDocumentacion,
      },
      {
        path: '/matriculaparcial/solicitud/resumen',
        component: matriculaParcialResumen,
      },
      {
        path: '/matriculaparcial/solicitud/fin',
        component: matriculaParcialFin,
      },
    ],
  },
];
