import {
  defineAsyncComponent,
} from 'vue';

// Portal
const trasladoExpedientePortal = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedientePortal" */ '@/app/acade/traslado-expediente-entrada/views/Portal.vue'));

// Solicitud
const trasladoExpedienteEntradaWizard = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaWizard" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/Wizard.vue'));
const trasladoExpedienteEntradaInicio = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaInicio" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/Inicio.vue'));
const trasladoExpedienteEntradaDatosPersonales = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaDatosPersonales" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/pasos/DatosPersonales.vue'));
const trasladoExpedienteEntradaDatosAcademicos = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaDatosAcademicos" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/pasos/DatosAcademicos.vue'));
const trasladoExpedienteEntradaDocumentacion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaDocumentacion" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/pasos/Documentacion.vue'));
const trasladoExpedienteEntradaPago = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaPago" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/pasos/Pago.vue'));
const trasladoExpedienteEntradaResumen = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaResumen" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/pasos/Resumen.vue'));
const trasladoExpedienteEntradaImpresion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaImpresion" */ '@/app/acade/traslado-expediente-entrada/views/solicitud/pasos/Impresion.vue'));

// Subsanación
const trasladoExpedienteEntradaWizardSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaWizardSubsanacion" */ '@/app/acade/traslado-expediente-entrada/views/subsanacion/Wizard.vue'));
const trasladoExpedienteEntradaPagoSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaPagoSubsanacion" */ '@/app/acade/traslado-expediente-entrada/views/subsanacion/pasos/Pago.vue'));
const trasladoExpedienteEntradaInicioSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaInicioSubsanacion" */ '@/app/acade/traslado-expediente-entrada/views/subsanacion/pasos/Inicio.vue'));
const trasladoExpedienteEntradaResumenSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaResumenSubsanacion" */ '@/app/acade/traslado-expediente-entrada/views/subsanacion/pasos/Resumen.vue'));
const trasladoExpedienteEntradaFinSubsanacion = defineAsyncComponent(() => import(/* webpackChunkName: "trasladoExpedienteEntradaFinSubsanacion" */ '@/app/acade/traslado-expediente-entrada/views/subsanacion/pasos/Fin.vue'));

export default [
  {
    path: '/admisionestudiosiniciados/inicio',
    name: 'PortalTrasladoExpedienteEntrante',
    component: trasladoExpedientePortal,
    meta: {
      requiresAuth: true,
      standalone: true,
      group: 'acade',
      application: 'admision-estudios-iniciados',
    },
  },
  {
    path: '/admisionestudiosiniciados',
    name: 'InicioTrasladoExpedienteEntrante',
    component: trasladoExpedienteEntradaInicio,
    meta: {
      requiresAuth: true,
      standalone: true,
      group: 'acade',
      application: 'admision-estudios-iniciados',
    },
  },
  {
    path: '/admisionestudiosiniciados/solicitud',
    name: 'WizardTrasladoExpedienteEntrante',
    component: trasladoExpedienteEntradaWizard,
    meta: {
      requiresAuth: true,
      standalone: true,
      group: 'acade',
      application: 'admision-estudios-iniciados',
    },
    redirect: '/admisionestudiosiniciados/solicitud/datospersonales',
    children: [{
      path: '/admisionestudiosiniciados/solicitud/datospersonales',
      component: trasladoExpedienteEntradaDatosPersonales,
    },
    {
      path: '/admisionestudiosiniciados/solicitud/datosacademicos',
      component: trasladoExpedienteEntradaDatosAcademicos,
    },
    {
      path: '/admisionestudiosiniciados/solicitud/documentacion',
      component: trasladoExpedienteEntradaDocumentacion,
    },
    {
      path: '/admisionestudiosiniciados/solicitud/resumen',
      component: trasladoExpedienteEntradaResumen,
    },
    {
      path: '/admisionestudiosiniciados/solicitud/pago',
      component: trasladoExpedienteEntradaPago,
    },
    {
      path: '/admisionestudiosiniciados/solicitud/impresion',
      component: trasladoExpedienteEntradaImpresion,
    },
    ],
  },
  {
    path: '/admisionestudiosiniciados/subsanacion/',
    name: 'WizardSubsanacionTrasladoExpedienteEntrante',
    component: trasladoExpedienteEntradaWizardSubsanacion,
    meta: {
      requiresAuth: true,
      standalone: true,
      group: 'acade',
      application: 'admision-estudios-iniciados',
    },
    redirect: '/admisionestudiosiniciados/subsanacion/inicio',
    children: [
      {
        path: '/admisionestudiosiniciados/subsanacion/inicio',
        component: trasladoExpedienteEntradaInicioSubsanacion,
      },
      {
        path: '/admisionestudiosiniciados/subsanacion/pago',
        component: trasladoExpedienteEntradaPagoSubsanacion,
      },
      {
        path: '/admisionestudiosiniciados/subsanacion/resumen',
        component: trasladoExpedienteEntradaResumenSubsanacion,
      },
      {
        path: '/admisionestudiosiniciados/subsanacion/fin',
        component: trasladoExpedienteEntradaFinSubsanacion,
      },
    ],
  },
];
